.popupMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include transparentBG(white, 0.95);
  z-index: 8888;


  .message {
    max-width: 750px;
    width: 100%;
    margin: 0;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%, -60%);
    position: absolute;
    border-radius: 7px;
    background: $light;
    border: 3px solid $secondary;
    padding: 7px;

    .inner{
      border: 3px solid $secondary;
      border-radius: 3px;
      padding: 25px;
    }
  }
 
  h4 {
    @include headerFont;
    font-size: 2.8em;
    font-weight: 700;
    text-align: center;
    color: $primary;
    margin: 10px 0 10px 0;
    text-transform: uppercase;

    @media all and (max-width: $xs) {
      font-size: 2em;
    }
  }

  p {
    text-align: center;
    margin: 0 20px;
    color: $basic;
    font-size: 1.2em;
  }

  .buttonHolder {
    display: block;
    margin-top: 25px;
    text-align: center;

    .continue {
      @include button;
      font-size: 1.35em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &#timeoutMessage {
    .btnWrap {
      width: 90%;
      max-width: 600px;
      margin: 50px auto 20px auto;
      text-align: center;

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 15px;
        position: relative;
        @include button;
        padding: 15px 15px 15px 70px !important;
        font-family: $primaryFont;
        font-size: 1.125em;
        text-align: left;

        @include hover {
          color: $light;
        }

        em {
          display: block;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 1.8em;
          padding-bottom: 10px;
        }

        &:before {
          @include iconFont;
          content: 'a';
          border: 3px solid $accent;
          width: 45px;
          height: 45px;
          padding-top: 7px;
          font-size: 1.4em;
          text-align: center;
          display: inline-block;
          @include vertCenter;
          position: absolute;
          left: 10px;
          background: transparent;
          @include rounded(50%);
        }
        &:after{
          display: none;
        }

        &.leaveBtn{
          @include button($wrong, $light);
          text-align: left;
        
          &:before{
            content: 'b';
            font-size: 1.4em;
            border-color: $wrong;
          }
          @include hover{
            color:$light;
          }
        }

       
      }
    }

    &.show {
      display: block;

      .message {
        top: 0;
      }
    }
  }

  &#menuInWarningMessage {
    &.show {
      display: block;

      .message {
        top: 0;
      }
    }

    .btnWrap {
      margin-top: 15px;
      text-align: center;

      button {
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      .stayHere {
        @include button;
      }

      .goHome {
        @include button($secondary, $secondaryHover);
      }
    }
  }
}

#printOrientation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($basic, 0.9);

  .dialog {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: block;
    width: 280px;
    height: 180px;
    padding: 15px;
    background: 50% url(/umbraco/assets/img/loader.gif) no-repeat;
    background-position: 50% 50%;
    background-color: $light;
    border-radius: 3px;
    transition: all 500ms;

    .printProgress {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 15px;
      height: 5px;
      background-color: $grey;
      text-align: center;

      .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background-color: $primary;
        transition: width 200ms;
      }

      .num {
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        text-align: center;
        color: $basic;
        font-family: $primaryFont;
        font-size: 0.9em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  button.cancel {
    @include button;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  iframe {
    display: none;
    width: 100%;
    height: 100%;
    border: 1px solid $dark;
    border-radius: 4px;
  }

  .printButtonHolder {
    display: none;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: center;

    button.print {
      @include button;
      font-size: 1.3em;
      margin: 0 2px;
    }

    button.close {
      @include button($secondary, $light);
      font-size: 1.3em;
      margin: 0 2px;
    }
  }
}

#printOrientation.completed {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  .dialog {
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 780px;
    height: 80vh;
    background: $light;
    overflow: scroll;

    .printProgress {
      display: none;
    }

    .printButtonHolder {
      display: flex;
    }

    iframe {
      display: block;
    }

    .cancel {
      display: none;
    }
  }
}

#staffNotice, #responsiveNotice {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: $light;

  &.showing {
    display: flex;
  }

  .logoWrap {
    width: 360px;
    margin-bottom: 15px;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .text {
    font-size: 1.2em;
    border-top: 3px solid #dddddd;
    border-bottom: 3px solid #dddddd;
    max-width: 500px;
    margin: 0 20px 70px;
    padding: 16px;

    p:last-child {
      margin-bottom: 0;
    }

    .buttonHolder {
      text-align: center;
    }

    button {
      @include button;
    }
  }
}

#responsiveNotice {
  z-index: 999999999;

  .text {
    background: #dddddd;
  }
}

@media (max-width: $sm) {
  #printOrientation.completed {
    .dialog {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

@media (max-width: $xs) {
  .popupMessage {
    h4 {
      font-size: 2.2em;
    }

    // .message {
    //   padding: 5px 15px 25px;
    // }
  }

  #printOrientation {
    .printButtonHolder {
      button {
        font-size: 1em;
      }
    }
  }
}

@media (max-width: $xxs) {
  .popupMessage#timeoutMessage {
    h4 {
      font-size: 1.8em;
    }

    .btnWrap {
      max-width: 300px;
      margin-top: 25px;

      span {
        display: block;
        margin: 5px 0;
      }
    }
  }

  #staffNotice {
    .logoWrap {
      width: 280px;
      margin-bottom: 15px;
    }
  }
}
