.slide {
  h2 {
    font-weight: normal;
    font-family: $primaryFont;
  }
  a{
    @include break;
  }

  h2.heading {
    font-weight: 700;
    font-size: 1.8em;
    color: $primary;
    margin-bottom: 20px;
    line-height: 1.2em;
    padding-top: 10px;
    @media (max-width: $xxs){
      font-size: 1.55em;
    }
  }

  h2.subheading {
    font-family: $primaryFont;
    font-weight: 700;
    font-size: 1.35em;
    color: $primary;
    margin: 5px 0 10px;
    padding-right: 3px;
    display: inline-block;
  }
  h2.SmallHeading {
    font-family: $secondaryFont;
    font-weight: 700;
    border-top: 1px solid $secondary;
    padding-top: 7px;
    font-size: 1.1em;
    color: $alt;
    // text-transform: uppercase;
    margin: 5px 0 10px;
    padding-right: 3px;
    display: block;
  }


 

  .largeText {
    display: block;
    color: $basic;
    font-size: 1.4em;
    line-height: 1.5em;
    font-family: $secondaryFont;
    font-weight: 700;
    position: relative;
    margin-bottom: 25px;

    @media (max-width: $xxs){
      font-size: 1.2em;
      line-height: 1.35em;
    }

  }

  .primaryBlock {
    
    padding: 10px 0px 0px 30px;
    position: relative;
    font-weight: 700;
    color: $alt2;
    display: block;
    margin-bottom: 20px;
    line-height: 1.2em;
    &:before{
      @include iconFont;
      content: 'F';
      position: absolute;
      left: 0px;
      top: 0px;
      color: $secondary;
      font-size: 2.3em;
      transition: transform .2s .75s;
    }
    .current &{
      &:before{
        transform: rotate(-12deg);
      }
    }
  }

  .secondaryBlock {
    border: 5px solid $accent;
    border-width: 5px 0px 5px 0px;
    font-weight: 600;
    padding: 15px 10px 15px 55px;
     display: block;
    // align-items: center;
    position: relative;
    line-height: 1.5em;
    min-height: 80px;
    margin: 20px 0px;
    &:before{
      @include iconFont;
      content: 'y';
      color: $accent;
      top: 12px;
      position: absolute;
      left: 0px;
      font-size: 3em;
    }
    &:after{
      @include iconFont;
      content: 'x';
      color: $primary;
      top: 23px;
      position: absolute;
      left: 0px;
      font-size: 1.8em;
      width: 43px;
      text-align: center;
    }
  }

  .ButtonLink{
    text-align: center;
    display: inline-block;
    
}

a.ButtonLink, .ButtonLink a{
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  max-width: 600px;
  margin: 0 auto;
  color:$primary;
  border: 1px solid $accent;
  font-size: 1em;
  position: relative;
  outline: none !important;
  display: inline-block;
  font-weight: 700;
  padding: 8px 35px 8px 15px;
  background: linear-gradient(90deg, $accent 50%, $light 50%);
  background-size: 200%;
  background-position: 100%;
  transition: background-position .15s;
  margin-bottom: 7px;
  &:after{
    content: 'R';
    @include vertCenter;
    position: absolute;
    right: 12px;
    @include iconFont;
  }
  @include hover{
      color: $light;
      background-position: 0%;
      &:after{
        color: $light;
      }
  }
}


  .highlight {
    @include bgWithContrast($grey);
    padding: 0 5px;
    display: inline-block;
  }

  h2.listheading {
    border: 1px solid $secondary;
    border-width: 1px 1px 0px 1px;
    font-weight: 800;
    color: $primary;
    font-size: 1.1em;
    padding: 10px 15px;
    margin-top: 25px;
    @media (max-width: $xxs){
      line-height: 1.2em;
    }
  }

 

  .videoCopy,
  .textCopy,
  .videoRTE,
  .tips {



    ul.primaryBlock, ul.secondaryBlock {
        margin-left: 0;
        padding-left: 35px;
    }
    ul{
      @include reformatList;
      &.longList{
        @media (min-width: $xs + 1){
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            width: 50%;
            min-width: 215px;
            flex-grow: 1;
          }
        }
       
      }
    }
    
    ul,ol{
        margin-top: 0;
      margin-bottom: 25px;
      font-family: $basicFont;
      font-weight:normal;
      border: 1px solid $secondary;
      padding: 15px;
     // font-size: 1.1em;
      ul,ol{
        font-size: 1em;
        margin-bottom: 0px;
        border-left: 0px;
        margin-top: 5px;
        margin-bottom: 0px;
        border: 0px;
        padding-bottom: 0px;
        padding-top: 5px;
        padding-left: 0px;
      }
      li{
        line-height: 1.4em;
      }

  
      }

      h2.listheading + ul, h2.listheading + ol{
        border-width: 0px 1px 1px 1px;
        padding-top: 10px;
      }

    ul {
        list-style-type: none;
        ul{
          margin-left: 8px;
          li{
            margin-bottom: 7px;
            padding-left: 20px;
            &:before{
              //background:$secondary;
              border: 2px solid $secondary;
              top: 7px;
              left: 2px;
              content: '';
              height: 7px;
              width: 7px;
            }
            li{
                &:before{
                    border:0px;
                    background: $alt2;
                    border-radius: 0px;
                    top: 7px;
                }
            }  
        } 
        }
        li{
            margin-left: 0px;
            padding-left: 20px;
            position: relative;
            margin-bottom: 7px;
                &:before{
                  @include iconFont;
                  content: 'Y';
                  color:$primary;
                  position: absolute;
                  left: 0px;
                  top: 7px;
                  font-size: .7em;
                } 
                
                
                ul,ol{
                  padding-bottom: 0px;
                  margin-bottom: 0px;
                }
          }
    }

    @supports(--css: variables) {
   
      ol {
        padding-left: 15px !important;
        list-style-type: none;
        counter-reset: counter;
        position: relative;
        padding-bottom: 8px;
       margin-top: 0px;
    
        ol,ul{
            padding-bottom: 0px;
            margin-bottom: 0px;
     
        }
       

        li {
            counter-increment: counter;
            margin-bottom: 7px;
            padding-left: 25px;
            position: relative;

            &:before {
                content: "."counter(counter);
                font-weight: 700;
                position: absolute;
                left: 0;
                top: 0px;
                direction: rtl;
                color: $basic;
                min-width: 20px;
                text-align: center;
              //  font-size: .8em;
            }
            ol{
              counter-reset: counter;
              li{
                counter-increment: counter;
              }
            }
            ul{
              margin-left: 3px;
            }
        }

        ul{
          margin-left: 20px;
          counter-reset: counter;
          li{
            margin-bottom: 7px;
            counter-increment: counter;
            &:before{
              background:$secondary;
              top: 10px;
              content: '';
              height: 7px;
              width: 7px;
              min-width: 0px;
            }
        }  
        }
    }
  }
}
}
