.spinner {
    display: none;
    position: fixed !important;
    left: 50% !important;
    top: 30% !important;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        background: rgba($light, 0.9);
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    div {
        div {
            background: $primary !important;
        }
    }
}
