.vidCarousel {
  .mediaHolder {
    position: relative;
  }

  .videoContainer {
    display: none;
    margin-bottom: 5px;

    .videoHolder {
      transform: scale(0);
      transition: transform .5s .2s;
    }

    &.current {
      display: block;

      .videoHolder {
        transform: scale(1);
      }
    }
  }

  .vidArrowWrap {
    width: 100%;
    left: 0;
    height: 60px;
    top: 0;
    display: flex;
    transform: scale(0) translateY(15px);
    transform-origin: center center;
    transition: transform .35s .15s;

    .current &, .searchSlideHolder & {
      transform: scale(1) translateY(0);
    }

    &.disabled {
      .vidArrow {
        .text, .icon {
          opacity: .25;
        }
      }
    }

    .vidArrow {
      cursor: pointer;
      @include bgWithContrast($primary);
      box-shadow: inset 0 0 0 2px $primary;
      width: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      .text {
        font-size: 1.3em;
        text-transform: uppercase;
        line-height: 1.05em;
        font-family: $primaryFont;
        font-weight: 700;
        margin: 0 8px;
      }

      .icon {
        font-size: 1.2em;
      }

      @include hover {
        @include bgWithContrast($primaryHover);
      }
    }
  }

  .vidProgress {
    @include bgWithContrast($secondary);
    font-weight: bold;
    min-width: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-width: 0 3px 0 3px;
    border-color: $light;
    border-style: solid;
  }
}

@media (max-width: $xs) {
  .vidCarousel {

    .vidArrowWrap {
      height: 50px;

      .vidArrow {
        .text {
          font-size: 1em;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .vidCarousel {
    .vidArrowWrap {
      .vidArrow {
        .text {
          display: none;
        }
      }
    }
  }
}
