/**ADG UNIVERSAL VARIABLES**/
//DYNAMIC COLORS
$primary: #002f87;
$secondary: #ffc82e;
$alt: #26927b;//Aqua
$alt: #21836D;//ADA Aqua
$alt2: #01276e; //Dark Blue
$alt3: #abb9d4;// Lite Blue
$accent: #3AB0C8;
$accent: #34A1B7;//ADA Teal
$contrast: #ef4128; //Red

$hover: $alt2;

//CONSTANT COLORS
$basic: #232323;
$light: #fff;
$dark: #555555;
$grey: #d0d0d0;
/* Default link color for ADA compatibility */
$link: #01276E; //Dark Blue
$bg: #EBEBEC;
$wrong: $contrast;
$correct: #7c9e14; //Light Green
$correct: #007166; //Dark Green




/* Hover colors can be a 3rd and/or 4th color if the school has them (if not, you can use the lighten and/or darken sass functions) */
$primaryHover: darken($primary, 10%);
$primaryHover: $alt2;
$secondaryHover: darken($secondary, 10%);
/* ADA hover needs to be a color that contrasts with the primary color but also is dark enough to see white against. */
$ADAhover: $basic;


//FONTS
$primaryFont:  "Montserrat","Open Sans","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
$secondaryFont: "Besley",Courier,Georgia,serif;
$basicFont:  "Montserrat",'Open Sans',"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$iconFont: 'UNG';


//WIDTHS
$teeny: 300px;
$tiny: 425px;
$xxs: 520px;
$xs: 650px;
$sm: 811px;
$tween: 950px;
$md: 1050px;
$mid: 1150px;
$lg: 1280px;
$xl: 1500px;

$xsH: 470px;

$smH: 650px;


//ELEMENTS
$maxWidth: 1400px;
$toolBarWidth: 450px;
$headerHeight: 110px;
$footerHeight: 40px;
$innerFoot: 60px;
$innerHead: 110px;
$topBarHeight: 80px;
$out: .5s;
$sb: 190px;
$sbL: 150px; 
$sbM: 120px; 
$sbS: 75px;
$top: 120px;
$left: 75px;

$rspHeader: 75px;

/* Based on the logo, adjust this max width for ADA responsive */
$logoADAMax: 275px;
