/*-----Tool Tips-----*/

.tipsMode {
    #helpBG {
        display: none;
        @include transparentBG($basic, .65); //needs to be same color as .tour-backdrop below
        @include fixed;
        @include fillSpace;
        z-index: 9990;
    }

    .alertBar {
        max-width: 320px;
        z-index: 10;

        &:before {
            width: 100%;
            height: 40px;
            z-index: -1;
            background: $primary;
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            content: '';
        }

        @media (max-width: $sm) {
            max-width: 100%;

            &:before {
                display: none;
            }
        }
    }
}

.tipIcon {
    position: fixed;
    bottom: 60px;
    right: 20px;
    font-size: 1.75em;
    font-family: $basicFont;
    text-align: center;
    z-index: 1;
    width: 40px;
    height: 20px;
    z-index: 9999;
    opacity: 0.7;
    color: $primary;
    transition: bottom .8s ease, opacity .3s ease;
    display: none !important; //REMOVE AFTER FEATURE APPROVED
    .tipsMode & {
        z-index: 9999;
        bottom: 10px;
    }

    &.clicked {
        color: $light;
        opacity: 1.0;
    }

    @include hover {
        cursor: pointer;
        opacity: 1.0;
    }
}

.mobileTips {
    display: none !important;
}

.tour-backdrop {
    @include transparentBG($basic, .65);
    z-index: 9990;
    position: fixed !important; //Never change
}

.tour-tips-element {
    pointer-events: none;
}

.popover[class*="tour-"] {
    z-index: 9999;
    @include rounded(0);
    min-width: 400px;

    &.moveRight {
        .endTips {
            left: auto;
            right: 0;
        }
    }

    &.moveTop {
        .endTips {
            top: auto;
        }
    }

    &.moveBottom {
        .endTips {
            top: -50px;
            bottom: auto;
        }
    }

    .popover-title {
        background: $primary;
        color: $light;
        text-transform: uppercase;
        font-family: $primaryFont;
        text-align: center;
        @include rounded(0);
        padding: 10px 14px;
        height: 40px;
        border: none;
    }

    .popover-content {
        color: $basic;
        font-family: $basicFont;
    }

    .popover-navigation {
        position: absolute;
        width: 100%;
        top: 1px;
        left: 0;

        button {
            position: absolute;
            top: 0;
            border: none;
            outline: 0;
            @include rounded(0);
            background: $secondary;
            font-size: 2em;
            color: $light;
            height: 40px;
            transition: background .2s ease, color .2s ease;

            &.prevStep {
                left: 1px;
            }

            &.nextStep {
                right: 1px;
            }

            &.disabled {
                display: none;
                cursor: pointer;
            }

            .icon {
                top: 5px;
                position: relative;
            }

            @include hover {
                background: none;
                color: $light;
            }
        }
    }

    .endTips {
        background: $dark;
        color: $light;
        border: none;
        @include rounded(0);
        font-family: $basicFont;
        margin: 10px auto;
        display: block;
        padding: 4px 20px;
        text-align: center;
        position: absolute;
        border: 1px solid $light;
        right: 0;
        transition: background .2s ease, color .2s ease;

        @include hover {
            background: $light;
            color: $dark;
        }
    }
}

.swipeTutorial {
    display: none;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);

    @keyframes swipeTutorial {
        from {
            right: 0%;
        }

        to {
            right: 40%;
        }
    }

    img {
        position: fixed;
        bottom: 20px;
        max-width: 260px;
        height: auto;
        animation: swipeTutorial 1200ms;
        animation-delay: 500ms;
        animation-fill-mode: both;
    }

    p {
        position: fixed;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        width: 90%;
        display: block;
        padding: 0 40px;
        font-size: 2em;
        text-align: center;
        color: $light;
    }
}

@media (max-width: $lg) {
    .swipeTutorial img {
        max-width: 200px;
    }
}

@media (max-width: $sm) {
    .swipeTutorial img {
        max-width: 150px;
    }

    .popover[class*="tour-"] {
        min-width: auto;
        width: 100%;

        .popover-navigation {
            button {
                width: 40px;
                padding: 0;
                text-align: center;

                &.prevStep {
                    left: 1px;
                }

                &.nextStep {
                    right: 1px;
                }
            }
        }
    }

    .endTips {
        top: -48px;
    }

    .tipIcon {
        display: none;

        .inSection & {
            display: block;
            bottom: auto;
            top: 25px;
            right: 25px;

            span {
                border: none;

                &:before {
                    display: none;
                }
            }
        }
    }

    .mobileTips {
        display: none !important; //REMOVE AFTER FEATURE APPROVED
    }
}

@media (max-width: $xs) {
    .swipeTutorial {
        img {
            max-width: 120px;
        }

        p {
            top: 20%;
        }
    }
}

@media (max-width: $xxs) {
    .swipeTutorial {
        img {
            max-width: 100px;
        }

        p {
            font-size: 1.8em;
            padding: 0 20px;
            top: 10%;
        }
    }
}
