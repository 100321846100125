.imgGallery {
  .imgWrap {
    .item {
      position: relative;
      display: none;
      padding-top: 50px;
    }

    .slideImg {
      @include backgroundCover;
      width: 100%;
      height: 600px;

      img {
        display: none;
      }
    }

    .caption {
      @include transparentBG($light, 0.98);
      color: text-contrast($light);
      width: calc(100% - 20px);
      position: absolute;
      bottom: 10px;
      text-align: left;
      left: 10px;
      display: block;
      padding: 15px;

      .inner {
        max-height: 190px;
        overflow: hidden;
        overflow-y: auto;
        @include scrollbar($color: $primary, $width: 25px, $bgColor: $grey);

      }

     

      

      

      // a {
      //   &:focus {
      //     background: $primaryHover;
      //     color: text-contrast($primaryHover);
      //   }

      //   @include hover {
      //     background: $primaryHover;
      //     color: text-contrast($primaryHover);
      //   }
      // }

      .inner {
        width: 100%;
        padding-left: 20px;
        padding-right: 0px;
      }
    }

    .carousel-inner > .active,
    .carousel-inner > .next,
    .carousel-inner > .prev {
      display: block;
    }
  }

  .copyBG {
    display: none;
  }

  .content, .titleWrap {
    max-width: 1050px;
    margin: 0 auto;
  }

  .slideSide {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    position: static;
  }

  .slideCopy {
    width: 100% !important;
    margin: 0 auto 15px !important;
    float: none !important;
    padding-bottom: 0;
  }

  .carousel-indicators {
    top: 0px;
    background: $primary;
    width: 100%;
    left: 0;
    margin: 0;
    height: 50px;
    padding-right: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      border-color: $light;
      height: 8px;
      width: 8px;
      margin: 0px 2px;

      &.active {
        background-color: $light;
        height: 10px;
        width: 10px;
      }
    }
  }
}

.viewCapBtn {
  position: absolute;
  top: -31px;
 left: 0;
  @include transparentBG($primary, .9);
  color: text-contrast($primary);
  z-index: 200;
  height: 31px;
  font-family: $primaryFont;

  .txt {
    display: block;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 700;
    padding: 3px 7px 3px 20px;

    &:before {
      @include iconFont;
      text-transform: none;
      font-size: 1.2em;
      font-weight: normal;
      left: 3px;
      @include vertCenter;
      position: absolute;
      content: 'v';
      padding-bottom: 0px;
    }
  }

  .showCap {
    display: none;

    &:before {
      font-size: 1.2em;
      right: 7px;
      content: 'w';
    }
  }

  @include hover {
    cursor: pointer;
    background: $secondary;
    color: text-contrast($secondary);
  }
}

.imgGallery {
  .imgWrap {
    .capHidden {
      .caption {
        padding: 5px 15px;

        .inner {
          max-height: 0px;
          overflow: hidden;
        }
      }

      .viewCapBtn {
        top: -25px;
        .hideCap {
          display: none;
        }

        .showCap {
          display: block;
        }
      }
    }
  }
}

.galleryNav {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 50;
  display: flex;
  .carousel-control {
    font-size: 1.4em;
    flex-grow: 1;
    height: 100%;
    opacity: 1;
    width: 50%;
    text-align: center;
    background: $secondary;
    box-shadow: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;

    .icon {
     box-shadow: none;
     text-shadow: none;
    }
  }

  .carousel-control {
    &:focus {
      @include bgWithContrast($secondaryHover);
      text-decoration: none !important;
    }

    @include hover {
      @include bgWithContrast($secondaryHover);
      text-decoration: none !important;
    }
  }

  .left {
    left: 4px;
  }

  .right {
    left: auto;
    right: 4px;
  }
}

@media (max-width: 1215px) {
  .imgGallery {
    .imgWrap {
      max-width: 960px;
    }
  }
}

@media (max-width: $md) {
  .imgGallery {
    .imgWrap {
      max-width: 800px;

      .slideImg {
        height: 500px;
      }

      .caption {
        .inner {
          max-height: 150px;
        }
      }
    }

    .carousel-indicators {
    }
  }
}

@media (max-width: $sm) {
  .imgGallery {
    .imgWrap {
    

      .caption {
        padding: 5px 15px;

        .inner {
          max-height: 130px;
          padding-left: 0px;
        }
      }

      .slideImg {
        height: 400px;
      }
    }
  }

  .galleryNav {
    .carousel-control {
      width: 40px;
      height: 50px;

      .icon {
        width: 40px;
      }
    }
  }
}

@media (max-width: $xs) {
  .imgGallery {
    .slideTitle {
      margin-bottom: 0;
    }

    .imgWrap {
      .caption {
        .inner {
          max-height: 150px;
        }
      }

      .slideImg {
        height: 300px;
      }
    }
  }
}
