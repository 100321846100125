@mixin questionMax() {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 100px;
}

.masterQuizSlide,
.surveySlide {
  width: 100%;


  #learnMoreWrapper {
    display: none !important;
    opacity: 0 !important;
  }

  .evalContent {
    @include questionMax;
  }

  .slideCopy {
    float: none;
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  h3 {
    display: block;
  }

  .questionBlock {
    @include clearfix;
    width: 100%;
    margin: 5px 0;
    @include transition($speed:.4s);

    &.error {
      .innerWrap {
        border: 2px solid $wrong;
      }
    }

    &:first-child {
      margin-top: 30px;
    }

    &.horizontal {
      @media (min-width: 651px) {
        .question {
          border-radius: 10px 10px 0 0;
        }

        .Answer,
        .answer {
          display: flex;
          align-content: stretch;
          width: 100%;
          text-align: center;
          border-radius: 0 0 10px 10px;

          div {
            margin: 0;
            padding: 0;
            width: 25%;
            border-right: 2px solid $grey;
            position: relative;

            &:first-child {
              span {
                border-radius: 0 0 0 10px;
              }
            }

            &:last-child {
              span {
                border-radius: 0 0 10px 0;
              }

              border: 0;
            }

            span {
              display: table-cell;
              padding-top: 10px;
              padding-bottom: 10px;
              @include fillSpace;
              vertical-align: middle;
              background: $light;
            }

            &.selected {
              span {
                @include bgWithContrast($primary);
                font-weight: 600;
              }
            }

            @include hover {
              span {
                @include bgWithContrast($primary);
                font-weight: 600;
              }
            }
          }

          label {
            display: table;
            vertical-align: middle;
            @include fillSpace;
          }

          input {
            @include vertHorzCenter;
            position: absolute;
          }
        }
      }
    }

    &:not(.horizontal) {
      .answer {
        padding: 10px 10px 15px;
        background: $light;

        &.twoCol {
          column-count: 2;
        }

        .checkbox {
          float: none;
          width: 100%;
          text-align: left;
          @include rounded(40px);

          @include hover {
            background: $primary;

            span {
              color: text-contrast($primary);
              font-weight: 600;
            }
          }

          &.selected {
            background: $primary;

            span {
              color: text-contrast($primary);
              font-weight: 600;
            }
          }
        }

        span {
          padding: 5px 5px 5px 36px;
          display: block;
        }

        label {
          display: block;
          position: relative;
        }

        input {
          @include vertCenter;
          position: absolute;
          left: 15px;
        }
      }

      .question {
        padding-top: 15px;
      }

      select {
        max-width: 350px;
        width: 100%;
        padding: 5px 10px;
      }
    }
  }

  .commentBox {
    label {
      display: block;
    }
  }

  .questionFieldset {
    margin: 0;
    padding: 0 0 7px;

    textarea {
      resize: vertical;
      max-height: 350px;
      min-height: 40px;

      .ie11 & {
        min-width: 100%;
        max-width: 750px;
      }
    }
  }

  .Answer,
  .answer {
    border: 2px solid $grey;
    border-radius: 0 0 10px 10px;

    .checkbox {
      page-break-inside: avoid;

      label {
        line-height: 1.2em;
      }

      span {
        position: relative;
        cursor: pointer;
        color: $basic;
      }

      input {
        left: 7px;
      }

      @include hover {
        span {
          color: $light;
          font-weight: 600;
        }
      }
    }
  }

  .question {
    margin-bottom: 0;
    padding: 10px 15px;
    border: 0 !important;
    @include bgWithContrast($grey);
    border: 2px solid $dark;
    font-family: $primaryFont;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 1.45em;
    @include legendFix();
    border-radius: 10px 10px 0 0;

    .ques {
      p {
        display: inline;
      }
    }
  }

  #quiz-submit,
  #survey-submit.btn {
    margin: 15px auto;
    text-align: center;

    @include rounded(0);
    @include button;
    display: block;
    //padding: 10px 35px;
    font-size: 1.2em;
    font-weight: 700;
  }

  &.graded {

    .backToMenuBtn {
      cursor: pointer;
      margin: 0 auto 10px auto;
      text-align: center;
      display: block;
      @include rounded(0);
      padding: 10px 25px;
      @include bgWithContrast($primary);
      box-shadow: inset 0 0 0 2px $primary;
      text-transform: uppercase;
      font-size: 1.125em;
      border: 1px solid white;
      float: right;
      @include transition;

      @include hover {
        @include bgWithContrast($primaryHover);
      }
    }

    .questionBlock {
      display: none !important;

      &.review {
        display: block !important;
      }

      input {
        display: none;
      }

      .wrong {
        cursor: default;

        &:before {
          @include iconFont;
          content: "b";
          @include bgWithContrast(darken($wrong, 15%));
          float: left;
          margin: -5px 0 0 -15px;
          font-size: 1.5em;
          padding: 5px;
          @include rounded(50%);
          position: relative;
          z-index: 99;
          border: 2px solid $wrong;
        }

        span {
          @include bgWithContrast($wrong);
          font-weight: bold;
        }

        @include hover {
          span {
            color: $light !important;
            font-weight: bold;
          }
        }
      }

      .correct {
        cursor: default;

        &:before {
          @include iconFont;
          content: "a";
          @include bgWithContrast(darken($correct, 15%));
          float: left;
          margin: -5px 0 0 -15px;
          font-size: 1.5em;
          padding: 5px;
          @include rounded(50%);
          position: relative;
          z-index: 99;
          border: 2px solid $correct;
        }

        span {
          @include bgWithContrast($correct);
          font-weight: bold;
        }

        @include hover {
          span {
            color: $light !important;
            font-weight: bold !important;
          }
        }
      }

      .answer {
        div {
          cursor: default;

          @include hover {
            cursor: default !important;
            background: transparent !important;

            span {
              color: $basic;
              font-weight: normal;
            }
          }

          span {
            margin-top: 0;
          }
        }
      }
    }

    #survey-submit.btn,
    #quiz-submit {
      display: none;
    }
  }

  &.completed {

    .questionBlock,
    .reviewText {
      display: none;
    }
  }

  .gradedMessages {
    background: $primary;
    padding: 15px;
    text-align: center;

    h2 {
      color: text-contrast($primary);
      font-size: 1.75em;
    }

    h3 {
      display: inline-block;
      @include bgWithContrast($light);
      font-weight: bold;
      padding: 5px 10px;
    }

    p {
      color: text-contrast($primary);
    }
  }
}

.cm-s-neat {
  text-align: left;
  @include bgWithContrast($primary);
  padding: 15px;
  font-size: 1.25em;
  @include rounded(5px);
  @include questionMax();
  max-width: 960px;
  margin-bottom: 25px;

  p {
    margin-bottom: 0;
  }

  &.introContent {
  }
}

.surveySlide {
  .questionBlock {
    .answer {
      div {
        color: $light !important;
      }
    }
  }

  .surveyComplete {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
  }
}

.ie8 {
}

//*********COMMENTBOXES*********/

#dc0, #dc1, #dc2, #dc3, #dc4, #dc5, #dc6,
#dc, #dc8, #dc9, #dc10, #dc11, #dc12, #dc13,
#dc14, #dc15, #dc16, #dc17, #dc18, #dc19, #dc20 {
  @include bgWithContrast($primary);
  padding: 10px;
  @include transition($speed:.4s);
  display: block !important;
  @include opacity(100, 1);
  visibility: visible !important;

  &.hidden {
    @include opacity(0, 0);
    visibility: visible !important;
    display: block !important;
    height: 0;
    overflow: hidden;
    padding: 0;

    textarea {
      height: 0;
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    color: $basic;
  }
}

@media (max-width: $xs + 50) {

  .masterQuizSlide,
  .surveySlide {
    form {
      .questionBlock {

        .Answer,
        .answer {
          padding: 10px 10px 15px !important;

          &.twoCol {
            column-count: 1 !important;
          }

          .checkbox:not([data-type="nestedOptions"]) {
            float: none;
            width: 100%;
            text-align: left;
            @include rounded(40px);

            @include hover {
              background: $primaryHover;

              span {
                color: text-contrast($primaryHover);
                font-weight: 600;
              }
            }

            &.selected {
              background: $primary;

              span {
                color: text-contrast($primary);
                font-weight: 600;
              }
            }
          }

          span {
            padding: 5px 5px 5px 36px;
            display: block;
          }

          label {
            display: block;
            position: relative;
          }

          input {
            @include vertCenter;
            position: absolute;
            left: 15px;
          }
        }
      }
    }
  }
}
