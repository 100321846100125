

.faqMode {}

/*
.faqBtn{
	background:$light!important;
    border-top:2px solid $primary!important;
    border-right:2px solid $primary!important;
    border-bottom:2px solid $primary!important;
    height: calc(100% - 2px)!important;
    .icon{
        color:$primary!important;
    }
}*/
#faqContentWrap {
  display: none;
  z-index: 11;
  position: fixed;
  height: 100%;
  top: 0;
  @include clearfix;
  width: 100%;
  left: 0px;


  
  .closeFaqBtn {
    @include button($accent, $light);
    text-transform: none;
    width: 58px;
    height: 58px;
    position: fixed;
    top: -150px;
    left: 7px;
    font-size: 1.8em;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0px;
    display: flex !important;
    font-family: $iconFont;
    z-index: 50;
    transition: top .3s, background-position .15s, opacity .3s ;
    opacity: 0;
    .faqMode &{
      opacity: 1;
      top: 10px;
      transition: top .3s, background-position .15s, opacity .3s .5s;
    }
    &:after{
      display: none;
      
    }

  }
}

#faqContent {
  width:calc((100vw - #{$sb}));
  margin: 0 auto;
  position: absolute;
  padding: 0px 40px 95px 80px;
  color: $basic;
  height: 100%;
  top: 0px;
  left: -100%;
  @include transition($speed: 1s);
  background: $light;
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  @include scrollbar($color: $primary, $width: 25px, $bgColor: $grey);
  @media (max-width: $mid){
    width:calc((100vw - #{$sbL}));
  }
  @media (max-width: $md){
    width:calc((100vw - #{$sbM}));
  }
  @media (max-width: $tween){
    width:100vw;
  }
 

.faqInnerContent{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

  //border-top: 45px solid $basic;
  &.results {
    width: calc(100vw - #{$sb}) ;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    @include scrollbar($color: $primary, $width: 25px, $bgColor: $grey);
    @media (max-width: $mid){
      width:calc((100vw - #{$sbL}));
    }
    @media (max-width: $md){
      width:calc((100vw - #{$sbM}));
    }
    @media (max-width: $tween){
      width:100vw;
    }

    .questCol {
      max-width: 500px;
      width: 100%;
      margin: 0px;
     .btnWrap{
       flex-direction: column;
       justify-content: center;
       input{
         font-size: 1.2em;
         margin-bottom: 5px;
         margin-left: 0px;
       }
     }
    }
    .resultsCol {
      right: 0;
      display: block;
      flex-grow: 1;
      .accordionButton{
        line-height: 1.2em;
      }
    }
  }
  
  &.showing {
    left: 0;
  }
  
  &.noFooter {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    @include scrollbar($color: $primary, $width: 25px, $bgColor: $grey);
    
    
    .resultsCol {
      min-height: 400px;
    }
  }
  
  .questCol {
    text-align: center;
    padding: 0px 0px 20px;
   max-width: 750px;
   margin: 0 auto;
    width: 100%;
    margin-top: 10px;
    max-height: 100%;
    overflow-y: auto;
    @include transition(0.5s);
    background: $primary;
    border: 5px solid $primary;

    #question {
      display: block;
      padding: 15px;
      width: 100%;
      min-height: 150px;
      max-height: 250px;
      max-width: 100%;
      border-width: 0px 0px 0px 0px;
      margin: 0 auto 20px;
      font-size: 1.1em;
     // border: 1px solid $grey;
      background: lighten($grey, 15%);

    }
    .btnWrap{
      display: flex;
      justify-content: flex-end;
      padding: 0px 20px;
      input{
        @include button($accent, $primary);
        color: $light;
        font-size: 1.15em;
        margin-left: 25px;
        padding: 10px 15px 10px;
        @include hover{
          color: $primary;
        }
      }
    }
    .faqQuestWrap{
      margin: 0 auto;
      .askQuest, .btnWrap{
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .sendQuestionText {
      display: none;
      padding-bottom: 20px;
      padding-top: 20px;
      border-top: 1px solid;
      margin-top: 20px;
      
      &.showing {
        display: block;
      }
    }
    
    .sendQuestion {
      display: none !important;
      text-transform: uppercase;
      
      &.showing {
        display: block !important;
        max-width: 200px;
        margin: auto;
      }
    }
    
    h2 {
      text-align: center;
    }
    
    .error {
      background: $basic;
      color: $light;
      padding: 5px 15px;
      display: block;
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
  
  .resultsCol {
     padding: 0px 50px 20px 30px;
    display: none;
    opacity: 0;
    overflow-y: auto;
    @include transition(0.5s);
    &.in{
      opacity: 1;
    }
    @media (max-width: $md){
      padding: 0px 20px 0px 15px;
    }
    @media (max-width: $sm){
      padding: 5px 0px 20px;
    }
    .message {
      background: $secondary;
      color: $basic;
      padding: 10px 15px;
      font-size: 1.4em;
      font-family: $primaryFont;
      font-weight: 900;
    }
    
    .accordionButtonWrapper {
     // margin-top: 0 !important;
    }
    
    .accordionWrapper {
      font-size: 1.25em;
     // font-size: 1.165em;
    }
  }
  
  
  .questNotAnswered {
    width: 100%;
    color: $light;
    height: auto;
    background: $wrong;
    padding: 15px 10px ;
  margin-bottom: 10px;
    overflow-y: auto;
    transition: all .5s ease;
    margin: auto;
    display: none;
    &.showing {
      display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  margin-bottom: 10px;
    }
    
    h3 {
      color: $light;
      background: $wrong;
      font-size: 1.4em;
      font-weight: 700;
    }
    
    .btn {
      @include button($light,$wrong);
      margin-left: 15px;
      font-size: 1.3em;
      color:$light;
      @include hover{
        color: $primary;
      }
    }
  }
  .questNotAnsweredLightBox {
    font-family: $secondaryFont;
    display: none;
    @include fixed;
    @include fillSpace;
    background: rgba($basic, 0.85);
    z-index: 50;
    
    .questNotAnsweredLightBoxWrap {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      margin: auto;
      color: $light;
      text-align: center;
      background: $light;
      padding: 60px;
      max-width: 800px;
      right: 0;
      
      h3 {
        font-family: $primaryFont;
        font-weight: 900;
        font-size: 1.6em;
        color: $primary;
        text-transform: uppercase;
      }
      
      .questionToBeAnswered {
        display: block;
        margin: 15px 0;
        font-size: 1.25em;
        font-family: $basicFont;
        color: $basic;
      }
      
      .submitQuestion, .cancel {
       // @include button($primary);
       @include button($accent, $light);
        font-size: 1.2em;
        margin: 10px 10px 0px;
        padding: 10px 15px;

        &:after{
          display: none;
        }
        
        @include hover {
          color: $light;
        }
      }

      
      .btn {
      //  min-width: 100px;
      }
    }
  }
  
  .questThanks {
    @include fixed;
    @include fillSpace;
    background: rgba($basic, 0.85);
    z-index: 1200;
    
    .questThanksWrap {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      margin: auto;
      color: $basic;
      text-align: center;
      background: $light;
      padding: 60px;
      max-width: 800px;
      right: 0;
    }
    
    h3 {
      font-size: 1.9em;
      font-weight: 900;
      color: $primary;
    }
    
    h4 {
      font-family: $basicFont;
      font-weight: 300;
      font-size: 1.5em;
    }
    
    .thanksCloseBtn {
      @include button($accent, $light);
      margin-top: 20px;
      font-size: 1.3em;
      @include hover {
        color: $light;
      }
     
    }
  }
  
  .titleWrap {
    margin-top: 15px;
    margin-bottom: 55px;
    text-align: center;

    p{
      // font-size: 1.25em;
      // font-family: $primaryFont;
      // color: $primary;
      // font-weight: bold;
      // font-weight: 700;
      // margin-bottom: 0px;
      // padding: 3px 15px;
      // display: inline-block;
      // background: $primary;
      // color: $light;
      // margin-top: 10px;

      font-style: italic;
    font-weight: 300;
    font-size: 1.5em;
    border: 1px solid $secondary;
    border-width: 1px 0px 1px 0px;
    padding: 15px;
    font-weight: 700;
    font-family: $secondaryFont;
    color: $primary;
    margin-top: 15px;
    }
  }
  
  h1 {
    font-size: 3.8em;
    max-width: 100%;
    font-family: $primaryFont;
      font-weight: 900;
    margin-left: 80px;
    margin-top: 15px;
    margin-bottom: 55px;
    color: $primary;
    position: relative;
    padding-bottom: 45px;
    &:after{
      @include iconFont;
      content: 'Z';
      color: $secondary;
      font-size: .3em;
      @include horzCenter;
      position: absolute;
      bottom: 0px;
    }
    
  }
}

@media (max-width: $mid){
 
 
}

@media (max-width:$md), (max-height: $xsH) {
 
}

@media (max-width: $tween){
 
 #faqContent{
  h1{
    margin-left: 0px;
  }
 }
}

@media (max-width:$sm) {
  #faqContentWrap {
   
    h1{
      font-size: 4em;
     
    }
    .closeFaqBtn{
      width: 45px;
      height: 45px;
      font-size: 1.75em;
    }

  }
  
  #faqContent {
    padding-left: 60px;
   // padding: 35px 20px 95px 20px;
   .faqInnerContent{
     flex-direction: column;
   }
 

    &.results{
      .questCol{
        margin: 0 auto;
        // width: 95%;
        // margin: 20px auto;
        // min-height: 0px;
        // padding-bottom: 40px !important;

        
      }
    }
    &.noFooter{
     overflow-x: hidden !important;
     overflow-y: auto !important;
   
     .resultsCol{
       min-height: 0px;
     }
    }
    .titleWrap {
      // border-radius: 0px;
      // h1{
      //   font-size: 3em;
      // }

    }
    
    .questCol,
    .resultsCol {
      // position: relative !important;
      // top: auto !important;
      // bottom: auto !important;
      // left: auto !important;
      // right: auto !important;
      // height: auto !important;
      // overflow-y: visible;
      // padding: 25px 25px 100px 25px !important;
      // width: 95%;
      //   margin: 0px auto 50px;
    }
  
    
    
    .accordionButton {
 
    }
    
    .questNotAnsweredLightBox {
      .questNotAnsweredLightBoxWrap {
         top: 50px;

      }
    }
    .questThanks{
      .questThanksWrap{
        top: 50px;
      }
    }
  }
}

@media (max-width: $xs){
  #faqContent {
    width: 100%;
    h1{
      font-size: 3em;
      margin-bottom: 35px;
      padding-bottom: 25px;
    }
    .titleWrap{
      margin-bottom: 25px;
      p{
        font-size: 1.25em;
        line-height: 1.2em;
      }
    }

    .questCol{
      .btnWrap{
        flex-direction: column;
        gap: 5px;
      }
    }
  }
 
}

@media (max-width: $tiny){
  #faqContent .questCol .btnWrap input{font-size: 1em !important;}
}

