@import '_gameWordSearch.scss';
@import '_gameMatching.scss';

@mixin halfBG($color) {
  @include pseudoContent;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  background: darken($color, 5%);
}


.miniGameSlide {
  .slideMain {
    position: relative;
  }

  .intFrame {
    margin-top: 35px;

    @media (max-width: $md) {
      margin-top: 10px;
    }

    .frameBG {
      @include absolute;
      @include fillSpace;
      opacity: .5;
      z-index: 1;
      @include backgroundCover;
      filter: grayscale(1);
    }

    .frameContent {
      z-index: 2;
      position: relative;
      @include fillSpace;
      padding: 25px;

      @media (max-width: $sm) {
        padding: 5px;
      }

      &.loading {
        .gameHolder {
          opacity: 0;
        }
      }
    }
  }

  .titleHolder {
    margin: 0 auto;
    margin-bottom: 25px;
    @include absolute;
    width: 100%;

    .slideTitle {
      font-size: 1.8em;
      margin-bottom: 0;
      display: block;
      padding: 15px 20px;
    }
  }
}


.introCopyLB {
  @include absolute;
  @include fillSpace;
  z-index: 25;

  &.viewed {
    display: none;

    .inner {
      top: -30px;
      opacity: 0;
      padding: 20px 25px;
    }
  }

  .inner {
    position: relative;
    top: 43%;
    transform: scale(1) translateY(-57%);
    max-width: 750px;
    margin: 0 auto;
    border: 2px solid $secondary;
    border-radius: 10px;
    @include bgWithContrast($primary);
    padding: 35px;
    opacity: 1;
    @include transition(.75s);

    &.loading {
      transform: scale(0) translateY(-55%);
      opacity: 0;
    }
  }

  h2 {
    font-size: 1.8em;
    font-family: $primaryFont;
    text-transform: uppercase;
    font-weight: 700;
    @include zeroMargPad;
    text-align: center;
    padding: 0 0 20px;
    line-height: 1.5em;
    max-width: 650px;
    width: 100%;
    margin: 0 auto !important;
  }

  p {
    font-size: 1.1em;
    line-height: 1.6em;
  }

  .copy {
    @include useGPU;
  }

  .btn {
    margin-top: 20px;
    animation: pulse 1.7s ease-in-out infinite;

    span {
      font-family: $primaryFont;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      padding: 10px 25px;
      font-size: 1.3em;
      @include bgWithContrast($secondary);
      box-shadow: inset 0 0 0 2px $secondary;
      cursor: pointer;

      @include hover {
        @include bgWithContrast($secondaryHover);
      }
    }
  }

  @media (max-width: $lg) {
    .inner {
      top: 45px;
      transform: scale(1) translateY(0);
      width: 94%;
    }
  }

  @media (max-width: $sm + 100) {
    width: 100%;

    .inner {
      width: 94%;
      padding: 20px;
    }
  }

  @media (max-width: $xs) {
    width: 100%;
    left: 0;

    .inner {
      width: 94%;
      max-height: 400px;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      @include scrollbar($color: $primary);
    }
  }

  @media (max-width: $xxs) {

    .inner {
      transform: none;
    }
  }

  @media (max-width: $xs) {
    .inner {
      padding: 15px;
      top: 25px;
      transform: scale(1) translateY(0);
    }

    h2 {
      font-size: 1.35em;
      padding-bottom: 12px;
    }

    p {
      line-height: 1.4em;
      font-size: .95em;
    }
  }
}

.replayButton {
  @include bgWithContrast($primary);
  box-shadow: inset 0 0 0 2px $primary;
  display: inline-block;
  font-weight: 700;
  font-size: .9em;
  text-transform: uppercase;
  padding: 7px 20px;
  margin-top: 15px;
  cursor: pointer;

  @include hover {
    @include bgWithContrast($primaryHover);
  }
}
