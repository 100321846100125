#homeScreen {
 
}

.orientationCompleteMsg{
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100% - #{$sb});
    background: $secondary;
    color: $primary;
    text-align: center;
    padding: 3px 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: none;
    transition: top .35s;
    .loading &, .searchMode &, .utilityOpen &, .setStage &, .helpMode &, .alertMode &{
        top: -200px;
    }
    .orientationComplete &{
        display: block;
    }
    @media (max-width: $mid){
        width: calc(100% - #{$sbL});
    }
    @media (max-width: $md){
        width: calc(100% - #{$sbM});
    }
    @media (max-width: $tween){
        width: calc(100% - #{$sbS});
        top: 75px;
    }
    @media (max-width: $xs){
        width: 100%;
    }

    @media (max-height: $xsH){
        display: none;
    }
}

