html.enlargedMode {
  overflow-y: hidden !important;
}

#slideImageEnlarged {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include transparentBG($basic, 0.75);
  z-index: 9999;
  display: table;
  padding-left: 10%;
  @include opacity(0, 0);
  cursor: pointer;

    overflow: auto !important;


  @media all and (max-width: $sm) {
    padding-left: 0;
    @include transparentBG($basic, 0.90);
    display: block;
    overflow: auto;
  }

  div {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    @media all and (max-width: $sm) {
      width: 100%;
      display: block;
    }
  }

  img {
    width: auto;
    max-height: 100vh;
    height: auto;
    max-width: 1280px;
    
  }

  .close {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    font-size: 2em;
    background: $primary;
    color: text-contrast($primary);
    padding: 10px;
    line-height: 1em;
    float: none;
    @include opacity(100, 1);
    @include transition;

    @media all and (max-width: $sm) {
      padding: 10px;
      font-size: 1.5em;
    }

    @include hover {
      background: $primaryHover;
      color: text-contrast($primaryHover);
    }
  }
}

.slideSide {
  .enlarge {
    cursor: pointer;
    position: relative;

    &:before {
      font-family: $iconFont;
      content: 'z';
      position: absolute;
      top: -5px;
      right: -5px;
      padding: 8px 12px;
      background:$contrast;
      color: text-contrast($contrast);
      display: block;
      @include opacity(100, 1);
      @include transition($speed: .5s);
      font-size: 1.5em;
      z-index: 1;

      @media all and (max-width: $sm) {
        display: none;
      }
    }

    @media all and (max-width: $sm) {
      pointer-events: none;
    }

    @include hover {
      &:before {
        background: $primaryHover;
        color: text-contrast($primaryHover);
      }
    }

    &:first-of-type {
      img {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
