.revealSlide {
  /*********MODAL STYLES*****************/
  &.Modal {
      .content {
          &.disabled {
              .slideCopy {
                 // opacity: .35;
              }

              .slideSide {
                //  opacity: .35;
              }
          }
      }

      &.noImg {
          .slideCopyHolder {
              max-width: 960px;
              width: 100%;
              padding: 0 15px;
              margin: 0 auto 0px;

              .smTip {
                  display: none;
                  opacity: 0;
              }
          }

          .slideSide {
              display: block;
              margin: 0 auto;

              .imgWrap {
                  display: none;
              }

              .tips {
                  opacity: 1;
              }
          }

          .revealContentHolder{
              padding-top: 10px;
          }
      }

      .revealContentHolder {
          width: 100%;
          padding-left: 0;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
         // max-width: 800px;
          align-items: flex-start;
          margin: 0 auto;

      }

      .TitleOnly {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          @media (max-width: $xs) {
              flex-direction: column;
              align-items: center;

              .revealItemHolder {
                  height: 100px !important;
                  width: calc(100% - 10px) !important;
                  margin-left: 0 !important;
                  flex-grow: 0 !important;
              }
          }

          .revealItemHolder {
            //  background: $primary no-repeat center right;
              margin: 10px .5% 0 .5%;
              width: calc(98% * (1/3) - 10px - 1px);
               max-width: 300px;
               min-width: 280px;
             min-height: 150px;
              position: relative;
              flex-grow: 1;
              transition: all .15s;
             

              @include hover {
                  // box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
                   margin-top: -4px;
                   
                   .revealBtn{
                      border-color: $secondary;
                      background: $secondary;
                      filter: drop-shadow(3px 4px 0px $primary);
                       &:before{
                        color: $primary;
                        filter: drop-shadow(2px 2px 0px $accent);
                       }
                       &:after{
                        color: $primary;
                       }
                   }
                   .titleWrap {
                       h2 {
                           margin-top: 0;
                           color: $primary;
                       }
                   }
               }

              .inner, .revealBtn {
                  height: 100%;
                  width: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
              }
          }

          .revealBtnWrap {
              @include absolute;
              @include fillSpace;
               transition: transform .2s;

              .revealBtn {
                  padding: 5px 45px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 2px solid $accent;
                  transition: all .15s;
                  background: $primary;
                  background: linear-gradient(175deg, rgba($primary,1) 1%, rgba(darken($primary,7%),1) 87%);
                  border-radius: 15px 0px 15px 0px;
                
                  
                  &:before {
                      @include iconFont;
                      content: "Y";
                      font-size: 2em;
                      position: absolute;
                     bottom: 5px;
                     right: 5px;
                      z-index: 5;
                      color: $light;
                      transition: all .15s;
                      @media (max-width: $xs){
                          font-size: 1.8em;
                          bottom:8px;
                          right: 8px;
                      }
                  }
                //   &:after {
                //     @include iconFont;
                //     content: "R";
                //     font-size: 2em;
                //     position: absolute;
                //    bottom: 5px;
                //    right: 5px;
                //     z-index: 6;
                //     color: $light;
                //     transition: all .15s;
                //     @media (max-width: $xs){
                //         font-size: 1.8em;
                //         bottom:8px;
                //         right: 8px;
                //     }
                // }
              }
              &.clicked {
                  transform: scale(0) rotateY(60deg);
              }
          }

          .titleWrap {
              color: $light;
              text-align: center;
              position: relative;
              z-index: 3;
              h2 {
                  font-size: 1.3em;
                  font-weight: 700;
                  text-transform: uppercase;
                  line-height: 1.1em;
                  margin: 0;
                  color: $light;
                  font-family: $primaryFont;
              }
          }
      }

      .ImageOnly {
          .revealItemHolder {
              margin: 10px .5% 0 .5%;
              width: 350px;
             // flex-grow: 1;
             
              position: relative;
              aspect-ratio: 1 / 1;
              @supports  (aspect-ratio: 1 / 1) {
                  aspect-ratio: 1 / 1;
                }
                @supports not (aspect-ratio: 1 / 1) {
                  aspect-ratio: auto;
                  &:after{
                      content: "";
                      display: block;
                      padding-bottom: 100%;
                  }
                }
          }

          .revealBtnWrap {
              @include absolute;
              @include fillSpace;
              transition: box-shadow .2s, margin-top .2s, transform .2s;

              @include hover {
                  box-shadow: 0 6px 6px rgba($accent, 0.2);
                  margin-top: -4px;

                  .topicImage {
                      filter: blur(4px);
                      opacity: .85;
                  }

                  .titleWrap {
                      transform: rotateY(0deg) rotateZ(0deg);
                      opacity: 1;

                      h2 {
                          margin-top: 0;
                          opacity: 1;
                      }
                  }
              }

              &.clicked {
                  transform: scale(0) rotateY(60deg);
              }
          }

          .revealBtn {
              @include absolute;
              @include fillSpace;
              overflow: hidden;

              .topicImage {
                  @include absolute;
                  @include fillSpace;
                  @include backgroundCover;
                  transition: filter .2s, opacity .2s;

                  img {
                      display: none;
                  }
              }
          }

          .titleWrap {
              background: rgba($primary, .8);
              top: 20px;
              position: absolute;
              z-index: 2;
              left: 20px;
              width: calc(100% - 40px);
              height: calc(100% - 40px);
              padding: 15px 7px;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: opacity .2s, transform .2s;
              transform: rotateY(50deg) rotateZ(20deg);
              opacity: 0;
              text-align: center;

              &:before {
                  @include pseudoContent;
                  border: 1px solid $primary;
                  width: calc(100% + 20px);
                  height: calc(100% + 20px);
                  position: absolute;
                  left: -10px;
                  top: -10px;
              }

              h2 {
                  color: text-contrast($primary);
                  @include zeroMargPad;
                  font-size: 1.4em;
                  font-weight: normal;
                  font-family: $secondaryFont;
                  position: relative;
                  margin-top: 40px;
                  opacity: 0;
                  transition: opacity .2s .15s, margin-top .4s .15s;

                  &:before {
                      @include iconFont;
                      content: "Y";
                      font-size: 1.4em;
                      @include horzCenter;
                      position: absolute;
                      top: -45px;
                  }
              }
          }

          @media(max-width: $xs) {
              .revealItemHolder {
                  height: 100px !important;
                  width: calc(100% - 10px);
                  margin-left: 0;
                  flex-grow: 0;
              }

              &.noImg {
                  .slideCopyHolder {
                      margin-bottom: 20px;
                  }
              }

              .titleWrap {
                  h2 {
                      padding-top: 0;

                      &:before {
                          top: 0;
                          left: auto;
                          position: static;
                          margin-right: 5px;
                          font-size: 1em;
                      }
                  }
              }
          }

          @media (max-width: $xxs) {
              .titleWrap {
                  h2 {
                      font-size: 1.15em;
                  }
              }

              &.noImg {
                  .slideCopyHolder {
                      padding: 0;
                  }
              }
          }
      }

      .ImageAndTitle {
          .revealItemHolder {
              margin: 10px .5% 0 .5%;
              width: calc(98% * (1/3) - 10px - 1px);
              min-width: 280px;
               max-width: 360px;
              position: relative;
              flex-grow: 1;
              position: relative;
              @supports  (aspect-ratio: 4/3) {
                  aspect-ratio:4/3;
                }
                @supports not (aspect-ratio: 4/3) {
                  aspect-ratio: auto;
                  &:after{
                      content: "";
                      display: block;
                      padding-bottom: 100%;
                  }
                }
          }

          .revealBtnWrap {
              @include absolute;
              @include fillSpace;
              transition: box-shadow .2s, margin-top .2s, transform .2s;
            //  border: 1px solid $grey;
              @include hover {
                 // box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
                  margin-top: -4px;
                  border-color: $secondary;
                  .revealBtn{
                      &:before{
                          transform: scale(1.1);
                          color: $secondary;
                          filter: drop-shadow(-4px -2px 0px $primary);
                      }
                      &:after{
                        color: $primary;
                      }
                  }

                  .titleWrap {
                    //  transform: rotateY(0deg) rotateZ(0deg);
                      opacity: 1;
                      background: $secondary;

                      h2 {
                          margin-top: 0;
                          opacity: 1;
                        
                          color: $primary;
                      }
                  }
              }

              &.clicked {
                  transform: scale(0) rotateY(60deg);
              }
          }

          .revealBtn {
              @include absolute;
              @include fillSpace;
              display: flex;
              justify-content: center;
              align-items: flex-start;

              .topicImage {
                  @include absolute;
                  @include fillSpace;
                  @include backgroundCover;
                  transition: filter .2s, opacity .2s;

                  img {
                      display: none;
                  }
              }
              &:before {
                  @include iconFont;
                  content: "Y";
                  font-size: 2.8em;
                  position: absolute;
                 bottom: 8px;
                 right: 8px;
                  z-index: 5;
                  color: $alt;
                  transition: transform .15s;
                  filter: drop-shadow(3px 4px 0px $primary);
                      
              }
              &:after {
                @include iconFont;
                content: "R";
                font-size: 1.5em;
                position: absolute;
               bottom: 17px;
               right: 18px;
                z-index: 6;
                color: $light;
                transition: all .15s;
            }

          }

          .titleWrap {
              z-index: 2;
              max-width: calc(100% - 10px);
              transition: opacity .2s, transform .2s;
              opacity: 1;
              text-align: center;
              background: $primary;
              margin-top: 5px;
              h2 {
                  color: $light;
                  @include zeroMargPad;
                  position: relative;
                  margin-top: 0;
                  opacity: 1;
                  text-align: center;
                  transition: opacity .2s .15s, margin-top .4s .15s;
                  padding: 8px 10px 5px 10px;
                  line-height: 1.1em;
                  font-size: 1.2em;
                  text-transform: uppercase;
                  font-weight: normal;
                  font-family: $primaryFont;
                  font-weight: 700;
                
              }
          }

          @media(max-width: $xs) {
              .revealItemHolder {
                  height: 100px !important;
                  width: calc(100% - 10px);
                  margin-left: 0;
                  flex-grow: 0;
              }

              &.noImg {
                  .slideCopyHolder {
                      margin-bottom: 20px;
                  }
              }

              .titleWrap {
                  h2 {
                      padding-top: 0;

                      &:before {
                          top: 0;
                          left: auto;
                          position: static;
                          margin-right: 5px;
                          font-size: 1em;
                      }
                  }
              }
          }

          @media (max-width: $xxs) {
              .titleWrap {
                  h2 {
                      font-size: 1.15em;
                  }
              }

              &.noImg {
                  .slideCopyHolder {
                      padding: 0;
                  }
              }
          }
      }

      .revealContentWrap {
          display: none;
      }
  }
  ////*****MODAL HOLDER STYLES*****////
  .modalHolder {
      @include fixed;
      @include fillSpace;
      opacity: 0;
      transition: opacity .2s;
      &.show {
          z-index: 100;
          background-color: rgba($light,.9);
          opacity: 1;
      }

      .modalBox {
          max-width: 650px;
          width: 100%;
          @include horzCenter;
          position: absolute;
          top: 50px;
          transition: margin-top .2s, opacity .15s;
          opacity: 0;
          padding: 15px;
          box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.3);
          background: $alt;


          .inner {
              transform: scale(.3);
              transition: transform .2s;
              overflow: hidden;
              overflow-x: hidden;
              overflow-y: auto;
              @include scrollbar($color: $secondary, $width: 25px, $bgColor: $grey);
              max-height: 400px;
              position: relative;
              z-index: 2;
              margin-bottom: 10px;
              background:$alt;

              .copyWrap {
                  position: relative;
                  z-index: 2;
                  color: text-contrast($primary);
                  padding: 15px 20px;
                  background:$alt;
                 // margin: 10px;
                 a,h2,h3{ color: text-contrast($primary);}
                 h2:before{
                     color: $light;
                 }
                 .largeText{
                   color: $light;
                 }
                 p{
                   span{
                     color: $light;
                   }
                 }

              }

              .topicTitle {
                  text-align:left;
                  font-size: 1.6em;
                  margin-bottom: 15px;
                  color: $light !important;
                  font-family: $primaryFont;
                  font-weight: 900;
                  text-transform: uppercase;
              }
          }

          .topicImage {
              @include absolute;
              @include fillSpace;
              @include backgroundCover;
              opacity: 0;

              img {
                  display: none;
              }
          }

          .closeModal {
              text-align: center;
              position: relative;
              z-index: 2;

              .modalBtn {
                 @include button($light, $secondary);
                
                 font-size: 1.2em;

                  // @include hover {
                  //     background: $secondaryHover;
                  //     color: text-contrast($secondaryHover);
                  // }
              }
          }
      }

      &.show {
          
          .modalBox {
              margin-top: 0;
              opacity: 1;

              .inner {
                  transform: scale(1);
              }
          }
      }

      @media(max-width: $xs), (max-width: $mid) and (max-height: 658px) {
          .modalMode &{
              background: $accent;
              z-index: 250;
              .modalBox{
                  box-shadow: none;
              }
          }
          .modalBox {
              width: 100%;
              transform: translate(0%, 0%);
              top: 0%;
              left: 0px;
              height: 100%;
              .inner{
                  max-height: calc(100% - 70px);
                  .topicTitle{
                      font-size: 1.8em;
                  }
              }
              
          }

         
      }

      @media(max-width: $xxs) {
          .modalBox {
          //     width: calc(100% - 5px);
          //     transform: translate(0%, 0%);
          //    // top: 15%;
          //     left: 0;

          //     &:before {
          //         height: calc(100% - 10px);
          //         width: calc(100% - 10px);
          //         top: 5px;
          //         left: 5px;
          //     }

          //     .inner {
          //         .copyWrap {
          //             margin: 5px;
          //             padding: 10px 20px;

          //             p {
          //                 font-size: .95em;
          //                 line-height: 1.5em;
          //             }
          //         }

          //         .topicTitle {
          //             margin-top: 10px;
          //             font-size: 1.25em;
          //         }
          //     }
          }
      }
  }
}
