
.slide {
  &.videoSlide, &.centerC{
    .tips{
      margin-left: 30px;
      width: calc(100% - 30px);
      margin-top: 15px;
      max-width: 1000px;
      .tipTitleHolder{
        width: 100px;
        height: 100px;
        .tipTitle{
          span{
            font-size: 1em;
          }
        }
        .tipImage{
          max-width: 75px;
        }
      }
      .tipTitleHolder{
        left: -40px;
      }
      .tip{
        background: $primary;
        border-radius: 10px;
        padding: 25px 20px 15px 90px;
        margin-bottom: 25px;
      }
    }
  }
  .tips {
    position: relative;
    padding-top: 20px;
    width: calc(100% - 50px);
    margin-left: 40px;
    margin-top: 40px;
    transition: transform .5s, opacity .5s;
   .tipTitleHolder{
     width: 140px;
     height: 140px;
     position: absolute;
     left: 0px;
      top: 0px;
      filter: drop-shadow(4px 5px 0px #fff) drop-shadow(0px -5px 0px #fff);
     &:after{
       background: $secondary;
       width: 130px;
       height:130px;
       @include pseudoContent;
       content: '';
       border-radius: 50%;
       position: absolute;
       bottom: 0px;
     }
     .tipImage{
       max-width: 110px;
       position: absolute;
       transform: translateX(-50%) scale(0);
       z-index: 5;
       left: 50%;
       bottom: 5px;
       width: 100%;
       transition: transform .25s;
      
       img{
         width: 100%;
       }
     }

     .tipTitle{
      position: absolute;
      bottom: -5px;
      transform-origin: center center;
      transform: rotate(-8deg);
      z-index: 10;
      color: $light;
      flex-direction: column;
    align-items: center;
    display: flex;

      
      span{
        background: $alt2;
        font-family: $secondaryFont;
        font-weight: 900;
        font-style: italic;
        line-height: 1em;
        border: 2px solid $alt;
        display: inline-block;
        font-size: 1.3em;
        padding: 3px 10px 5px;
        border-radius: 3px;
        transition: transform .3s;
      }
      .top{
        transform: translateX(-55px) ;
      }
      .bottom{
        transform: translateX(55px) translateY(-1px);
        padding-right: 7px;
      }
     }
   }

   .tip{
     padding-left: 170px;
     color: $light;
     line-height: 1.5em;
     font-size: 1.05em;
     transition: .35s;
     h2, .largeText{color: $light;}

     a{
       background: $primary;
       color: $secondary;
       display: inline;
       padding:0px 2px;
       border-radius: 3px; 
     }
   }
  }

  .current & {
    .tips{
      .tipTitleHolder{
        .tipImage{
          transform: scale(1) translateX(-50%);
         
        }
        .tipTitle {
          .top{transform:translateX(0px);}
          .bottom{
            transform: translateX(18px) translateY(-1px);
          }
        }
      } 
    } 
  }
  .movingOut &{
    .tip{
      opacity: 0;
      transform: translateY(15px);
    }
  }
}

@media (max-width: $mid){
  .slide{
    .tips{
      margin-left: 0px;
      width: calc(100% - 20px);
      padding-top: 0px;
      .tipTitleHolder{
        width: 120px;
        height: 120px;
        .tipTitle{
          span{
            font-size: 1.1em;
          }
        }
        .tipImage{
          max-width: 90px;
        }
      }
      .tip{
        padding-left: 140px;
        font-size: .95em;
        color: $light;
      }
    }
  }
}

@media (max-width: $tween) and (min-width: $sm + 1), (max-width: $xs){
  .slide{
    .tips{
      // width: 100%;
      // padding-top: 120px;
    }
  }
}


@media (max-width: $sm){
  .slide{
    .tips{
      margin-left: 30px;
      width: calc(100% - 30px);
      margin-top: 15px;
      .tipTitleHolder{
        width: 100px;
        height: 100px;
        .tipTitle{
          span{
            font-size: 1em;
          }
        }
        .tipImage{
          max-width: 75px;
        }
      }
      .tipTitleHolder{
        left: -40px;
      }
      .tip{
        background: $primary;
        border-radius: 10px;
        padding: 45px 20px 15px 15px;
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: $xxs){
  .slide{
    .tips{
      width: 100%;
      margin-left: 0px;
      margin-top: 60px;
      .tipTitleHolder{
        @include horzCenter;
        position: absolute;
        top: -60px;
        .tipTitle{
          left: -8px;
        }
      }
      .tip{
        padding-top: 60px;
      }
    }
  }
}

