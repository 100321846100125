.blueBG{
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100vh;
  width: $sb;
  background: $primary;
  transition: width .5s;
  .setStage &{
    width: 40%;
  }
  .helpMode &, .loading &{
    width: 0px;
  }
}
.headerBar{
  position: fixed;
  width: $sb;
  right: 0px;
  top: 0px;
  height: 100vh;
  padding: 30px 20px 30px 23px;
  transition: right .5s;
  .setStage &{
    right: - $sb;
  }
  .helpMode &{
    right: - $sb;
  }

  .branding{
    width: 100%;
    margin: 0 auto;
    .logoWrap.rsp{
      display: none;
    }

    img{
      max-width: 100%;
    }
  }
}

@media (max-width: $mid){
  .blueBG{
    width: $sbL;
  }
  .headerBar{
    width: $sbL;
  }
}

@media (max-width: $md){
  .blueBG{
    width: $sbM;
  }
  .headerBar{
    width: $sbM;
    padding: 30px 10px 30px 13px;
  }


}

@media (max-width: $tween), (max-height: $xsH){
  .rspHeaderBG{
    height: $rspHeader;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    background: $primary;
    transition: height .5s, top .5s;
    z-index: 9;
    .loading &, .helpMode &, .alertMode &, .searchMore &, .utilityOpen &{
      top: -400px;
    }
    .setStage &{
      top: -400px;
    }
  }

  .headerBar{
    width: 100%;
    height: $rspHeader;
    padding: 15px 25px;
    transition: top .5s;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .branding{
      width: 250px;
      margin: 0px;
      .logoWrap{
        display: none;
        &.rsp{
          display: block;
        }
      }
    }
    .loading &, .helpMode &, .alertMode &, .searchMore &, .utilityOpen &, .setStage &{
      top: -400px;
    }
  }
  .blueBG{
    width: $sbS;
    .alertMode &{
      width: 0px;
    }
  }
}

@media (max-width: $sm){
  .blueBG{
    .setStage &{
      width: 250px;
    }
  }
}

@media (max-height: $xsH){
  .blueBG{
    width: 0px;
    .setStage &{
      width: 43%;
    }
  }
}

@media (max-width: $xs){
  .blueBG{
    width: 0px;
    .setStage &{
      width: 0px;
    }
  }
  
}

@media (max-width: $xxs){
  .headerBar{
    padding: 15px;

    .branding{
      width: 220px;
    }
  }
}

