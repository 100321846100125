.videoSlide {
  .videoWrapper {
    padding-right: 0 !important;
  }

  .videoContainer {
    margin-bottom: 15px;
  }

  &.vidBelow {
  
  }

  .videoRTE {
    background: $light;
    border-radius: 15px;
    padding: 10px 20px;
    margin-top: 10px;
    max-width: 1000px;
    margin: 10px auto;

    p, h2, h3, h4, span {
     
    }
  }
}

.videoWrapper {
  .loading {
    display: block;
    color: $grey;
    font-size: 1.2em;
    font-family: $primaryFont;
    text-align: center;
    padding-top: 40px;
    text-transform: uppercase;
  }
}

.autoplaySet {
  display: none;
}

.videoHolder, #videoHolder {
  position: relative;
  background: $dark url('/images/load-screen.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 56.3%; //for 16:9 ratio videos
  padding-top: 0;
  height: 0;
 // box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

  .chrome &, .android & {
    &:before {
      display: none !important;
    }
  }

  &:before {
    content: '';
    background: $dark url('/images/load-screen.jpg') no-repeat;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
  }

  &.hideLoad, .hideLoad & {
    background: none;

    &:before {
      display: none !important;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

@media (max-width: $lg) {
  // .videoSlide {
  //   .slideCopy {
  //     width: 40%;
  //   }

  //   .slideSide {
  //     width: 60%;
  //   }
  // }
}

@media (max-width: $md + 100) {
  // .videoSlide {
  //   .slideCopy {
  //     width: 43%;
  //   }

  //   .slideSide {
  //     width: 57%;
  //     padding-right: 20px !important;
  //   }
  // }
}

@media (max-width: $md) {
  // .videoSlide {
  //   .slideCopy {
  //     width: 43%;
  //   }

  //   .slideSide {
  //     width: 57%;
  //     padding-right: 20px !important;
  //     margin-top: 0;
  //   }
  // }

  // .videoHolder, #videoHolder {
  //   &:before {
  //     display: none;
  //   }
  // }
}

@media (max-width: $xs) {

  .videoSlide {
    // .slideSide {
    //   padding-right: 0 !important;
    // }

    // &.vidBelow {
    //   .content {
    //     padding: 25px 20px !important;
    //   }
    // }
  }
  .videoWrapper{
    border: 1px solid rgba($dark, .5);
  }
}
