/**MIXINS**/
@mixin header {
  color: $primary;
  font-family: $primaryFont;
  text-transform: uppercase;
}

@mixin titleFont {
  @include header;
  font-weight: 400;
}

@mixin mainHeadFont {
  @include header;
  font-weight: 700;
}

@mixin bodyCopy {
  color: $basic;
  font-family: $basicFont;
}

@mixin button($color: $accent,$bg: $light, $hoverColor: $secondary, $hoverText: $alt2 ) {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px 10px 15px;
  line-height: 1.1em;
  border: 2px solid $color;
  border-radius: 0;
  text-align: center;
  font-family: $basicFont;
  color:$primary;
  background: linear-gradient(90deg, $color 50%, $bg 50%);
  outline-offset: 2px;
  text-decoration: none;
  background-size: 200%;
  background-position: 100%;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-position .15s;
  cursor: pointer;
  &:after{
    content: 'R';
    @include vertCenter;
    position: absolute;
    right: 12px;
    @include iconFont;
  }
  @include hover {
    color: text-contrast($color);
    background-position: 0%;
    &:after{
      color: text-contrast($color);
    }
  }

  a {
    text-decoration: none;
    color: text-contrast($color);
    @include transition;
  }

  @include hover {
    a {
      text-decoration: none;
      color: text-contrast($hoverColor);
    }
  }

  @media (max-width: $xs){
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin headerHeight {
  height: $headerHeight;
  @include transition($speed: .3s);

  .inSection & {
    height: $innerHead;
  }

  @media (max-width: $lg) {
  }

  @media (max-width: $md) {
    .inSection & {
    }
  }

  @media (max-width: $sm) {
    .inSection & {
    }
  }
}

@mixin innerFoot {
  height: $innerFoot;
  @include transition($speed: .3s);
}

@mixin topBarHeight {
  border-top: 3px $light solid;
  height: $innerHead;

  @media (max-width: $lg) {
    height: 80px;
  }

  @media (max-width: $md) {
    height: 60px;
  }
}

@mixin formWidth {
  max-width: 600px;
  width: 100%;
}


@mixin maxWidth {
  max-width: $maxWidth;
  margin: 0 auto;
}

@mixin break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;
}


@mixin reformatList {
  list-style-type: none;
  @include zeroMargPad;
  @include clearfix;
}

@mixin imgResponsive {
  width: 100%;
}


/***ADA MIXINS***/
@mixin adaHeaderFont {
  color: $primary;
  font-family: $primaryFont;
}

@mixin adaBtns {
  display: inline-block;
  @include bgWithContrast($primary);
  border: 2px solid $primary;
  font-family: $primaryFont;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1em;
  padding: 7px 10px;
  text-decoration: none;
  cursor: pointer;
  @include rounded(5px);

  &:focus {
    @include bgWithContrast($ADAhover);
  }

  @include hover {
    @include bgWithContrast($ADAhover);
  }
}

@mixin adaBtnHover {
  text-decoration: underline;
}

@mixin adaSchoolLogo {
  padding: 20px 25px;
  font-family: $primaryFont;
  padding: 8px 0 5px 97px;
  float: left;
  margin: 8px 0 0 0;
  padding: 0;
}

@mixin adaIconFont {
  font-family: 'ada' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
