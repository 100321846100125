[data-content=fwContentW]{
    &.setStage{
        .blueBG{
            width: 100%;
        }
    }
}

.slide {
    /***DEFAULT & COPY SLIDE***/
    .slideTitle.rsp{
        display: none;
    }
    .slideSide {
        width: 46%;
        
        @media (min-width: $sm + 1){
            position: sticky;
            top: 15px;
        }
    }

    .slideCopy {
        width: 50%;
        transition: transform 1s, opacity 1.25s;
        
        .inner{
            max-width: 800px;
        }
        
    }
    /***VIDEO SLIDE***/
    &.videoSlide {
        .slideFlexWrap{
            flex-direction: row-reverse;
        }
        
        .slideSide {
            width: 54%;
        }

        .slideCopy {
            width:44%;
        }
    }
    /***QUIZ SLIDE***/
    &.quizSlide {
        .slideSide {
            width: 46%;
        }

        .slideCopy {
            width: 50%;
        }
        &.noImg{
            .slideCopy{
                width: 100%;
            }
        }
    }
    /***NO IMG***/
    &.noImg {
        .slideFlexWrap {
            flex-direction: column;
            max-width: 1050px;
            margin: 0 auto;
            background: $light;
            border-radius: 3px;
        }

        .slideSide {
            max-width: 800px !important;
            width: 100%;
            margin: 0 auto;
            float: none;
        }

        .slideCopy {
            max-width: 800px !important;
            width: 100%;
            margin: 0 auto;
            float: none;
        }
        .tips .tip{color: $alt2;}
    }

    &.imgGallery {
        .slideFlexWrap {
            flex-direction: column;
            max-width: 1050px;
            margin: 0 auto;
            background: $light;
            border-radius: 3px;
            flex-direction: column-reverse;
        }
    }
    &.checkSlide, &.learnMore{
        .slideFlexWrap{
            flex-direction: column;
        }
    }

    &.vidBelow {
        .slideFlexWrap {
            max-width: 1500px;
            margin: 0 auto;
            width: 100%;
            flex-direction: column;
        }
        .content{
            padding: 15px 0px 50px 0px;
        }

        .slideTitle.rsp{
            display: block;
            max-width: 1500px;
            margin: 0 auto;
            span{
                
            }
        }
        @media (max-width: $xl){
            .slideFlexWrap{
                max-width: 1200px;
                padding: 15px 150px 50px 90px;
            }
            .slideTitle.rsp{
                max-width: 1200px;
            }
        }
        @media (max-width: $lg){
            .slideFlexWrap{
                padding: 15px 100px 50px 100px;
            }
        }
        @media (max-width: $md){
            .slideFlexWrap{
                padding: 15px 20px 50px 100px;
            }
        }
        @media (max-width: $sm){
            .slideFlexWrap{
                padding: 0px 20px 50px 60px;
            }
           
        }
        @media (max-width: $xs){
            .slideFlexWrap{
                padding: 0px 25px 50px 25px;
            }
        }

        .slideSide {
            width: 100%;
            max-width: 1160px;
            margin: 0 auto;
            padding-bottom: 0px;
            position: relative;
            z-index: 2;
        }

        .slideCopy {
            width: 100%;
            margin: 0 auto !important;
            background: $light;
            padding: 25px;
            max-width: 1000px;
            border-radius: 15px;
            .inner{
                margin: 0 auto;
            }
            .slideTitle{
                display: none;
            }
            @media (max-width: $xs){
                padding: 25px 0px;
            }
        }
    }

    &.interactiveSlide{
        .slideFlexWrap {
        flex-direction: column;
        }
    }
    &.surveySlide{
        .slideFlexWrap {
        flex-direction: column;
        }
    }

    

    .slideFlexWrap {
        @include flexJustify;
        align-items: flex-start;
        /*Comment this out if you want to align vertically centered*/
    }

    @media (max-width: $sm) {
        background: $light;
        padding-top: 25px;
        .slideFlexWrap {
            flex-direction: column;
            /*If the html needs to switch, use column-reverse*/
            justify-content: flex-start;
            align-items: flex-start;
        }

        .slideSide {
            width: 100% !important;
            margin-bottom: 20px;
        }
        

        .slideCopy {
            width: 100% !important;
            background: $light;
           // padding: 20px;
        }

        &.videoSlide {
           .slideFlexWrap{
            flex-direction: column;
           }
           .slideTitle{
            display: none !important;
           }
           .slideTitle.rsp{
            display: block !important;
           }
        }

        &.copySlide {
            .slideSide {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}
