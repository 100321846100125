
.accHolder{
  border: 1px solid $alt;
  margin-bottom: 8px;
  transition: margin .35s;
  background: rgba($light,.85);
  .largeText{
    font-size: 1.4em;
    margin-bottom: 25px;
    padding-top: 0px;
  }
  &.open{
     .accordionButton{
      
       
     }
  }
  &.clicked{
     // margin-top: 28px;
     .accordionButtonWrapper{
       @include hover{
         .accordionButton{
           &:before{
           // transform: translateY(-3px) translateX(2px);
           }
         }
       }
     }
      .accordionButton {
          cursor: pointer;
          color:$light;
          margin-bottom: 10px;
          background: $alt;


         // transform: translate(0px, -25px);
          &:before {
              text-transform: uppercase;
              transform: rotate(-90deg);
              top: 4px;
              color: $light;
              background: $alt;
          }
      }
  }

}
p + .accHolder, h2 + .accHolder{
  margin-top: 20px;
  &.clicked{
      margin-top: 20px;
  }
}
.accHolder + p, .accHolder + h2{
  margin-top: 25px;
}
.accordionButtonWrapper {
 // padding: 5px;
  @include hover {
      cursor: pointer;
      .accordionButton{
          color: $light;
          background: $alt;
          &:before{
          background: $alt;
          color: $light;
          }
          .inner{
              //background-position: -100%;
          }
      }
     
  }

  &.open {
      // .accordionButton {
      //     cursor: pointer;
      //     color: text-contrast($primaryHover);
      //     transform: translate(0px, -25px);
      //     &:before {
      //         text-transform: uppercase;
      //         content: 'v';
      //     }
      // }
  }

  p {
      border-left: 0 !important;
      padding-left: 0 !important;
  }
}

.accordionButton {
  font-size: 1.3em;
  font-family: $primaryFont;
  background: $light;
  color: $primary;
  font-weight: bold;
  outline: none !important;
  padding: 12px 15px 12px 30px;
  position: relative;
  margin-bottom: 0;
  display: block;
  transform: translate(0px, 0px);
  transition: transform .45s;
  .inner{
   // background: $light;
     // transition: all 300ms;
  }
  &:before {
      @include iconFont;
      top: 7px;
      position: absolute;
      left: -15px;
      text-transform: lowercase;
      content: 'R';
      font-size: .8em;
      transition: transform .2s;
      height: 35px;
      width: 35px;
      background: $light;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $alt;
      transform-origin: center center;

  }
}

.accordionWrapper {
  display: none;
  padding: 10px 20px 25px;
  margin-bottom: 3px;
 
  background: $light;

  p:not(.termContent) {
      border-left: 0 !important;
      padding-left: 0 !important;
  }


  .learnMoreWrapper {
      
  }
}

@media (max-width: $xs) {
  .accordionButton{
     
  }
}

@media (max-width: $xxs) {
  .accordionButtonWrapper {
     
  }
  .accordionButton{
    font-size: 1.2em;
    line-height: 1.3em;
    padding-left: 35px;
  }
}
