@font-face {
    font-family: 'ung';
    src:  url('../fonts/ung.eot?bxxfsi');
    src:  url('../fonts/ung.eot?bxxfsi#iefix') format('embedded-opentype'),
      url('../fonts/ung.woff2?bxxfsi') format('woff2'),
      url('../fonts/ung.ttf?bxxfsi') format('truetype'),
      url('../fonts/ung.woff?bxxfsi') format('woff'),
      url('../fonts/ung.svg?bxxfsi#ung') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ung' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow-down-right:before {
    content: "\76";
  }
  .icon-arrow-up-right:before {
    content: "\77";
  }
  .icon-slide-self-direct:before {
    content: "\47";
  }
  .icon-slide-game:before {
    content: "\48";
  }
  .icon-icons_SaveExit:before {
    content: "\e903";
  }
  .icon-multi-dots:before {
    content: "\5a";
  }
  .icon-learn-more:before {
    content: "\68";
  }
  .icon-pin_bubble_rotated:before {
    content: "\e901";
  }
  .icon-achievement:before {
    content: "\74";
  }
  .icon-announce:before {
    content: "\65";
  }
  .icon-check:before {
    content: "\61";
  }
  .icon-faq:before {
    content: "\72";
  }
  .icon-glossary:before {
    content: "\71";
  }
  .icon-lingo:before {
    content: "\73";
  }
  .icon-home:before {
    content: "\e900";
  }
  .icon-link:before {
    content: "\64";
  }
  .icon-add:before {
    content: "\75";
  }
  .icon-arrow-outline:before {
    content: "\6c";
  }
  .icon-arrow-solid:before {
    content: "\6d";
  }
  .icon-x:before {
    content: "\62";
  }
  .icon-feather:before {
    content: "\46";
  }
  .icon-hexagon_point:before {
    content: "\59";
  }
  .icon-hexagon_point_stroke:before {
    content: "\79";
  }
  .icon-left-chevron:before {
    content: "\4c";
  }
  .icon-right-chevron:before {
    content: "\52";
  }
  .icon-search:before {
    content: "\66";
  }
  .icon-star:before {
    content: "\f005";
  }
  .icon-slide-multi:before {
    content: "\4d";
  }
  .icon-search-plus:before {
    content: "\7a";
  }
  .icon-lock:before {
    content: "\63";
  }
  .icon-print:before {
    content: "\6f";
  }
  .icon-slide-copy:before {
    content: "\45";
  }
  .icon-slide-video:before {
    content: "\43";
  }
  .icon-slide-test:before {
    content: "\44";
  }
  .icon-slideview:before {
    content: "\f065";
  }
  .icon-quickview:before {
    content: "\f06e";
  }
  .icon-menu:before {
    content: "\f0c9";
  }
  .icon-slide-survey:before {
    content: "\4a";
  }
  .icon-slide-reveal:before {
    content: "\49";
  }
  .icon-slide-quiz:before {
    content: "\4b";
  }
  .icon-angle-double-right:before {
    content: "\51";
  }
  .icon-angle-double-right1:before {
    content: "\57";
  }
  .icon-exclamation:before {
    content: "\78";
  }
  .icon-pdf:before {
    content: "\70";
  }
  .icon-pin_bubble:before {
    content: "\e902";
  }
  