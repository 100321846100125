.ie11, .ie {
  .ieNotice {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    padding: 10px 20px;
    background: linear-gradient(90deg, gold, lightsalmon);
    text-align: center;

    p {
      margin: 0 !important;
    }
  }
}
.errorMsg.fieldWrapper{
  color: $secondary;
  background: $primary;
}
.Message{
  ul{
    background: $primary;
    color:$secondary;
  }
}

.loginPage {

  form {
    font-size: 1em;
  }

  input:not([type=checkbox]):not([type=radio]),
  select,
  textarea {
    position: relative;
    display: block;
    width: 100%;
    height: 58px;
    margin: 0;
    border: 2px solid $light;
    padding: 25px 10px 12px;
    border-radius: 3px;
    white-space: normal;
    line-height: 1;
    @include colorWithContrast($basic);
    font-size: 1.15em;
    text-align: left;
    transition: border-color 200ms;
    appearance: none;

    &:focus {
      border-color: darken($primaryHover, 5%);
    }
  }

  select {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 25px 50px;
    padding: 26px 6px 12px;
    text-indent: 0;

    .webkit &, .chrome & {
      padding-left: 10px;
    }

    &::-ms-expand {
      display: none !important;
    }
  }

  #PhoneNumber {
    padding-left: 45px;
  }

  #backgroundTexture{
    background: url('../images/Login_Background.svg') no-repeat;
    @include backgroundCover;
    @include fixed;
    @include fillSpace;
    opacity: .3;
  }

  button {
    @include button($accent,$primary);
    color:$light;
    font-size: 1.3em;

    &.infoBtn {
      @include button($accent,$secondary);
      width: 22px;
      height: 22px;
      padding: 0;
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 9;
      border: none;
      border-radius: 50%;
      &:after{
        display: none;
      }

      &:before {
        content: 'h';
        font-family: $iconFont;
        font-size: .8em;
        text-transform: lowercase;
      }
    }
  }

  option {
    appearance: none;
    margin: 0;
    padding: 0;
  }

  label:not(.checkboxLabel) {
    position: absolute;
    top: 8px;
    left: 11px;
    z-index: 2;
    display: block;
    margin: 0 0 5px;
    border: 0;
    padding: 0;
    line-height: 1;
    white-space: normal;
    font-size: 1em;
    font-family: $primaryFont;
    color: $dark;
    pointer-events: none;
    text-transform: uppercase;
    transition: all 300ms;
  }

  .fieldGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-width: 0 0 1px 0;
    border-color: rgba(text-contrast($primary), 0.5);
    border-style: solid;
    padding-bottom: 10px;
    margin-bottom: 25px;
    width: 100%;

    .groupTitle {
      width: 100%;
      font-family: $primaryFont;
      font-weight: 900;
      color: text-contrast($primary);
      font-size: 1.2em;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    .fieldWrapper {
      width: 100%;
      margin: 0 0 20px;
    }

    select[multiple] {
      height: 100%;
      background-image: none;
    }
  }

  .fieldWrapper {
    position: relative;
    margin: 0 auto 20px;
  }

  .buttonHolder {
    text-align: center;
  }

  .error, .loginError {
    font-weight: bold;
    font-size: .85em;
    display: block;
    padding: 5px 5px 5px 50px;
    @include bgWithContrast($basic);
    text-align: left;
    margin-top: -5px;
    position: relative;

    &:before {
      font-family: $iconFont;
      content: 'x';
      @include absolute;
      @include bgWithContrast(lighten($wrong, 20%));
      padding: 5px 15px;
    }

    &.validation-summary-errors {
      background: transparent;
      padding: 0;
      margin-top: 0;

      &:before {
        display: none;
      }

      ul {
        margin: 0 0 10px 0;
        padding: 0 15px;
      }

      li {
        list-style: none;
        padding: 5px 5px 5px 50px;
        @include bgWithContrast($basic);
        text-align: left;
        position: relative;

        &:before {
          font-family: $iconFont;
          content: 'x';
          @include absolute;
          @include bgWithContrast(lighten($wrong, 20%));
          padding: 5px 15px;
        }
      }
    }
  }

  .field-validation-error {
    list-style: none;
    padding: 5px 5px 5px 30px;
    @include bgWithContrast($basic);
    text-align: left;
    position: relative;
    font-size: .85em;
    display: block;
    margin-top: -5px;
    height: auto;

    &:before {
      font-family: $iconFont;
      content: 'u';
      @include absolute;
      @include bgWithContrast(lighten($wrong, 20%));
      padding: 5px 7px;
      height: 100%;
    }
  }

  header {
    padding-right: 0;
  }

  footer {
    &.ADGBranding {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $basic;
      z-index: 500;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;
      display: none;

      .adgLogoWrap {
        a {
          display: block;
        }

        .adgLogo {
          height: 43px;
          width: auto;
        }
      }
    }
  }
}

.accountPG {
  h3 {
    color: text-contrast($primary);
    text-align: center;
    margin-top: 35px;
  }
}

.loginHeader{
  margin-bottom: 30px;
  .logoWrap{
    max-width:300px;
    margin: 0 auto;
    img{
      width: 100%;
    }
  }
}
.loginPage{
  #mainContent{
    display: flex;
    justify-content: flex-end;
  }
}

#membership {
  // border: 10px solid $primary;
  background: $primary;
  border-radius: 7px;
 max-width: 650px;
 width: 95%;
  padding: 45px;
  position: relative;
  z-index: 1;
  margin:25px auto 70px;
  .titleArea{
    text-align: center;
    padding-bottom: 25px;
    h1{
      font-family: $primaryFont;
      font-weight: 700;
      padding-bottom: 5px;
      font-size: 2.3em;
      line-height: 1.2em;
      color: $light;
    }
    p{
      font-family: $primaryFont;
      font-weight: 500;
      font-size: 1.3em;
      color: $light;
    }
  }

}
.landingPage  {
  
  .formArea{
   display: flex;
   max-width: 520px !important;
   width: 100%;
   justify-content: center;
   margin: 0 auto;
 
   flex-direction: column;
   align-items: center;
  }
 
   a {
       @include button($accent, $primary);
       color: $light;
       max-width: 500px;
       width: 100%;
       padding: 20px;
       outline: 0;
       justify-content: center;
       font-size: 1.5em;
     margin: 5px 0px;
       text-align: center;
   }
 }//landing page

 .loginNav {
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid $grey;
  padding-top: 25px;
  width: 100%;
  align-items: flex-end;
  a{
  
  }
 

  a, .btn {
  
      padding: 10px 15px !important;
      border-right: 1px solid $grey;
      text-align: center;
      display: flex;
  align-items: center;
  color: $light;
  &:after{
    display: none;
  }

      &:last-child {
          border-right: 0px;
      }

      &.createLink {
        padding: 0px !important;
        border: 0px;
        margin-left: 15px;
          a {
            @include button($primary, $secondary);
            border-color: $secondary;
            padding: 20px 25px 20px !important;
            font-size: 1.1em;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 0px;

            color: $primary;
            @include hover{
              text-decoration: none !important;
              color: $secondary;
              border-color: $secondary;
            }
            
            &:before {
            }
            @media (max-width: $xs){
              padding: 15px 10px;
              &:before{
                display: none;
              }
            }
          }
      }
  }
  .utLink{
    @include button($accent, $primary);
  }
}

.formWrap {
  padding: 5px 0 35px;
    position: relative;
    color: $light;
    color: $basic;
    max-width: 500px;
    margin: 0 auto;
  .formArea{
    max-width: 850px;
    width: 100%;
  }
  
  p {
      font-family: $basicFont;
      font-weight: bold;
      font-size: 1em;
      text-align: center;
  }

  .loginNav {
      margin-top: 25px;

      a {
          @include button(rgba($alt,.6),$primary);
          color: $secondary;
          font-size: 0.85em;
      }
  }
}//form wrap

.accountProfiler {
  padding: 0 !important;

  h1 {
    width: 100%;
    @include bgWithContrast($primary);
    padding: 0 !important;
  }

  .formInner {
    margin: 10px auto 30px;
  }

  .profileQuestions {
    padding: 0 25px 25px;

    .instruction {
      text-align: center;
      padding-top: 20px;
    }

    .fieldWrapper {
      margin: 0 auto 20px 20px;
    }

    .desc {
      text-align: left;
    }
  }
}

.formWrap.login {
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: transparent;
    max-width: 500px;
    width: 100%;


  
 
  .newStudentWrapper {
    text-align: center;

    .account {
        margin-top: 20px;
    }
    @media (max-width: $xs){
        padding-top: 0px
    }
}
}

.formWrap.userAccount {
  form {
      margin: 0 auto;
      @include clearfix;
  }
}

.formWrap.password {
  .fieldWrapper {
      float: none;
      max-width: 400px;
      width: 100%;
      padding: 0;
  }

  .formArea {
      padding: 5px 15px;
  }

  .message {
      font-size: 1em;
      text-align: center;
      color: $light;
      margin: 0 auto;
      background: $wrong;
      max-width: 400px;
      width: 100%;
      font-weight: 600;
  }
}

// .formArea {
//   padding: 25px 15px;

//   p {
//     margin-bottom: 15px;
//     margin-top: 0;
//   }
// }

// .returningStudentWrapper {
//   border-top: 1px solid text-contrast($primary);
//   margin-top: 20px;
//   padding: 25px 40px;
// }

// .newStudentWrapper {
//   text-align: center;

//   .account {
//     margin-top: 20px;
//   }
// }


.account {
  a {
    @include button($secondary, $secondaryHover);
    margin-top: 10px;
    font-size: 1.2em;
    vertical-align: middle;

    &:before {
      content: 'k';
      text-transform: lowercase;
      font-family: $iconFont;
      text-align: left;
      font-size: 24px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}


.logError {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: $light;
  text-align: center;
  padding: 15px;

  h1 {
    text-transform: uppercase;
    color: $primary;
  }

  .error {
    color: $basic;
    font-size: 1.2em;
  }
}

@media (max-width: 1000px) {
  #membership {
  //  max-width: 100%;
    top: 0px;
  }
}

@media (max-width: $sm) {
  #membership {
    top: 0px;
    position: relative;

    .titleArea {
      margin-top: 22px;
      height: auto;
      padding-bottom: 10px;
      h1{
        font-size: 2em;
      }
    }
  }

  .formArea {
    padding: 0 20px;
  }

  .formWrap {
    width: 100%;
   // margin-top: 30px;
  }

  .newStudentWrapper {
    display: block;
    width: 100%;

    h1 {
      margin-top: 0;
      padding-top: 20px;
    }
  }

  .returningStudentWrapper {
    width: 100%;
    display: block;
    //padding: 25px;

    .fieldWrapper {
     // width: 80%;
    }
  }

  .account {
    padding-bottom: 20px;

    a {
      margin-top: 0;
    }
  }

  .loginPage {
    footer.ADGBranding .adgLogoWrap {
      margin: auto;
    }
  }
}

@media (max-width: $xs) {

  .formWrap {
    //margin-top: 110px;

    &.userAccount {
      margin-top: 20px;
    }

    h1 {
      font-size: 1.5em;
    }
  }
  #membership{
    padding: 45px 20px;
  }

  .loginPage {
    .fieldGroup {
      .fieldWrapper {
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}




@media (max-width: $xxs) {
  .returningStudentWrapper {
    padding: 15px;

    .fieldWrapper {
      width: 95%;
    }
  }
}
