$utilityWidth: 525px;

.openUtilityBtn {
 border: 2px solid $alt;
 @include horzCenter;
 position: absolute;
 bottom: 15px;
 width: 100px;
 color: $light;
 display: flex;
 align-items: center;
 justify-content: center;
 text-transform: uppercase;
 font-weight: 700;
 padding: 7px;
 gap: 5px;
 transition: opacity .3s;
 opacity: 1;
.loading &, .faqMode &{
  opacity: 0;
}
 @include hover{
   background: $alt;
   color: $light;
   cursor: pointer;
 }

}

.xCloseUtilityBtn {
  position: fixed;
  right: -$utilityWidth;
  top: 0;
  z-index: 251;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: $headerHeight;
  border-radius: 5px 0px 0px 5px;
  // border-top: 2px solid $light;
  // border-left: 2px solid $light;
  // border-bottom: 2px solid $light;
  font-size: 2em;
  background: $alt2;
  color: $light;
  
  @include transition;
  cursor: pointer;

  @include hover {
    background: $secondary;
    color: $alt2;
  }

  .inSection & {
    display: none !important;
  }

  &.open {
    right: $utilityWidth;
  }
}

.utilityMenu {
    position: fixed;
    right: -$utilityWidth;
    width: $utilityWidth;
    top: 0;
    background: $alt2;
    height: 100%;
    overflow: auto;
    z-index: 231;
    @include transition();

    &.open {
        right: 0;
    }

    .menuContent {
        border-bottom: 2px solid $grey;
        padding: 25px 0;

        h1 {
            font-weight: 300;
            text-transform: none;
            color: $primary;
            font-size: 2.4em;
            line-height: 1.1em;
        }

        &.UMinfo {
            padding: 25px 15px 0;
            font-family: $primaryFont;
            border-bottom: 0;
            @include headerHeight;

            .UMtitle {
                font-weight: 700;
                text-transform: uppercase;
                color: $light;
                font-size: 1.8em;
            }

            .UMstudent {
                color: $light;
                font-weight: 400;
            }
        }

        &.UMbtns {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 15px 15px 10px;
            background: $light;
            border-bottom: 1px solid $alt2;
        }

        &.toc {
            margin-left: 0;
            padding-left: 0;
            background: $light;

            h1 {
                padding-left: 15px;
                font-size: 1.5em;
                font-family: $secondaryFont;
                font-weight: 900;
            }
        }
        .alphaToc{
          padding: 7px 15px;
        }

        .btn {
            @include button;
            width: calc(50% - 4px);
            margin-bottom: 8px;
            font-size: 1.1em;

            &.adaMenuLink {
                .alpha &, .beta & {
                    display: none !important;
                }
            }
        }

        .closeUtilityBtn {
            @include button($secondary, $secondaryHover);
            display: none;
        }

        .certBtn {
            display: none;
            position: relative;

            .icon-cert {
                @include vertCenter;
                position: absolute;
                left: 5px;
                font-size: 1.25em;
            }
        }
    }
}


//*****TOC******//
#sitemap {
  font-family: $primaryFont;

  ul {
    list-style: none;
    padding-left: 0;

    ul {
      li {
        position: relative;

        @include hover {
          .lockedMsg {
            display: block;
          }

          li {
            .lockedMsg {
            //  display: none;
            }
          }
        }

        &.level-4 {
          @include hover {
            .lockedMsg {
              
            }
          }
        }
      }
    }
  }

  .level-2 {
    @include zeroMargPad;

    &.available {
      .mainLevel {
        .available {
          display: none;
        }
      }
    }

    &.notAvailable {
      .hideUnavailableTopics & {
        display: none;
      }

      .mainLevel {
        .notAvailable {
          display: block;
          font-size: .85em;

          b {
            font-weight: normal;
          }

          &:before {
            content: "(";
          }

          &:after {
            content: ")";
          }
        }
      }
    }

    .tempType {
      display: none;
    }

    .mainLevel {
      font-size: 1em;
      text-transform: uppercase;
      @include bgWithContrast($wrong);
      padding: 8px 10px 8px 20px;
      cursor: default;
      font-weight: 700;
    }

    a {
      font-size: 1.2em;
      @include bgWithContrast($dark);
      padding: 5px 10px 5px 20px;
      cursor: default;
      font-weight: 600;
    }
  }

  .level-3 {
    &.selfDir {
      a {
        font-weight: bold;
      }

      .level-4-link {
        cursor: default !important;
        @include bgWithContrast($grey);
        color:$alt2;
        padding: 5px 10px 5px 40px;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: .85em;
        border-bottom: 0 !important;
        .lockedMsg{display: none;}
        &:before{
          display: none;
        }

        @include hover {
          cursor: default !important;
          @include bgWithContrast($grey);
          color:$alt2;

          &:before {
            display: none;
          }
        }
      }
    }

    &.last {
      a {
        border-bottom: none;
      }
    }

    a {
      text-transform: none;
      font-size: 1em;
      color: $primary;
      background: transparent;
      padding: 7px 10px 7px 20px;
      cursor: pointer;
      position: relative;
      text-decoration: none !important;
      border-bottom: 1px $grey solid;
      outline: 0;

     
    }
  }

  .level-4,.level-3{
    
    a{
      position: relative;
      padding-left: 37px;
      @include hover{
        background: $secondary;
      }
      &:before{
          font-family:$iconFont;
          content: 'a';
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          font-size: 1.1em;
          z-index: 2;
          position: relative;
          pointer-events:none;
          text-align: center;
          width: 30px;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          font-weight: normal;
      }
    }
  }

  .level-4 {

    a {
      padding: 7px 10px 7px 40px;
      font-weight: normal !important;
      border: 0;
      border-bottom: 1px solid $grey !important;
      outline: 0;

      @include hover {
        &:before {
          //left: 15px !important;
        }
      }
    }
  }

  a {
    text-decoration: none !important;
    display: block;
  }

  .locked {
    a {
      cursor: default;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include hover{
        background: transparent;
      }
    }

    .level-3 {
      a:not(.unlockedSlide) {
        @include opacity(50, .50);
      }
    }
  }

  .lockedMsg {
    font-size: .8em;
    padding: 2px 4px;
    min-width: 62px;
     background: $dark;
     color: $light;
     text-transform: uppercase;
     display : inline-block;
     border-radius: 4px;
     text-align: center;
    
   }



   .slideVideoToc{
    a{&:before{content: 'C'}}
  }
  .slideCopyToc{
    a{&:before{content: 'E'}}
  }
  .slideQuizToc{
    a{&:before{content: 'K'}}
  }
  .slideMultiQuizToc{
    a{&:before{content: 'M'}}
  }
  .slideMasterTestToc{
    a{&:before{content: 'D'}}
  }
  .slideSurveyToc{
    a{&:before{content: 'J'}}
  }
  .slideItemsOfIntrestToc{
    a{&:before{content: 'h'}}
  }
  .slideRevealToc{
    a{&:before{content: 'I'}}
  }
  .slideMatchingToc{
    a{&:before{content: 'H'}}
  }
  .slideWordSearchToc{
    a{&:before{content: 'H'}}
  }
  .slideChecklistToc{
    a{&:before{content: 'a'}}
  }
  .slideSelfDirectedToc{
    a.level-3-link{&:before{content: 'G'}}
    &.level-5 a.level-5-link{&:before{content: 'G'}}
  }

  .level-4 > a {
  }

  .multiQuizToc {
    display: none;
  }
}


// @media (max-width: $tween){
//   .openUtilityBtn{
//    // height: calc(100% - 10px);

//    left: auto;
//     right: 5px;
//   //  top: 5px;
//   @include vertCenter;
//  // position: relative;
//     display: flex;
//     //flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     //width: 75px;
//     gap: 5px;
//     // .icon{
//     //   font-size: 1.8em;
//     // }
//     // .text{
//     //   font-size: .8em;
//     // }
//   }
// }

@media (max-width: $tween){
  .openUtilityBtn{
     height: calc(100% - 20px);
    transform: none;
    left: auto;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    gap: 2px;
    .icon{
      font-size: 1.8em;
    }
    .text{
      font-size: .8em;
    }
  }


  .utilityMenu .menuContent .closeUtilityBtn{
    padding-right: 10px;
    padding-left: 25px;
   &:after{
    right: auto;
    left: 12px;
    transform: rotate(180deg) translateY(-50%);
    transform-origin: center center;
   }
  }
}

@media (max-width: $xs){

  .xCloseUtilityBtn.open{
    display: none;
  }
  .utilityMenu{
    width: 100%;
    right: -100%;
    &.open{
      right: 0px;
    }
  }
}


@media (max-width: $xxs){
  .openUtilityBtn{
    width: 45px;
    .icon{
      font-size: 1.5em;
    }
    .text{
      font-size: .7em;
    }
  }
}