#umbracoPreviewBadge {
    position: fixed !important;
    top: 0 !important;
    left: auto !important;
    width: 100% !important;
    height: auto !important;
    background: #bacb0f !important;
    display: block !important;
    padding: 0 4px 10px 4px;
    color: black;
    text-align: center;
    max-height: 80px;
    overflow: auto;
    pointer-events: none;

    &:after {
        display: block;
        font-size: 1em;
        margin-top: 5px;
        content: "PREVIEW MODE: This is a preview, and should be used for content evaluation only. Some features and styles may not act/look exactly the way they do on live site."
    }

    @include hover {
        background: lighten(#bacb0f, 10%) !important;
    }
}

$top: 35px;

.inSection {
    .PreviewMode {
        #inner-content {
            display: block;
        }
    }
}

.PreviewMode {
    .slideBody {
        display: none !important;
    }
}

$smPrev: 38px;

@media (max-width: $md) {
    #umbracoPreviewBadge {
        padding: 0 4px 0 4px;

        &:after {
            display: none !important;
        }
    }

    .PreviewMode {

        .inSection & {
        }
    }
}
