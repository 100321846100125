#searchModeWrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: none;
  height: 100%;
  width: 100%;
  background: transparent;

  .searchMode & {
      display: block;

      .searchResultsPanel {
         right: 0;
      }

      .searchSlideHolder {
          opacity: 1;
      }
  }
  /* Area to hold slide */
  .searchSlideHolder {
      width: calc(100% - 450px);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      transition: background-color .3s ease, opacity .3s;


      &:before {
          content: "SLIDE PREVIEW: This is a preview. Some features and styles may not act/look exactly the way they do in the orientation.";
          position: fixed;
          width: calc(100% - 450px);
          background: $dark;
          color: $light;
          bottom: 0;
          left: 0;
          text-align: center;
          padding: 4px;
          z-index: 10;
          font-size: .9em;
          transition: opacity 1.5s ease;
          opacity: 0;
      }

      &.viewingSlide {
          background: $light;

          &:before {
              opacity: 1;
          }
      }

      .searchMode & {
          opacity: 100 !important;
      }

      .slide {
          background: $light;
          display: none;
          min-height: 100% !important;
          padding: 0 0 40px !important;
          margin-top: 0 !important;
          @include scrollbar($color: $secondary, $width: 25px, $bgColor: $grey);
        overflow-y: auto;
        
        .tips{
            background: $primary;
            padding-bottom: 10px;
            .tipTitleHolder{
                .tipImage{
                  transform: scale(1) translateX(-50%);
                 
                }
                .tipTitle {
                  .top{transform:translateX(0px);}
                  .bottom{
                    transform: translateX(18px) translateY(-1px);
                  }
                }
              } 
        }
        .slideSide{
            .imgWrap{
                height: auto !important;
            }
        }
        &.videoSlide{
            .slideTitle{
                display: none;
                &.rsp{
                    display: block;
                }
            }
        }
          .slideFlexWrap{
              opacity: 1;
              transform: none !important;
          }
          .learnMoreWrapper {
              display: none !important;
          }
          .sectionProgWrap{display: none;}
          .slideTitleHolder{
              min-height:auto;
              h2{
                display: none;
              }
          }
          .slideTitle.rsp{
            display: none;
          }
          
          .slideTitle {
            font-family: $primaryFont;
              display: block;
              text-shadow: none;
              color: $primary;
              font-weight: 700;
              width: 100%;
              margin-bottom: 25px;
             // padding-left: 30px;
              font-size: 3.2em;
              padding: 20px 0px;
              padding-bottom: 45px;
              position: relative;
              &:after{
                @include iconFont;
                content: 'Z';
                color: $secondary;
                font-size: .3em;
                position: absolute;
                bottom: 0px;
                left: 0px;
              }
              
          }

          .tips{

          }
        
          &.interactiveSlide{
              .content{opacity: 1 !important;}
          }
          .slideCopy{
              width: 100%;
              padding-top: 0px;
              .slideCopyInner{
                  opacity: 1;
                  transform: none !important;
              }
          }
          .slideSide{
              position: static;
              height: auto;
              width: 100%;
              padding-top: 0px;
              .imgWrap{
                  max-width: 650px;
                  margin: 0 auto;
              }
              .imgHolder{
                  background: none !important;
                  max-height: none !important;
                  width: 100%;
                  clip-path: none;
                  
                  
                  img{
                      display: block !important;
                  }
              }
          }

          .content {
             // padding: 0 30px 25px;
              width: 100%;
              max-width: 1200px;
              max-height: 100%;
              min-height: 100%;
              flex-direction: column;
              background: $light;
              margin: 0 auto;
          }
      }
  }
  /* Area that holds search results */
  .searchResultsPanel {
      width: 450px;
      position: absolute;
      right: 450px;
      bottom: 0;
      background: $primary;
      height: 100%;
      @include scrollbar($color: $dark, $width: 25px, $bgColor: $grey);
      overflow: auto;
      animation: searchResultsPanelIn 300ms;

      @keyframes searchResultsPanelIn {
          from {
              right: -450px;
          }

          to {
              right: 0;
          }
      }
  }

  .searchBtn {
      position: fixed;
      top: -200px;
      right: 450px;
      z-index: 20;
      background: $alt2;
      color: $light;
      font-size: 2.2em;
      text-transform: uppercase;
      font-family: $primaryFont;
      font-weight: 900;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      transition: top .3s .5s;
      height: $headerHeight;
      border-radius: 5px 0px 0px 5px;
      width: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      .searchMode &{
          top: 0px;
      }

      @include hover {
        background: $secondary;

          .icon {
              color: $primary;
          }
      }
  }
}

.ezsearch-summary {
  color: $light;
  padding: 0 25px;
  font-size: 1.1em;
  margin-bottom: 5px;
  

  strong {
      font-family: $primaryFont;
      font-weight: 700;
  }

  p {
      @include zeroMargPad();
  }
}

.ezsearch-result-count {
  color: $wrong;
  font-style: italic;
  font-size: 0.85em;
  padding: 0 25px;

  p {
      @include zeroMargPad();
  }

  a {
      @include hover {
          color: $link;
          text-decoration: underline;
      }
  }
}

.ezsearch-results {
  padding: 10px;
}

.ezsearch-result {
  margin-bottom: 0;
  padding: 18px 25px;
  position: relative;
  overflow: hidden;
  min-height: 145px;
  border-bottom: 0px solid $dark;
  border: 2px solid transparent;

  .locked {

      .preview,
      .slideTitle,
      .topic {
          opacity: 0.5;
      }

      position: relative;

      @include hover {
          cursor: not-allowed;
          background: transparent;
      }

      .lockedMsg {
          display: none;
          @include absolute;
          width: 100%;
          top: 25px;
          background: $secondary;
          color: text-contrast($secondary);
          font-weight: 700;
          padding: 15px;
          text-align: center;
          font-size: 1.1em;
      }
  }

  .resultContent {
      position: relative;
      left: 0;
      @include transition($speed: 0.3s);
  }

  &.clicked {
    border-color: $secondary;
      .resultContent {
          left: 60px;
      }

      .viewOptions {
          left: 0;
      }
  }

  @include hover {
      cursor: pointer;
      border-color: $secondary;
  }

  &.viewing {
      background: $secondary;

      a {
          color: text-contrast($secondary);
      }

      .preview {
          color: text-contrast($secondary);
      }

      .topic {
          color: text-contrast($secondary);
      }
  }

  .slideTitle {
      font-family: $primaryFont;
      font-weight: 700;
      color: $dark;
      font-size: 1.5em;
      letter-spacing: .025em;
      padding: 0 0 7px;
      display: block;
      text-shadow: none;
      color: $light;
      line-height: 1.2em;
  }

  a {
      display: inline-block;
      color: $light;
      text-decoration: none;
  }

  .preview {
      color: $light;
      @include zeroMargPad();
      font-size: 0.95em;
      margin-bottom: 10px;
  }

  .topic {
      font-family: $primaryFont;
      font-size: 0.8em;
      text-transform: uppercase;
      color: $light;
  }
}

.viewOptions {
  width: 60px;
  height: 100%;
  margin: 10px auto;
  position: absolute;
  @include transition();
  left: -60px;
  top: -10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .view {
      text-align: center;
      
      background: $secondary;
      //padding-top: 20px;
      @include borderBox;
      height: 49%;
      display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
      

      p {
          @include zeroMargPad;
          @include transition();
          font-family: $primaryFont;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0;
         // margin-top: -20px;
          color:$primary;
          height: 0px;
          overflow: hidden;
      }

      .icon {
          @include transition();
          color: $primary;
          font-size: 2em;
          line-height: 1em;
      }

      &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
          height: 49%;
      }

      @include hover {
          background: $primaryHover;
          cursor: pointer;

          p {
              color: text-contrast($primaryHover);
              font-size: 0.85em;
              height: auto;
          }

          .icon {
              color: text-contrast($primaryHover);
              font-size: 0;
             // margin-top: -65px;
              height: 0px;
          }
      }
  }
}

.ezsearch-pager {
  text-align: center;
  background: $basic;
  padding: 5px;
  color: $light;

  p {
      margin: 5px 0;
  }

  span {
      font-weight: bold;
  }

  a {
      color: $light;
      font-weight: normal;

      @include hover {
          text-decoration: underline !important;
      }
  }
}

.ezsearch-no-results {
  background: $secondary;
  color: text-contrast($secondary);
  font-weight: 700;
  font-family: $primaryFont;
  padding: 3px 5px;
  margin: 0 25px;
  text-align: center;

  p {
      @include zeroMargPad;
  }

  strong {
      text-decoration: underline;
      font-size: 1.1em;
      font-style: italic;
  }
}

.quickViewing {
  #searchModeWrap .searchSlideHolder {
      background: $light;
  }
}

.lockedBtn {
  display: none;
}

.inSection {
  .searchBtn {
      right: -50px;
  }

  .alertBarContainer {
      display: none;
  }
}

.searchArea {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto 20px;
  padding: 25px 5% 25px 5%;
  height: $headerHeight;
  background-color: $alt2;
}

.ezsearch-form {
  display: flex;
  align-items: stretch;

  .searchField {
      position: relative;
      display: block;
      width: 100%;
      min-width: 0;
      margin: 0;
      border: 0px;
      padding: 16px 10px 16px;
      border-radius: 0;
      white-space: normal;
      line-height: 1;
      background: $light;
      color: $basic;
      font-size: 1.15em;
      text-align: left;
      transition: border-color 200ms;

      &:focus {
        border-color: $primary;
      }
  }

  .btn {
      @include button($accent, $alt2);
      @include iconFont;
      color: $light;
      font-size: 1.7em;
      margin-left: 8px;
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      padding: 0px 15px;
      @include hover{
        color:$alt2;
      }
  }
}

.summaryWrap {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.resultsBtn {
  background: $secondary;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 3px 10px 3px 35px;
  color: $light;
  text-transform: uppercase;
  font-family: $primaryFont;
  font-weight: 700;
  font-size: 0.9em;

  .icon {
      margin-right: 5px;
      font-size: 1.5em;
      position: absolute;
      bottom: -5px;
      left: 7px;
  }
}

@media (max-width: $md) {
  #searchModeWrap {
      .searchSlideHolder {
          width: 0;

          &.viewingSlide {
              &:before {
                  display: none;
              }
          }
      }

      .searchResultsPanel {
          height: 100%;
          width: 100%;
      }

      .searchBtn {
          width: 75px;
          font-size: 1.1em;
        //   height: 90px;
          .searchMode &{
            top: 0px;
            left: 5px;
          }
      }
  }

  .searchArea {
      padding-left: 90px;
      padding-right: 20px;
  }

  .ezsearch-form {
      .searchField {
          height: 55px;
      }

      .btn {
          height: 55px;
          padding: 5px;
          max-width: 50px;
          width: 50px;
      }
  }
}

@media (max-width: $xs) {
 
}

@media (max-width: $tiny) {
    #searchModeWrap {
        .searchArea {
            padding-left: 80px;
            padding-right: 10px;
        }
        .searchBtn{
            font-size: 1em;
            width: 65px;
            // font-family: $secondaryFont;
            // font-weight: normal;
        }
    }
  }
