$gradient:  linear-gradient(90deg, $secondary  50%, $secondary 100%);
/**************EXPAND STYLES*******************/
.revealSlide {
  &.Expand {
    .revealContentHolder {
      display: flex;
      flex-direction: column;
      
      &.TitleOnly {
        .revealItemHolder {
          @include hover {
            transform: scale(0.99);
          }
        }
        
        .topicImageHolder {
          display: none;
        }
        
        .copyWrap {
          .topicTitle {
            display: none;
          }
        }
      }
      
      &.ImageOnly, &.ImageAndTitle {
        .revealItemHolder {
          &.open {
            .topicImageHolder {
              background:$primary;
            }
            .titleWrap{
              padding: 10px;
              background: $secondary;
            }
            
            .topicImage {
              opacity: 0;
            }
            
            .revealContent {
              opacity: 1;
            }
          }
          
          .revealContentWrap {
            height: 100%;
            overflow: hidden;
            overflow-x: hidden;
            overflow-y: auto;
            @include scrollbar($color: $secondary);
            padding-bottom: 10px;
          }
        }
        
        .titleWrap {
          display: none;
        }
        
        .topicImageHolder {
          @include absolute;
          @include fillSpace;
          background-color: $dark;
          @include transition(.3s);
          
          .topicImage {
            @include absolute;
            @include fillSpace;
            @include transition(.3s);
            @include backgroundCover;
          }
          
          img {
            display: none;
          }
        }
        
        .revealContentWrap {
          @include absolute;
          height: 100%;
          
          .revealContent {
            background: transparent;
            height: 100%;
            opacity: 0;
            // @include transition(.3s);
            transition: opacity .4s ease-in 1s;
          }
        }
      }
    }
    
    //End Reveal Content Holder
    .revealItemHolder {
      margin-bottom: 8px;
      @include transition(.3s);
      @include useGPU;
    }
    
    .revealBtnWrap {
      .revealBtn {
        background: $primary;
        padding: 10px;
        @include transition(.3s);
      }
      
      h2 {
        @include zeroMargPad;
        color: $light;
        font-weight: 700;
        font-size: 1.3em;
        font-family: $primaryFont;
      }
      
      &.clicked {
        .revealBtn {
          background:$secondary;
          padding: 0px;
          .titleWrap{
            padding: 10px;
            background: $secondary;
          }
          h2 {
            color: $secondary;
          }
        }
      }
    }
    
    //End Reveal Btn Wrap
    .revealContentWrap {
      display: none;
      
      .revealContent {
        background: $primary;
        padding: 15px;
        
        .copyWrap {
          color: $light;
          
          h2 {
            color: $light;
          }
          
          h3 {
            color: $light;
          }
          .largeText{
            color: $light;
          }
          
          strong {
            color: $light;
          }
          a{
            color: $light;
          }
        }
      }
    }
    
    &.noImg {
      .content {
        display: block;
        // max-width: 2500px;
        // width: 100%;
      }
      
      .revealContentHolder {
       // padding: 0 15px;
      }
    }
    

    
    &.Grid_Format {
      
      //Expand
      &.noImg {
        .content {
          .slideSide {
        
            
          }
        }
        
        .revealCopy {
          
          .slideCopyHolder {
            width: 100%;
            padding: 0;
            max-width: 960px;
            width: 100%;
            padding: 0 15px;
            margin: 0 auto;
        
          }
          
          .revealContentHolder {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
          }
        }
        
        .revealContentHolder {
          // &[data-qty="4"],&[data-qty="8"],&[data-qty="12"],&[data-qty="16"]{
          //   .revealItemHolder{width: 25%; padding: 0 15px;}
          // }
        }
      }
      
      .revealContentHolder {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        
        &.TitleOnly {
         
          
          .revealItemHolder {
            width: 30%;
            margin: 0px 1% 10px;
            min-width: 250px;
            max-width: 250px;
            flex: 1 1 0;
            @include hover{
              transform: none;
            }
          }
          .revealBtnWrap{
            @include hover{
              .revealBtn{
                background: $gradient;
                color: $primary;
                h2{
                  color: $primary;
                }
                &:before{
                  color: $primary;
                }
              }
            }
            &.open{
              .revealBtn{
                background:$gradient;
                .darkMode & {
                  background: $gradient;
                  border-color: $primary;
                }
                h2{
                  color: $primary;
                }
                &:before{
                  color: $primary;
                  content: 'w';
                 
                }
              }
            }
            .revealBtn{
              text-align: center;
              position: relative;
              padding: 10px 15px 10px;
              padding-left: 45px;
              min-height: 70px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border: 3px solid $primary;
              background: $light;
              h2{
                color: $primary;
              }
              &:before{
                @include iconFont;
                content: 'v';
                top: 0px;
                height: 100%;
                left: 0px;
                position: absolute;
                bottom: 4px;
                width: 35px;
                color: $primary;
                font-size: 1.5em;
                display: flex;
                padding-top: 5px;
                background: $light;
                justify-content: center;
              }
            }
          }
          .revealContentWrap{
            .revealContent{
              background: $primary;
              .copyWrap{
                color: $light;

                strong{
                color: $light;
                } 

                a {
                  color: $light;
                  text-decoration: none;

                  strong {
                    color: $light;
                  }
                }

                h2 {
                  color: $light;
                }

                .introText {
                  color: $light;

                  strong {
                    color: $light;
                  }
                }
           
              }
            }
          }
        }
        
        &.ImageOnly {
          .titleWrap {
            opacity: 0;
          }
          
          .revealItemHolder {
            @include hover {
              .titleWrap {
                opacity: 1;
              }
            }
          }
        }
        
        &.ImageAndTitle, &.ImageOnly {
          justify-content: center;
          
          @media (max-width: $md) {
            .revealItemHolder {
              border-width: 5px;
            }
          }
          
          &[data-qty="2"], &[data-qty="4"] {
            .revealItemHolder {
              margin: 10px 0 0 1%;
              width: calc(98% * (1/2) - 10px - 1px);
              
              @media (max-width: $xxs) {
                margin: 0 auto 10px;
                width: 100%;
              }
            }
          }
          
          .revealItemHolder {
            margin: 10px 0 0 1%;
            flex-grow: 1;
            width: calc(98% * (1/3) - 10px - 1px);
            height: 250px;
            max-height: 2000px;
            
            //border: 10px solid $light;
            @media (max-width: $sm) {
              height: 200px;
              margin: 10px 0 0 1%;
              width: calc(98% * (1/2) - 10px - 1px);
            }
            
            @media (max-width: $xxs) {
              height: 125px;
              margin: 0 auto 10px;
              width: 100%;
              
              &.open {
                //height: 450px !important;
                height: auto !important;
              }
            }
            
            .inner {
              width: 100%;
              
              @include hover {
                .titleWrap {
                  background: $gradient;
                  h2{color: $primary;}
                  h1{color: $primary;}
                }
              }
            }
            
            .revealBtnWrap {
              min-height: 250px;
              padding: 0 0 20px 0;
            }
            
            .copyWrap {
              opacity: 0;
              //padding-top: 50px;
            }
            
            .topicTitle {
              display: none;
            }
            
            .topicImageHolder {
              position: absolute;
              z-index: 1;
              height: 100%;
            }
            
            &.open {
              height: auto;
              border-width: 5px;
              
              .inner {
                height: auto;
                position: relative;
              }
              
              @include hover {
                .titleWrap {
                 // background:transparent;
                }
              }
              
              .titleWrap {
                background:$secondary;
                opacity: 1;
                
                h2 {
                  color: $primary;
                  &:before{
                    content: 'w';
                  }
                }
              }
              
              .topicImage {
                filter: grayscale(.5);
                opacity: 0;
                background-attachment: fixed;
              }
              
              .revealContentWrap {
                .copyWrap {
                  opacity: 1;
                  //padding-top: 50px;
                }
              }
              
              .revealBtnWrap {
                height: auto;
                padding: 0;
                min-height: 1px;
              }
              
              .revealContent {
                //padding: 0;
              }
            }
            
            .titleWrap {
              display: block;
              text-align: center;
              position: relative;
              
              top: 0;
              left: 0;
              z-index: 20;
              width: 100%;
              
              background: $primary;
              
              h2 {
                color: $light;
                font-weight: 700;
                padding: 5px;
                font-size: 1.1em;
                position: relative;
                &:before {
                  @include iconFont;
                  content: "v";
                  display: inline-block;
                  margin-right: 5px;
                  position: absolute;
                  left: 6px;
                  top: 8px;
                  font-size: 1.5em;
                  transition: transform .2s;
                  transform-origin: center center;
                }
              }
            }
          }
          
          .revealContentWrap {
            padding-top: 0;
            width: 100%;
            overflow: auto;
            height: auto;
            position: relative;
            z-index: 10;
            
            .copyWrap {
              //font-size: 1.1em;
              margin: 0 auto;
              width: 100%;
              opacity: 0;
              //padding: 15px;
            }
            
            //  display: block !important;
          }
        }
      }
    }
    
    @media (max-width: $xxs) {
      .revealContentHolder {
        padding: 0 !important;
        
        &.TitleOnly {
          .revealItemHolder {
            // width: 100% !important;
            // min-width: 50px !important;
          }
        }
      }
    }
  }
}
