@-ms-viewport {
  width: auto !important;
}

html {
  /* Shows sidebar at all times */
  // overflow: -moz-scrollbars-vertical;
  // overflow-y: scroll;
  overflow: hidden !important;
  &.membershipPage{
    overflow: auto !important;
    @include scrollbar($color:$secondary, $width: 25px, $bgColor: lighten($grey, 5%));
  }
}

body {
  @include zeroMargPad;
  @include bodyCopy;
  background: $light;
}

body:before {
  content: "" !important;
}

#wrapper {
  position: relative;
}

#mainContent {
  position: relative;
  height: auto;
  padding: 0;
}

img {
  border: 0;
}

a {
  color: $link;
  text-decoration: underline;
 

  @include hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $primaryFont;
  margin: 0;
}

p {
  margin: 0 0 15px;
  word-wrap: break-word;
  hyphens: none;
}

span.available {
  display: none;
}

.printOrientationChecklist {
  display: none;
}

.mainADALink {
  display: inline-block;
  position: fixed;
 
  right: calc(#{$sb} + 10px);
  z-index: 25;
  text-transform: uppercase;
  font-family: $primaryFont;
  font-weight: 700;
  font-size: .9em;
  @include transition;
  color: $light;
  box-shadow: none;
  padding: 5px 12px;
  border: 0px;
  background: $alt;
  text-decoration: none;

    bottom: 15px;
   .utilityOpen &, .menuOut &, .alertMode &, .loading &, .faqMode &, .searchMode &, .alertWidgetMode &, .alertMode &{
      bottom: -100px;
    }
    .helpMode &{
      right: 10px;
    }
 
  

  .alpha &, .beta & {
    display: none !important;
  }

  @media all and (max-width: $xs) {
    .helpTour & {
      z-index: -1 !important;
    }
  }

  &:focus {
    @include bgWithContrast($secondary);
    text-decoration: none;
    outline: 3px solid $secondary;
    outline-offset: 2px;
  }

  @include hover {
    @include bgWithContrast($secondary);
    font-size: 1em;
    text-decoration: none;
  }

  .loading & {
    z-index: 9999999999999 !important;
  }

  .inSection & {
    display: none;
  }

  .returning & {
    display: none !important;
  }

  .utilityOpen & {
    z-index: 230;
  }

  .helpMode & {
    z-index: 9999;
  }

  @media (max-width: $sm) {
    .helpMode & {
      
    }

  }
}

#hiddenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  background: rgba($basic, 0.75);
  transition: opacity 500ms;

  .utilityOpen & {
    pointer-events: auto;
    z-index: 12;
    opacity: 1;
  }

  .searchMode & {
    pointer-events: auto;
    opacity: 1;
  }
}

.noOverflow {
  overflow-y: hidden !important;
}

//*****UNIVERSAL*****//

.container-fluid {
  padding: 0;
}

.row {
  margin: 0 !important;
}

#backgroundCover {
  width: 100%;
  height: 100%;
  @include fixed;
  background-image: url(/images/background.jpg);
  @include backgroundCover;
}

@media (max-width: $mid) {
  .mainADALink {
    right: calc(#{$sbL} + 10px);
  }
}

@media (max-width: $md){
   
   .mainADALink {
    right: calc(#{$sbM} + 10px);
    font-size: .8em;
    .alertWidgetMode &, .menuOut &, .searchMode &, .faqMode &, .utilityOpen &{
     // top: -250px;
    }

    .helpMode &, .loading &{
      // right: 20px;
      // top: auto;
      // bottom: 20px;
    }
  }
}

@media (max-width: $md){
   
  .mainADALink {
   right: calc(#{$sbS} + 10px);
  }
}
@media (max-height: $xsH){
  // .mainADALink{
  //   right: 140px;
  //   .helpMode &, .alertWidgetMode &, .menuOut &, .searchMode &, .faqMode &, .utilityOpen &{
  //     top: -250px;
  //     bottom: auto;
  //   }
  // }
}

@media (max-width: $tween){
  .mainADALink{
   // right: 10px;
    // top: auto;
    // right: auto;
    // bottom: 0px;
    // width: 100%;
    // right: 0px;
    // text-align: center;
    // padding: 15px;
  }
}

@media all and (max-width: $xs) {
  .mainADALink {
   top: auto !important;
   bottom: -250px !important;
   .helpMode &{
    bottom: 10px !important;
   }
  }
}



