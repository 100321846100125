.selfDirList {
  list-style: none;
  clear: both;
  @include clearfix;
  margin-left: 0 !important;
  border: 0px !important;
  border-top: 1px solid $accent !important;
  padding: 25px !important;

  .selfDirItem {
    padding: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 7px;

    &:before {
      display: none;
    }

    a {
      // font-size: 1.2em;
      // display: inline-block;
      // text-decoration: none;
      // padding: 5px 20px;
      // @include transition;
      // padding-left: 40px;
      // position: relative;
      // @include bgWithContrast($primary);
      // box-shadow: inset 0 0 0 2px $primary;
      // font-family: $primaryFont;
      // text-transform: uppercase;
      // font-weight: 600;
      @include button($secondary, $light);
      text-align: left;
      padding: 12px 15px 12px 40px;

      &:after{
        display: none;
      }

      &:before {
        @include transition;
        @include iconFont;
        content: 'Q';
        position: absolute;
        left: 12px;
        top: 3px;
        font-size: 2.1em;
      }
    }

    &.disabled {
      visibility: hidden;

      &:after {
        display: none;
      }
    }
  }
}

.selfDirTable {
  display: block;

  tbody {
    display: block;
  }

  tr {
    display: block;
  }

  .selfDirCell {
    display: block;
  }

  .selfDirBtn {
    @include button;
  }

  a {
    display: block;
    color: $light;
  }
}
