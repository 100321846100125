@keyframes growReveal {
  0%, 100% {
    transform: scale(1)
  }

  40% {
    transform: scale(1.2)
  }

  50% {
    transform: scale(1.2)
  }
}

@keyframes growRevealLg {
  0%, 100% {
    transform: scale(1)
  }

  40% {
    transform: scale(1.3)
  }

  50% {
    transform: scale(1.3)
  }
}

.intInstructions {
  margin-bottom: 15px;
  position: relative;
  @include transition(.35s);

  .intIcon{display: none;}

  h3 {
    color: $primary;
    font-weight: 400;
    text-transform: none;
    font-size: 1.1em;
    padding: 7px 0px 7px 0px;
    margin: 0;
    @include transition(.35s);
  }
}


.matchingSlide {

  .rspTipHolder {
    @include absolute;
    @include fillSpace;
    z-index: 5;
    @include transparentBG($light, .6);
    display: none;
    overflow-y: auto;
    padding-bottom: 50px;

    .closeBtn {
      @include button($secondary,$light);
      margin: 0 auto;
    }

    &.showTip {
      .rspTipWrap {
        animation: growReveal .8s ease-out;
        transform: scale(1);

        p {
        }
      }
    }
    .rspTipWrap{
      background: $primary;
      max-width: 550px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      transform: scale(0);
      padding: 20px;
      border-radius: 10px;
      @include transition($speed: .35s);
      margin-bottom: 50px;
      margin-top: 50px;
    }

    .rspTip {
     width: 100%;
      margin: 0px auto;
      position: relative;
      background: $primary;
      padding: 7px 10px 10px;
      font-weight: 600;
      color: $light;
     // font-size: 1.1em;

      h4 {
        font-size: 1.3em;
        padding-bottom: 10px;
        text-align: center;
        font-family: $primaryFont;
        font-weight: 900;
        letter-spacing: .025em;
      }

      p {
        margin-bottom: 0;
        line-height: 1.5em;
        text-align: left;
        font-weight: 400;
        //font-size: .9em;
        
      }
      a{
        color: $light;
      }
    }
  }
  .tips {
    display: none;
  }

  .thumbImg {
    display: none;
  }

  .rspTipHolder {
    display: none;

    &.showTip {
      display: none;
    }
  }

  .returnContent {
    display: none;
  }

  &.activityComplete {
    .introCopyLB {
      display: none;
    }

    .tips {
      opacity: 1;
      display: block;

      &.smTip {
        display: none;
        opacity: 0;
      }
    }

    .matchingGrid {
      width: 0%;
      transform: scale(0);
    }

    .intInstructions {
      display: none;
    }

    .matchingContent {
      width: 100%;
    }

    .card {
      //width: calc(98% * (1 / 4) - 10px - 1px) !important;

      .back {
       // background-color: $light !important;
      }
    }
  }

  &.activityCompleteReturning {
    .matchingHolder{
      flex-direction: column;
    }
    .matchingGrid {
      width: 100%;
      transform: scale(1);
      .returnContent {
        font-size: 1.3em;
        color: $primary;
        font-family: $primaryFont;
        font-weight: 700;
        line-height: 1.5em;
        display: block;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      .deck {
        display: none;
      }
    }

    .matchingContent {
      width: 100%;
      background: transparent;
      border: 2px solid $secondary;

      .matchingAnswers {

        .matchingAnswersList {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }

        .matchAnswer {
          width: 48%;
          min-width: 190px;
          display: block;
          transform: scale(1);
          padding: 0;
          font-weight: 600;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 8px;
          border-bottom: 1px solid rgba($primary, .2);
          padding-bottom: 10px;

          &:before {
            display: none;
          }

          .matchText {
            display: block;
            width: calc(100% - 120px);
          }

          .text {
            font-weight: 400;
            font-style: italic;
            font-size: .9em;
            line-height: 1.35em;
            margin-bottom: 0;

            a {
              color: $light;
            }

            ol > li:before {
              color: $light;
            }

            ul > li:before {
              color: $light;
            }

            p {
              margin-bottom: 7px;
            }
          }
        }

        .thumbImg {
          display: flex;
          align-items: center;
          height: 105px;
          width: 105px;
          overflow: hidden;
          text-align: center;
          margin: 0 auto;
          border: 3px solid $light;
          @include rounded(50%);
          background-color: $secondary;

          &.fillSpace {
            @include backgroundCover;

            img {
              display: none;
            }
          }

          &.noFill {
            background-image: none !important;
          }

          img {
            height: auto;
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }



  .content {

    &.loading {
      .matchingGrid {
        opacity: .3;
      }

      .matchingContent {
        opacity: .3;
      }
    }
  }

  .matchingHolder {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    background: $light;
    padding: 50px 25px;
  }

  .matchingContent {
    width: 43%;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    background: $light;
    color: text-contrast($light);
    transition: width .35s;
    border-radius: 10px;
    border: 1px solid $secondary;
    position: relative;
    &:before{
      @include pseudoContent;
      height: calc(100% + 16px);
      width: calc(100% + 16px);
      position: absolute;
      top: -8px;
      left: -8px;
      border: 1px solid $secondary;
      border-radius: 12px;
      //z-index: -1;
    }

    .matchingAnswersList {
      @include zeroMargPad;
    }

    .matchAnswer {
      font-weight: 700;
      font-size: 1em;
      padding: 10px;
      display: none;
      margin-bottom: 5px;
      transform: scale(0);
      border: 1px solid rgba($secondary,.5);
      border-radius: 8px;
      max-width: 600px;

      &.show {
        transform: scale(1);
        display: block;
      }

      &.current {
        @include transparentBG($light, .15);
        animation: growReveal .8s ease-out;
        transform: scale(1);
      }
      h4{
        color: $primary;
      }

      .text {
        font-weight: 400;
        font-style: italic;
        font-size: .85em;
        line-height: 1.35em;
        margin-bottom: 0;

        p {
          margin-bottom: 7px;
        }

        a {
          color: $light;
        }

        ol > li:before {
          color: $light;
        }

        ul > li:before {
          color: $light;
        }
      }
    }
  }

  .matchingGrid {
    width: 55%;
    position: relative;
    transition: opacity .25s, width .35s;

    &.loading {
      opacity: 0;
    }
  }

  .deck {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   // border: 1px solid $primary;
    //padding: 5px 15px;
    transition: opacity .25s, transform .5s;
    width: 100%;

    &.loading {
      opacity: 0;
      transform: scale(0);
    }
  }

  .card {
    @include useGPU;
    flex: 1 1 25%;
    padding: 5px;
    transition: transform 0.2s;
    cursor: pointer;
    @include transition();
    @supports  (aspect-ratio: 6 / 5) {
      aspect-ratio: 6 / 5;
    }
    @supports not (aspect-ratio: 1 / 1) {
      aspect-ratio: auto;
      &:after{
          content: "";
          display: block;
          padding-bottom: 100%;
      }
    }
    .cardTitle {
      position: absolute;
      display: none;
    }

    @include hover {
      padding: 2px;

      .front {
        box-shadow: 2px 2px 10px 12px rgba(0, 0, 0, 0.2) inset;
      }
    }

    &.fillSpace {
      .back {
        overflow: hidden;
        @include backgroundCover;
      }

      &.correct {
        .cardImage {
          width: 105%;
        }
      }

      .cardImage {
        display: none;
      }
    }

    &.noFill {
      .back {
        background-image: none !important;
      }
    }

    &.flipped {
      .flipper {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);

        .front {
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }

        .back {
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
        }
      }
    }

    &.wrong {
      transform: scale(.9);

      .back {
        background-color: $wrong;
      }

      .cardImage {
        opacity: .7;
      }
    }

    &.correct {
      .back {
        background-color: $correct;
      }

      .cardImage {
        width: 60%;
      }
    }

    &.clicked {
      .front {
        background-color: lighten($primary, 12%)
      }
    }

    .flipper {
      transition: transform 0.35s, background-color .2s;
      transform-style: preserve-3d;
      position: relative;
      @include fillSpace;
    }

    .tile {
      @include fillSpace();
      @include absolute;
      backface-visibility: hidden;
    }

    .front {
      z-index: 2;
      transform: rotateY(0deg);
      border-radius: 5px;
      background-repeat: repeat;
      background-color: $primary;
      color: $light;
      padding: 10px;
    }

    .tempText {
      /* You can change this to display block and it will show you which cards to match */
      background: $secondary;
      text-align: center;
      opacity: .6;
      font-size: .85em;
      font-weight: bold;
      position: absolute;
      bottom: 5px;
      left: 5px;
      line-height: 1em;
      height: 20px;
      width: 20px;
      text-transform: uppercase;
      padding-top: 4px;
      display: none;
    }

    .back {
      transform: rotateY(180deg);
      background-color: $grey;
      border-radius: 5px;
    }

    .cardImage {
      @include transition();
      width: 55%;
      @include vertCenter;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  .correctContentLB {
    @include fillSpace;
    @include absolute;
    @include transparentBG($light, .75);
    display: none;

    .lbHolder {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      @include vertCenter;
      background: $primary;
      color: $light;
      font-family: $secondaryFont;
      font-weight: 700;
      padding: 15px;

      p {
        @include zeroMargPad;
      }
    }
  }
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .matchingSlide .card {
    &.flipped {
      .flipper {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);

        .front {
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
        }

        .back {
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
      }
    }
  }
}

@media (max-width: $xl) {
  .matchingSlide {
    .returnContent {
      padding-left: 30px;
    }

    &.activityComplete {
    }
  }
}

@media (max-width: $lg) {
  .matchingSlide {
    &.activityCompleteReturning {
      .matchingGrid {
        .returnContent {
          font-size: 1.2em;
        }
      }
    }

    .content {
    //  padding: 20px !important;
    }

    .matchingContent {
      padding: 20px 15px;
    }

    .intInstructions {

      &.inactive {
        h3 {
          font-size: 1.1em;
        }
      }

      h3 {
        font-size: 1.1em;
        padding: 5px 10px;
        left: -10px;
      }

      .intIcon {
        height: 60px;
        width: 60px;
        display: none;
      }
    }
  }
}

@media (max-width: $md), (max-height: $xsH) {
  .matchingSlide {
    //padding-right: 30px;

    .matchingGrid {
      .rspTipHolder {
        .rspTip {
          .inner {
            a {
              color: text-contrast($primary);
            }

            ol {
              list-style-type: decimal;
              padding-left: 40px !important;

              li {
                padding: 0;
                counter-increment: none;

                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .matchingContent {
      &.activityComplete {
        .matchingGrid {
        }

        .matchingContent {
        }
      }

      .matchAnswer {
        padding: 4px 5px 5px 30px;
        line-height: 1.45em;
        font-size: .9em;

        &:before {
          left: 3px;
        }
      }
    }

    .introCopyLB{
      position: fixed;
      top: 0px;
      background: $primary;
      padding-top: 50px;
      padding-bottom: 50px;
      overflow-y: auto;
      border-radius:10px;
      @include scrollbar($color: $secondary, $width: 25px, $bgColor: $grey);
    }

    .rspTipHolder{

    }
  }
}


@media (max-width: $sm + 50px) {
  .matchingSlide {
    padding-right: 0px;

    &.activityComplete {
      .matchingHolder {
        flex-direction: column-reverse;
      }

      .matchingGrid {
        width: 100%;
      }

      .matchingContent {
        width: 100%;
      }
    }

    &.activityCompleteReturning {
      padding-right: 30px;

      .matchingHolder {
        flex-direction: column;
      }

      .matchingGrid {
        width: 100%;
      }

      .matchingContent {
        width: 100%;
      }
    }

    .intInstructions {
      .intIcon {
        height: 50px;
        width: 50px;
        display: none;
      }
    }

    .matchingHolder {
      flex-direction: column;
      max-width: 700px;
      margin: 0 auto;
    }

    .matchingGrid {
      width: 100%;
      margin-bottom: 30px;
    }

    .matchingContent {
      width: 100%;
    }

  

    // .rspTipHolder {
    //   @include absolute;
    //   @include fillSpace;
    //   z-index: 5;
    //   @include transparentBG($light, .6);
    //   display: none;

    //   .closeBtn {
    //     text-align: center;
    //     max-width: 150px;
    //     background: $secondary;
    //     color: $light;
    //     margin: 0 auto;
    //     padding: 5px;
    //     text-transform: uppercase;
    //     font-weight: 700;
    //     font-size: .9em;
    //     margin-top: 15px;
    //   }

    //   &.showTip {
    //     .rspTip {
    //       animation: growReveal .8s ease-out;
    //       transform: scale(1);

    //       p {
    //       }
    //     }
    //   }

    //   .rspTip {
    //     max-width: 450px;
    //     width: 95%;
    //     margin: 20% auto 0;
    //     position: relative;
    //     transform: scale(0);
    //     background: $primary;
    //     padding: 7px 10px 10px;
    //     font-weight: 600;
    //     color: $light;
    //     font-size: 1.1em;
    //     @include transition($speed: .35s);
    //     @include boxShadow();

    //     h4 {
    //       font-size: 1.1em;
    //     }

    //     p {
    //       margin-bottom: 0;
    //       line-height: 1.35em;
    //       text-align: left;
    //       font-weight: 400;
    //       font-size: .9em;
    //     }
    //   }
    // }
  }
}

@media (max-width: $xs) {
  .matchingSlide {
    &.activityCompleteReturning {
      padding-right: 15px;

      .matchingHolder {
      }

      .matchingGrid {
        width: 100%;

        .returnContent {
          padding-left: 0;
          font-size: 1em;
          line-height: 1.4em;
          margin-top: 0;
        }
      }

      .matchingContent {
        padding: 15px 10px;

        .matchingAnswers {
          .matchingAnswersList {
            display: block;

            .matchAnswer {
              width: 100% !important;
            }
          }
        }
      }
    }

    .intInstructions {
      h3 {
        padding-left: 10px;
        font-size: .9em;
        line-height: 1.45em;
      }

      .intIcon {
        display: none;
      }
    }

    .matchingGrid {
      max-width: 575px;
      margin: 0 auto 30px;
    }

    .deck {
      padding: 0px;
    }

    .card {
      margin: 0px 0;
    }
  }

  
}

@media (max-width: $xs), (max-height: $xsH){
  .tipMode{

  }
  .matchingSlide{
    .rspTipHolder{
      background:rgba($primary,.8);
      position: fixed;
      top: 0px;
      left: 0px;
      padding: 15px 15px 0px;
      .rspTipWrap{
        border: 2px solid $light;
      }
    }
  }
 
}

@media (max-width: $xxs), (max-height: $xsH)  {
  .matchingSlide{
    .introCopyLB{
      padding-bottom: 0px;
      padding-top: 50px;
    }

    .rspTipHolder{

      padding: 15px 15px 15px;
      
    }
  }
 
}

@media (max-width: $tiny) {
  .matchingSlide.activityCompleteReturning .matchingContent .matchingAnswers .matchingAnswersList .matchAnswer {
    flex-direction: column;
    text-align: center;

    .matchText {
      width: 100%;
    }
  }
}
