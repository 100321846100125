#helpBG {
  display: none;
  @include transparentBG($basic, .7);
  @include fixed;
  @include fillSpace;
  z-index: 400;
}

#helpContent {
  z-index: 401;
  display: none;
  position: fixed;
  left: 0;
  top: 0px;
  @include fillSpace;
  max-height: 100vh;
  overflow: hidden;
  // overflow-x: hidden !important;
  // overflow-y: scroll !important;

  .helpArrowNav {
    display: block;
  }
}

#helpCarousel {
  width: calc(100% - 75px);
  max-width:960px;
  margin: 0 auto;
  position: absolute;
  padding: 0 0 95px;
  background: $light;
  height: calc(100vh - 100px);
  color: $basic;
   top: -400px;

  @include transition($speed: 1s);
  border-radius: 7px;
 @include horzCenter;
  position: absolute;

  &.showing {
    top: 50px;
  }

  .carousel-inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    height: calc(100% - 60px);
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    @include scrollbar($color: $secondary, $width: 25px, $bgColor: $grey);
  }

  .item {
    width: 100%;
    margin: 0 auto;
    padding: 0 100px 45px;
    margin-top: 30px;

    .icon {
      vertical-align: middle;
      margin-right: 5px;
      speak: never;
      display: inline-block;
      transform: translateY(-2px);

    }
  }

  .titleWrap {
    background: $primary;
    padding: 15px 0;
    text-align: center;
    margin: 2px;
     border-radius: 7px;
    border-bottom: 3px solid $alt;
  }

  h1 {
    text-transform: uppercase;
    color: text-contrast($primary);
    font-family: $secondaryFont;
    font-weight: 700;
    font-size: 1.3em;
  }

  h3 {
    @include headerFont;
    font-weight: 700;
    font-size: 1.1em;
    margin: 15px 0 5px 0;
  }

  .helpSlideTitle {
    font-weight: 700;
    font-size: 1.8em;
    color: $primary;
    margin: 15px 0 5px 0;
  }

  .copy {
    font-family: $basicFont;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.45em;
    color: $dark;

    ul {
      li {
        padding-bottom: 8px;
      }
    }

    p {
      @include zeroMargPad;
      padding-bottom: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
      @include boxShadow();
    }

    h2 {
      font-size: 1.8em;
    }
  }

  .helpArrowNav {
    @include horzCenter;
    position: fixed;
    width: 100%;
    top: 40%;
    max-width: 100%;
    height: 0;
    border: 0;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      top: auto;
      position: absolute;
      width: 60px;
      height: 60px;
      text-decoration: none !important;
      padding: 0 10px 0;
      border-radius: 50%;
      border: 2px solid $light;

      span {
        color: $light;
        font-size: 2em;
      
      }

      @include hover {
        background: $secondaryHover;
        span{
          color:$alt2;
        }
        
      }

      &.left {
        left: -30px;
      }

      &.right {
        right: -30px;
      }
    }
  }


  .carousel-indicators {
    bottom: -15px;

    li {
      border: 2px solid $dark;

      &.active {
        background: $dark;
      }
    }
  }


  .helpBtnWrap {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;

    .lbClosebtn {
      @include button;
      font-size: 1.2em;

      &.closeBtn {
        max-width: 200px;
        margin: auto;
        right: 0;
        left: 0;
      }
    }

    &.disabled {
      .lbClosebtn {
        background: $grey;
        color: lighten($dark, 60%);
        border: 0;

        @include hover {
          cursor: not-allowed;
        }
      }
    }

    @include hover {
      cursor: pointer;
    }
  }
}

.inSection {
  .helpBtn {
    display: none;
  }
}

.swipeTutorial {
  display: none;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  @keyframes swipeTutorial {
    from {
      right: 0%;
    }

    to {
      right: 40%;
    }
  }

  img {
    position: fixed;
    bottom: 20px;
    max-width: 260px;
    height: auto;
    animation: swipeTutorial 1200ms;
    animation-delay: 500ms;
    animation-fill-mode: both;
  }

  p {
    position: fixed;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    width: 90%;
    display: block;
    padding: 0 40px;
    font-size: 2em;
    text-align: center;
    color: $light;
  }
}

@media (max-width: $lg) {
  .swipeTutorial img {
    max-width: 200px;
  }
}

@media (max-width: $md) {
  #helpContent {
    top: 0;
  }
}

@media (max-width: $sm) {
  .swipeTutorial img {
    max-width: 150px;
  }

  #helpContent {
    background: $light;
  }

  #helpCarousel {
    margin-bottom: 100px;
    padding-bottom: 155px;
    height: calc(100% - 25px);
    width: 100%;

    .carousel-indicators{
      position: fixed;
      bottom: 60px;
    }

    &.showing {
      top: 0;
    }
    .helpBtnWrap{
      bottom: 100px;
      width: 100%;
      left: auto;
      right: 0px;
      .lbCloseBtn{
        width: 100% !important;
        height: 45px !important;
        display: block;
      }
      &.disabled{
        
      }
    }

    .helpArrowNav {
      position: fixed;
      top: auto;
      bottom: 26px;
      width:100% !important;
      height: 45px !important;
      z-index: 200;
      transform: none;
      left: 0px;
      background: $primary;
      .arrow {
        width: 50%;
        height: 45px;
        padding-top: 0;
        border-bottom: 0;
        border-radius: 0px;
        border: 0px;

        &.right {
          right: 0;
          border-left: 1px solid $light;
        }

        &.left {
          left: 0;
          border-right: 1px solid $light;
        }

        span {
          font-size: 1.8em;

          &:before {
            padding-top: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .swipeTutorial {
    img {
      max-width: 120px;
    }

    p {
      top: 20%;
    }
  }

  #helpCarousel { 
     //height: calc(100vh - 60px);
    &:before {
      display: none;
    }

    .helpSlideTitle {
      font-size: 1.8em;
    }

    

    .copy {

      li {
        margin-bottom: 7px;
      }

      ul {
        margin: 0;
      }

      p {
        @include zeroMargPad;
        padding-bottom: 10px;
      }

      img {
        max-width: 100%;
        height: auto;
        @include boxShadow();
      }

      h2 {
        font-size: 1.8em;
      }
    }

    .helpBtnWrap {
     // bottom: 25px;

      .lbClosebtn {
        font-size: 1.5em;
      }
    }

    .item {
      width: 100%;
      padding: 0 20px 45px;
    }

    .helpSlideTitle {
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}

@media (max-width: $xxs) {
  .swipeTutorial {
    img {
      max-width: 100px;
    }

    p {
      font-size: 1.8em;
      padding: 0 20px;
      top: 10%;
    }
  }

  #helpCarousel {
  //  padding: 0 0 80px;
    font-size: .9em;

    h1 {
      max-height: 100%;
    }
  }
}
