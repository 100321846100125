
.progress {
    // font-size: .8em;
    // display: inline-block;
}
.currentSectionBannerHolder{
    position: fixed;
    top: -150px;
    left: 100px;
    margin-bottom: 35px;
    transition: top .5s;
    z-index: 20;
    @media (min-width: $xl + 1){
        left: 125px;
      }

      @media (max-width: $tween){
        left: 45px;
      }
    .inSection &{
        top: 35px;
    }
    &:before{
      @include pseudoContent;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 18px 25px;
      border-color: transparent transparent $primary transparent;
      position: absolute;
      top: -18px;
      left: 0px;
    }
    [data-content=fwContentW] &{
      &:before{
        border-color: transparent transparent $light transparent;
      }
    }
}

.currentSectionBanner{
    background: $primary;
      position: relative;
      height: 40px;
      min-width: 300px;
      clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 50%, 100% 100%, 0 100%);
      padding: 7px 30px 5px 15px;
      [data-content=fwContentW] &{
        background: $light;
      }
      .innerBanner{
        position: absolute;
        background: $light;
        background-image: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(0,47,135,1) 50%); 
        background-size: 200%;
        background-position: 0px;
        top: 3px;
        left: 3px;
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        clip-path: polygon(0 0, calc(100% - 3px) 0, calc(100% - 17px) 50%, calc(100% - 3px) 100%, 0 100%);
        [data-content=fwContentW] &{
          background-image: linear-gradient(90deg, rgba($primary,1) 50%, rgba($light,1) 50%); 
        }
      }
      .bannerContent{
        position: relative;
        z-index: 2;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        font-size: 1.2em;
        font-weight: 600;
        color: $primary;
        display: flex;
        gap: 5px;
        [data-content=fwContentW] &{
          color: $light;
        }

        .progress{
            display: flex;
            justify-content: center; 
            align-items: center;
            // font-weight: 800;
            //  font-size: 1.025em;
        }
      }
}

@media (max-width: $sm){
  .currentSectionBanner .bannerContent{
    font-size: 1em;
  }
}

@media (max-width: $xs){
  .currentSectionBannerHolder{
    left: 0px;
    width: 100%;
    background: $primary;
    z-index: 50;
    .inSection &{
      top: 0px;
    }
  }
  .currentSectionBanner{
    width: 100%;
  }
}



