.errorPage {
    .basicButton {
        @include button($color:$primary);
        padding: 15px;
        margin: 0 0 20px 0;
        @include rounded($radius:6px);
    }

    .errorWrapper {
        @include transparentBG($color:$light,$opacity:0.80);
        padding: 35px;
    }

    .message {
        padding: 20px 0 0 30px !important;

        @media all and (max-width: $xs) {
            text-align: center;
        }
    }

    .formArea {
        background: $primary;
        max-width: 95%;
        margin: 0 auto;
    }

    h2 {
        font-size: 1.5em !important;
        font-family: $primaryFont;
        background: $primary;
        color: $light;
        padding-bottom: 5px;
    }

    h3 {
        color: $light;
    }
}
