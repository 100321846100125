//******GLOSSARY*********//

#glossary {
    .alertInnerContent {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0px 75px 50px;
    }
  
    dl {
        @include zeroMargPad;
    }
  
    .toggleBtn {
        position: relative;
        padding: 10px 15px 10px 55px;
        display: inline-block;
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: 700;
        font-weight: bold;
        font-family: $primaryFont;
        color: $primary;
        background: $light;
        cursor: pointer;
        @media (max-width: $md){
            //margin-top: 50px;
        }
  
        .toggle {
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            height: 26px;
            width: 45px;
            border-radius: 90px;
            background: $primary;
            border: 2px solid $primary;
  
            .inner {
                position: absolute;
                top: 0px;
                right: calc(100% - 22px);
                height: 22px;
                width: 22px;
                border-radius: 50%;
                background: $light;
                transition: right 200ms;
            }
        }
        @include hover{
          background: $secondary;
          color: $primary;
           .toggle{
               .inner{
                   background: $secondary;
               }
           }
        // border-color: $light;
        // color: $primary;
        }
    }
  
    .toggleBtn.toggleOpen {
        // background: $light;
        // border-color: $light;
        // color: $primary;
  
        .toggle {
            .inner {
                right: 0px;
                //background: $primary;
            }
        }
    }
  
    .glossaryList {
        @include reformatList;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-right: 0;
        padding-top: 30px;
    }
  
    .termWrap {
        min-width: 270px;
        margin-bottom: 15px;
        padding: 0 5px;
          width: 33%;
          max-width: 400px;
        
        dl{
            
           
        }
    }
  
    .termVar {
        display: none;
    }
  
    .glosDef {
        margin: 0;
        padding: 10px 10px 20px;
        display: none;
      font-size: 1.1em;
      color: $basic;
      background: $light;
      box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 15%);
      border-bottom: 3px solid $secondary;
    }
  
    .glosTerm {
        position: relative;
        font-size: 1.4em;
        font-weight: 700;
        font-weight: bold;
        text-rendering: optimizeLegibility;
        color: $light;
        font-family: $primaryFont;
        cursor: pointer;
        background: $alt;
        border: 3px solid $alt;
        border-width: 3px 0px 3px 0px;
        @media (max-width: $xxs){
            font-size: 1.1em;
        }
  
        .termPrim {
            position: relative;
            display: block;
            padding: 15px 10px 15px 35px;
            @media (max-width: $xxs){
              padding: 10px 10px 10px 35px;
              }
            &:before {
                @include iconFont;
                content: 'Q';
                font-size: 1.1em;
                position: absolute;
                top: 50%;
                left: 8px;
                z-index: 10;
                transform: translateY(-50%);
                text-align: center;
                transform-origin: center center;
        
            }
        }
    }
  
    .glosTerm {
        @include hover {
            @include bgWithContrast($secondary);
            border-color: $secondary;
        }
    }
  
    .glosTerm.open {
        background: transparent;
        border-color:transparent;
        border-bottom-color: $secondary;
        color: $primary;
        background: $secondary;
        color: $alt2;
      
  
        .termPrim {
          &:before {
            content: 'W';
             // background: $secondary;
            //  border-radius: 3px;
           //   height: 25px;
             // width: 25px;
              //left: -12px;
            // padding-top: 3px;
          }
        }
  
        
    }
  }
  
  @media (max-width: $xl - 150px) {
    #glossary {
        .alertInnerContent{
            padding-right: 15px;
        }
        .termWrap{
            margin-bottom: 15px;
            padding: 0px 7px;
        }
    }
  }
  
  @media (max-width: $lg) {
    #glossary {
      .alertInnerContent{
         // padding-right: 25px;
      }
       .termWrap{
           width: 100%;
       }
    }
  }
  
  @media (max-width: $sm) {
    #glossary {
        
    }
  }
  
  @media (max-width: $xs) {
    #glossary {
  
       
    }
  }
  
  
  @media (max-width: $xxs){
      #glossary .termWrap{
          margin-bottom: 7px;
      }
     
  }