#inner-content {
  @include clearfix;
  margin: 0;
  position: relative;
  display: none;
  height: 100vh;
  width: 100%;
  padding-top: $top;
  

  .slideHolder {
    @include absolute;
    @include clearfix;
    top: 0;
    width: 100%;
    z-index: 1;
    overflow: visible;
    left: auto;
    display: none;
    padding: 0 !important;

    .slideTitle {
      font-family: $primaryFont;
      font-weight: 700;
     color: $primary;
      font-size:2.9em;
      line-height: 1.15em;
      padding-bottom: 45px;
      position: relative;
      margin-bottom: 20px;
      &.rsp{
        span{
          background: $light;
          padding-bottom: 5px;
          padding-right: 25px;
        }
      }
      @media (max-width: $tween){
        font-size: 2.6em;
        padding-bottom: 30px;
        line-height: 1.1em;
      }

      @media (max-width: $xs){
        font-size: 2.3em;
        padding-right: 10px;
      }
      @media (max-width: $xxs){
        font-size: 1.6em;
      }
      [data-content=fwContentW] &{
        color: $light;
        text-align: center;
        width: 100%;
        padding-bottom: 35px;
        &:after{
          @include horzCenter;
          position: absolute;
        }
      }
      
      &:after{
        @include iconFont;
        content: 'Z';
        color: $secondary;
        font-size: .3em;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
    }
    &.movingIn{
      .slideCopy{
        opacity: 0;
        transform: translateY(-110%);
      }
      .tips{
        transform: translateX(100%);
        opacity: 0;
      }
    }
    &.current{
      .slideCopy{
        transform: translateY(0);
        opacity: 1;

      }
      .tips{
        transform: translateX(0%);
      }
    }
    &.movingOut{
      .slideCopy{
        transform: translateY(110%);
        opacity: 1;
      }
      .tips{
        transform: translateX(100%);
        opacity: 0;
      }
    }

    &.movingOut,&.movingIn{
      .slide{
        overflow: hidden;
      }
    }
  }

}

@keyframes arrowRevealL {
  0% {
    clip-path: polygon(0 0, 9% 0, 9% 0, 0 0);
  }

  60% {
    clip-path: polygon(0 0, 9% 0, 9% calc(100% + 25px), 0 calc(100% + 25px));
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% + 25px), 0 calc(100% + 25px));

  }
}


@keyframes arrowRevealR {
 
  
  0% {
    clip-path: polygon(0 0, 0 0, 0% 9%, 0 9%);
  }

 40% {
    clip-path: polygon(100% 0, 0 0, 0% 9%, 100% 9%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

.sectionArrowHolderL{
  inset: 0px auto auto 0px;
  width: 100px;
  height:170px;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 20;
  clip-path: polygon(0 0, 9% 0, 9% 0, 0 0);
  @media (max-width: $tween){
    width: 50px;
    height:145px;
  }

  @media (max-width: $sm){
    height:150px;
  }

  @media (max-width: $xs){
    height:0px;
  }


  .sectionArrow{
    height: 100%;
    border: 3px solid $secondary;
    border-radius: 0px 0px 0px 15px;
    border-width: 0px 0px 5px 5px;
    transition: width .25s .2s, height .25s;
    width:55px;   
    &:after{
      @include iconFont;
      content: 'R';
      bottom: -13px;
      position: absolute;
      right: 16px;
      color: $secondary;
      font-size: 2.3em; 
    }
    @media (max-width: $tween){
      width: 25px;
      &:after{
        right: 5px;
      }
    }
  }
  .inSection &{
    animation: arrowRevealL 2s;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% + 25px), 0 calc(100% + 25px));
  }
}

.sectionArrowHolderR{
  inset: auto 25px 0px auto;
  width: 75px;
  height:calc(50vh);
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 20;
  clip-path: polygon(0 0, 0% 0, 0% 0, 0 0);
  padding-top: 25px;
  transition: width .3s , left .3s;

  @media (max-width: $mid){
    display: none;
  }

  .sectionArrow{
    height: 100%;
    border: 3px solid $secondary;
    border-radius: 0px 15px 0px 0px;
    border-width: 5px 5px 0px 0px;
    transition: width .3s;
    width:40px;   
    &:after{
      @include iconFont;
      content: 'R';
      top: 11px;
      position: absolute;
      left: 0px;
      color: $secondary;
      font-size: 2.3em; 
      transition: left .2s;
    }
  }
  .inSection &{
    animation: arrowRevealR 2s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  [data-content='centerContentW'] &{
    inset: auto 35px 0px auto;
    width: 170px;
    .sectionArrow{
      width: 140px;
      &:after{
        left: 0px;
      }
    }

    @media (max-width: $lg){
      width: 130px;
      .sectionArrow{
        width: 100px;
      }
    }
  }

  [data-content='centerCopyContentW'] &{
    inset: auto 35px 0px auto;
    width: 210px;
    .sectionArrow{
      width: 180px;
      &:after{
        left: 0px;
      }
    }
    @media (max-width: $lg){
      width: 190px;
      .sectionArrow{
        width: 160px;
      }
    }
  }

}


.slide {
  margin: 0 auto;
  width: 100%;
  max-height: calc(100vh - #{$top}) !important;
  margin-top: $top;
  padding-right: 50px;
  @media (min-width: $xs + 1){
    scrollbar-gutter: stable;
   
  }
  @media (max-width: $mid){
    padding-right: 30px;
  }
  @media (max-width: $sm){
    max-height: calc(100vh - 100px) !important;
    margin-top: 100px;
  }

  @media (max-width: $xs){
    padding-right: 20px;
    max-height: calc(100vh - 120px)  !important;
    margin-top: 40px;
  }

  
  .current &{
      @include scrollbar($color: $alt, $width: 25px, $bgColor: $grey);
      overflow-y: auto;
   }

 

  .content {
    font-size: 1.15em;
    line-height: 1.7em;
    color: $basic;
    min-height: calc(100% - #{$top});
    padding: 15px 50px 50px 100px;

    @media (min-width: $xl + 1){
      padding: 15px 50px 50px 125px;
    }

    @media (max-width: $mid){
      padding:0px 0px 50px 100px;
    }
    @media (max-width: $tween){
      padding:0px 0px 50px 60px;
    }

    @media (max-width: $xs){
      padding-left: 25px;
    }
  }

  .slideSide {
    img {
      @include rspImage;
      border-radius: 15px;
      &.transparent {
        background: transparent !important;
      }
    }
  }


  .imgRight,
  .imgRight img {
    float: right;
    margin: 0 0 30px 30px !important;
    padding: 10px;
    @include rspImage;
    @include rounded(6px);
    @include transparentBG($color: $light, $opacity:0.75);

    @media all and (max-width: $xs) {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
  }

  .imgLeft,
  .imgLeft img {
    float: right;
    margin: 0 30px 30px 0 !important;
    @include rspImage;

    @media all and (max-width: $xs) {
      float: none !important;
      margin: 0 auto 10px auto !important;
    }
  }

  .imgWrap {
    width: 100%;
    margin: 0 auto;
    @include transition(.5s);
    position: relative;
    text-align: center;
    .imgHolder{
    //  clip-path: polygon(0 100%, 0 0, 0 0, 0 100%);
      transition: clip-path .5s .15s;
    }

    .current & {
      .imgHolder{
        //clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
      }
      img {

       // box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
       // transform: translateY(8px);
      }
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 80vh;
      display: inline-block;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: box-shadow .5s, transform .5s;
    }

    .imgHolder {
      @include absolute;
      width: 100%;
      display: none;

      &:first-of-type {
        display: block;
      }

      &:only-child {
        position: static;
      }

      .caption {
        position: absolute;
        bottom: 10px;
        padding: 10px;
        @include transparentBG($primary, .65);
        color: text-contrast($primary);
        display: block;
        width: calc(100% - 20px);
        left: 0;
        right: 0;
        margin: auto;
        max-height: 100px;
        overflow: hidden;
        overflow-y: auto;
        @include scrollbar();
        font-size: 1em;

        p {
          font-size: 1em;
          margin-bottom: 0;
        }
      }
    }

    h2 {
      font-size: 1.25em;
      margin-top: 10px;
    }
  }

  a {
    font-weight: normal;
    color: $link;
    text-decoration: underline;
    display: inline-block;

    @include hover {
      text-decoration: none;
    }

    &[href*='.pdf'] {
      &:before {
        @include iconFont;
        content: "p";
        font-size: .7em;
        padding-right: 1px;
      }
    }
  }

  .imgWrapper {
    padding: 0 5px 0 0;

    @media all and (max-width: $md) {
      padding-right: 0;
      margin: 5px 0 0 0;
    }

    img {
      @include transparentBG($color: $light, $opacity:0.75);
      padding: 20px;
      margin-bottom: 5px;
    }
  }

  .row.textWrapper {
    margin-top: 53px !important;
  }

  .videoCopy,
  .textCopy,
  .videoRTE,
  .tips {
    @include clearfix;


    ul {
      @include zeroMargPad;
      @include reformatList;
      padding-left: 20px;
      margin-bottom: 15px;
      margin-top: 0 !important;

      li {
        position: relative;
        @include zeroMargPad;
        padding-left: 20px;
        margin-bottom: 5px;
        font-weight: 600;
        color: lighten($basic, 15%);
        font-size: .95em;

        &:before {
          @include iconFont;
          content: 't';
          position: absolute;
          left: 0;
          top: 9px;
          color: $primary;
          font-size: .5em;
        }
      }
    }

    img {
      @include imgResponsive;
    }
  }
}

/* PRINTER FRIENDLY VIEW STYLING */
@media print {
  a[href]:after {
    display: block;
    clear: both;
  }

  .closeBtn {
    display: none !important;
    content: none !important;
  }
}

@media (max-width: $lg) {
  #inner-content {
    // .slideHolder {
    //   .slideTitle {
    //     font-size: 1.9em;
    //     padding: 0 325px 0 30px;
    //   }
    // }
  }

  .slide {
    // .content {
    //   padding: 25px 20px !important;
    // }

    // .slideCopy {
    //   padding-right: 15px;
    // }

    // .slideSide {
    //   padding-left: 15px !important;
    // }
  }
}

@media all and (max-width: $md) {
  #inner-content {
    // .slideHolder {
    //   .slideTitle {
    //     font-size: 1.6em;
    //     padding: 10px 20px 10px 20px;
    //     height: auto;

    //     span {
    //       @include vertCenter(0%, 0%);
    //     }
    //   }
    // }
  }

  .slide {

    // .content {
    //   padding: 25px 25px 150px !important;
    //   max-width: 850px;
    //   width: 100%;
    //   margin: 0 auto;
    // }

    // .slideCopy {
    //   padding-right: 0;
    //   float: none !important;
    //   width: 100% !important;
    // }

    // .slideSide {
    //   padding-left: 0 !important;
    //   float: none !important;
    //   width: 100% !important;
    //   margin-top: 20px;
    //   clear: both;
    // }
  }
}

@media all and (max-width: $tween) {
  .slide {
  //  margin-top: 80px;
  }
}

@media all and (max-width: $xs) {
  .slide {
    // .content {
    //   font-size: 1.1em;
    //   padding-top: 10px !important;
    //   padding-bottom: 250px !important;
    // }
  }


}

@media (max-width: $xxs){
  #inner-content{
    .slideHolder{
      .slideTitle{
       // font-size: 2em;
      }
    }
  }
}
