//***==LoadBar KEYFRAMES==***//
@keyframes loading {
  0% {
    left: -10%;
  }

  100% {
    left: 90%;
  }
}

@keyframes loadingBar {
  0% {
    width: 0
  }

  20% {
    width: 35%
  }

  40% {
    width: 38%
  }

  50% {
    width: 40%
  }

  100% {
    width: 100%
  }
}

.loadingScreenHolder {
  @include fixed;
  height: 100vh;
  width: 100vw;
  background: $primary;
  overflow: hidden;
  transition: clip-path .6s .45s;
  z-index: 9999;
  clip-path: circle(100.0% at 50% 50%);
  display: none;
  &:before{
    @include pseudoContent;
    @include fixed;
    @include fillSpace;
  
    background: url('../images/Login_Background.svg') no-repeat;
    @include backgroundCover;
    opacity: .1;
  }
  

  &.in {
   
    .innerWrap {
      opacity: 1;
    }

    .loadLogoWrap {
      transform: translateY(0);
    }
  }

  .loading & {
    display: block;
  }

  &.out {
    clip-path: circle(0.0% at 50% 50%);
  }

  .innerWrap {
    max-width: 750px;
    width: 100%;
    @include vertHorzCenter;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;
    opacity: 0;
    transition: opacity .35s;
  }

  .loadLogoWrap {
    max-width: 300px;
    width: 100%;
    transform: translateY(-50px);
    transition: transform .5s;

    img {
      width: 100%;
    }
  }

  .loadBar {
    position: relative;
    width: 100%;
    height: 60px;
    background: $light;
    margin: 40px 0 30px;
    overflow: hidden;
    border-radius: 80px;
    border: 5px solid $light;

    .loadBarInner {
      @include absolute;
      top: 0;
      height: 100%;
      background: $secondary;
      width: 0;

      &.loading {
        width: 100%;
        animation: loadingBar 3.25s ease-in;
      }
    }
  }

  h5 {
    text-transform: uppercase;
    font-size: 3em;
    @include zeroMargPad;
    color: $light;
    text-align: center;
    font-family: $secondaryFont;
  }
}

@media (max-width: $xs) {
  .loadingScreenHolder {
    .innerWrap {
      height: calc(100vh - 100px);
    }

    h5{
      font-size: 2em;
    }

    .loadLogoWrap {
      max-width: 225px;
    }

    .loadBar {
      margin: 30px 0 20px;
    }

    &.in {
      .innerWrap {
        width: 100%;
      }
    }
  }
}
