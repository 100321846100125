.arrowNav{
  position: fixed;
  right: -350px;
  top: 0px;
  height: 100px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: $primary;
  z-index: 20;
  transition: right .5s;
  .inSection &{
    right: 50px;
    
  }
  .arrow{
    color: $secondary;
    font-size: 4em;
    padding-top: 5px;
    &.disabled{
      opacity: .3;
      cursor: not-allowed;
    }
    @include hover{
      cursor: pointer;
     .icon{
       &:before{
        content: 'm';
       }
        
      }
    }
    &.left{
      transform: scaleX(-1);
    }
  }
}
.homeHolder{
  height: 65px;
  width: 65px;
  border: 3px solid $light;
  border-radius: 50%;
  display: flex;
  justify-content: center; 
  align-items: center;
  color: $light;
  font-weight: 800;
  font-size: 1.5em;
  @include hover{
    background: $light;
    color: $primary;
    cursor: pointer;
  }
}

.timer {
  position: absolute;
  left: 25px;
  @include fillSpace;
  @include transparentBG($primary, .5);
  display: none;
  height: 2px;
  width: calc(100% - 50px);
  // border: 1px solid $secondary;
  bottom: -5px;

  .inner {
      @include absolute;
      height: 100%;
      background: $secondary;
      width: 0;
  }

  .inSection & {
      display: block;
  }
}

@media (max-width: $sm){

  .arrowNav{
    width: 200px;
    height: 80px;
    .inSection &{
      right: 25px;
    }
    
    .arrow{
      font-size: 3.3em;
    }
  }

  .homeHolder{
    height: 50px;
    width: 50px;
    font-size: 1.3em;
  }
  
}


@media (max-width: $xs){
  .arrowNav{
    width: 100%;
    background: $primary;
    right: 0px;
    top: auto;
    bottom: -250px;
    transition: bottom .35s;
    .inSection &{
      right: 0px;
      bottom: 0px;
      padding: 0px 15px;
    }
  }
  .timer{
    bottom: auto;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 5px;
  }
}