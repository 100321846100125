.navCarouselArrows{
  height: calc(100% - (150px));
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $sb;
  position: fixed;
  right: 0px;
  align-items: center;
  top: 75px;
  transition: right .5s;
  @media (max-width: $mid){
    width: $sbL;
  }
  @media (max-width: $md){
    width: $sbM;
  }
  @media (max-width: $tween){
    width: $sbS;
  }
  .setStage &, .alertMode &{
    right: - $sb;
  }
  .helpMode &, .loading &, .faqMode &{
    right: - $sb;
  }
  
  .carouselArrow{
    font-size: 4em;
    color: $light;
    cursor: pointer;
    
    transform-origin: center center;
    color: $secondary;
    &.off{
     opacity: 0;
     visibility: hidden;
    }
    &.prevArrow{
      transform: rotate(-90deg);
    }
    &.nextArrow{
      transform: rotate(90deg);
    }
    @include hover{
      &:before{
        content: 'm';
      }
    }
  }
  .indicatorsWrap{
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    .ind{
      height: 18px;
      width: 18px;
      background: transparent;
      border: 3px solid $light;
      display: block;
      border-radius: 50%;
      margin: 10px auto;
      transition: .15s;
      &.active{
        
        background: $contrast;
      }
      &.current{
        height: 30px;
        width: 30px;
      }
      &.completed{
        background: $light;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before{
          @include iconFont;
          content: 'a';
          color: $primary;
          font-size: 1em;
        }
      }
      &.completedInd{
        background: $correct;
      }
    }
  }
}

@media (max-width: $md){
  .navCarouselArrows {
    .indicatorsWrap {
      .ind{
        height: 15px;
        width: 15px;
        margin: 7px auto;
        border-width: 2px;
        &.current{
          height: 27px;
          width: 27px;
        }
      }
    }
    .carouselArrow{
      font-size: 3em;
    }
  }
}

#main-nav {
  width: calc(100vw - #{$sb});
  @include zeroMargPad;
  position: fixed;
  left: 0px;
  top: 0vh;
  @media (max-width: $mid){
    width: calc(100vw - #{$sbL});
  }
  @media (max-width: $md){
    width: calc(100vw - #{$sbM});
  }
  @media (max-width: $tween){
    width: calc(100vw - #{$sbS});
  }

  .button {  
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    display: none;
    transition: top .5s, opacity .4s;
    &.onL,&.onC,&.onR,&.offL,&.offR{display: flex;}
    &.onL{top: -100vh;}
    &.onC{top: 0;}
    &.onR{top: 100vh;}
    &.offL{top: -200vh;}
    &.offR{top:  200vh;}
    &:nth-child(1){
      .sectionArrow.top{
        display: none !important;
      }
    }
    &:last-child{
      .sectionArrow.bottom{
        display: none !important;
      }
    }
    &:nth-child(even){
      flex-direction: row-reverse;
    }
    &.active{
      .messageBanner{
        min-width: 375px;
        .msg[data-status=active]{
          display: flex;
        }
      }
      .titleHolder{
        @include hover{
          .buttonHolder{
            svg{
              transform: scale(1.2) rotate(-90deg);
            }
          }
        }
      }

      .imageSide{
        .imageCircle{
          background: $light;
        }
        .posterImage{
          display: none;
        }
        .sectionSlideshow{
          display: block;
        }
      }
      
    }
    &.completed{
      .messageBanner{
        .innerBanner{
          display: none;
        }
        .msg{
          color: $light;
          padding-left: 25px;
          &:before{
            @include iconFont;
            color: $light;
            content: 'a';
            position: absolute;
            left: 10px;
            top: 7px;
            font-size: 1.2em;
          }
          &[data-status=complete]{
            display: flex;
           }
        }
      }
      .buttonHolder{
        svg{
          transform: rotate(90deg);
        }
      }
      .titleHolder{
        @include hover{
          .buttonHolder{
            svg{
              transform: scale(1.2) rotate(0deg);
            }
          }
        }
      }

      .imageSide{
        .posterImage{
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    &.active, &.completed{
      .titleHolder{
        cursor: pointer;
        @include hover{
          .buttonHolder{
            svg{
              .cls-1{
                stroke: $accent;
              }
            }
            .startBtn{
              transform: scale(1.1);
            }
          }
        }
      }
      .buttonHolder{
        svg{
          .cls-1{
            stroke: $accent;
          }
        }
        .startBtn{
          display: flex;
        }
        .locked{
          display: none;
        }
      }
      .imageSide{
        .mainImageWrap{
          border-color: $secondary;
        }
      }
      .sectionNum{
        height: 115px;
        width: 115px;
        top: 4%;
        
        font-size: 4em;
      }
    }

    &.notAvailable{
      .messageBanner{
        .msg[data-status=active],.msg[data-status=locked], .msg[data-status=complete] {
          display: none;
        }
        .msg.justInTime{
          display: flex;
          span{
            display: inline-block;
          }
        }
      }
      
    }

    .imageSide{
      width: 55%;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      position: relative;
      transform-origin: center center;
      transition: transform .5s;
      .mainImageWrap{
        height: 80vh;
        width: 80vh;
        border: 18px solid $light;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        transition: transform .75s;
        @supports (aspect-ratio: 1/1){
          aspect-ratio: 1/1;
        }
      }
     
      .imageCircle{
        width: 100%;
        height: 100%;
        background: $primary;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
      }
      .posterImage{
        @include backgroundCover;
        @include fillSpace;
        @include absolute;
        filter: grayscale(100%);
        opacity: .3;
      }
      .sectionSlideshow{
        border-radius: 50%;
        border: 10px solid $secondary;
        height: calc(100% - 30px);
        width: calc(100% - 30px);
        @include vertHorzCenter;
        position: absolute;
        display: none;
      }
    }
    .sectionNum{
      background: $secondary;
      height: 95px;
      width: 95px;
      position: absolute;
      top: 5%;
      left: 5%;
      border-radius: 50%;
      border: 8px solid $light;
      font-family: $secondaryFont;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      font-weight: 900;
      font-style: italic;
      font-size: 3em;
      z-index: 5;
    }
    .titleHolder{
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 50px;
      transform-origin: center center;
      transition: transform .5s;
    }

    .section-title{
      font-family: $primaryFont;
      font-weight: 800;
      color: $primary;
      font-size: 4em;
      text-align: center;
      line-height: 1.1em;
      @media (min-width: $xl){
        font-size: 5em;
      }
    }
    .abbr-title {
      display: none;
    }
    .bannerHolder{
      position: relative;
      margin-bottom: 35px;
      &:before{
        @include pseudoContent;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 18px 25px;
        border-color: transparent transparent $primary transparent;
        position: absolute;
        top: -18px;
        left: 0px;
      }
    }

    .messageBanner{
      background: $primary;
      position: relative;
      height: 50px;
      min-width: 300px;
      clip-path: polygon(0 0, 100% 0, calc(100% - 18px) 50%, 100% 100%, 0 100%);
      padding: 7px 35px 5px 10px;
     
      .innerBanner{
        position: absolute;
        background: $light;
        background-image: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(0,47,135,1) 50%); 
        background-size: 200%;
        background-position: 0px;
        top: 3px;
        left: 3px;
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        clip-path: polygon(0 0, calc(100% - 3px) 0, calc(100% - 20px) 50%, calc(100% - 3px) 100%, 0 100%);
      }
      .msg{
        position: relative;
        z-index: 2;
        display: none;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        font-size: 1.3em;
        font-weight: 800;
        color: $primary;
        &[data-status=active]{
          &[data-show=start]{[data-section=start]{display: block;}}
          &[data-show=default]{[data-section=default]{display: block;}}
          &[data-show=end]{[data-section=end]{display: block;}}
        }
        &[data-status=locked]{
          display: flex;
         }
         
         span{
           display: none;
         
          //  &[data-section=default]{
          //   display:block;
          //   }
         }
      }
    }

    .buttonHolder{
      height: 125px;
      width: 125px;
      position: relative;
      margin-top: 15px;
      
      svg{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        transform-origin: center center;
        transition: transform .4s;
        
        .cls-1{
          stroke-width: 1px;
          stroke: $dark;
          transition: stroke-width .2s, fill .2s;
          fill: none;
        }
      }
      
      .startBtn,.locked{
        display: flex;
        justify-content: center;
        align-items: center;
        @include fillSpace;
        font-size: 4em;
        position: relative;
        z-index: 2;
      }
      .startBtn{
        font-family: $secondaryFont;
        font-weight: 800;
        font-style: italic;
        color: $primary;
        text-transform: uppercase;
        font-size: 2.8em;
        display: none;
        transform-origin: center center;
        transition: transform .4s;
      }
    }

  }
}

.menuOut, .helpMode, .loading, .searchMode, .faqMode, .alertMode{
  #main-nav{
    .button{
      .titleHolder{
        transform: scale(0);
      }
      .imageSide{
        //transform: scale(0);
      }
      .mainImageWrap{
        transform: translateX(-150%) rotate(-170deg); 
        transition: transform .75s;
      } 
      &:nth-child(even){
        .mainImageWrap{
          transform: translateX(150%) rotate(170deg); 
          transition: transform .75s;
        } 
      }
    }
  }


  
}


//****STYLES FOR LEFT/RIGHT/TOP/BOTTOM ARROWS****//

.button{
  .imageSide{
  }
  .titleHolder{
    padding-right: 50px;
  }
  &:nth-child(even){
    .imageSide{
    }
    .titleHolder{
      padding-left: 50px;
    }
    .sectionArrow{
      &.top{
        border-radius: 0px 15px 0px 0px;
        border-width: 5px 5px 0px 0px;
        //inset:calc((2vh + 5px) * -1) 50% auto auto ;
        inset: auto 50% calc(88vh + 5px) auto;
        &:after{
          content: 'R';
          top: -18px;
          left: -15px;
        }
      }
      &.bottom{
        inset: auto 50% 2vh auto;
        border-radius:0px 0px 15px 0px;
        border-width: 0px 5px 5px 0px;
        &:after{
          content: 'L';
          left: -5px;
        }
      }
    }
  }
 


  .sectionArrow{
    width: calc(43% - 30px);
    position: absolute;
    border: 5px solid $secondary;
    transition: opacity .5s;
    opacity: 1;
    @media (max-width: $mid){
      width: calc(43% - 20px);
    }
    @media (max-width: $md){
    //  width: calc(43% - 10px);
    }
    .helpMode &, .loading &, .searchMode &, .faqMode &{
      opacity: 0;
    }
    &:after{
      @include iconFont;
      content: 'L';
      position: absolute;
      color: $secondary;
      font-size: 2.3em; 
    }
    &.top{
      inset: calc((2vh + 5px) * -1)  auto auto  50%;
      height: 14vh;
      border-radius: 15px 0px 0px 0px;
      border-width: 5px 0px 0px 5px;
      transition: width .25s .2s, height .25s;
      @media (max-width: $mid){
        height: 14vh;
      }
      &:after{
        content: 'L';
        top: -18px;
        right: -15px;
        transition: clip-path .15s .45s;
      }
      &.out{
        height: 1vh;
        width: 5px;
        transition: width .25s .15s, height .25s .35s;
        &:after{
          transition: clip-path .15s;
          clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
        }
      }
    }
    &.bottom{
      inset: auto 10% 2vh auto;
      height: 10vh;
      border-radius:0px 0px 0px 15px;
      border-width: 0px 0px 5px 5px;
      transition: height .25s .35s, width .25s .15s;
      &:after{
        content: 'R';
        bottom: -18px;
        right: -5px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        transition: clip-path .15s;
      }
      &.out{
        height: 1vh;
        width: 0%;
        transition: height .25s, width .25s .25s;
        &:after{
          clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
          transition: clip-path .15s .4s;
        }
      }
    }

    
  }
}

#main-nav{
  .completedBtn{
   
    .messageBanner{
      .innerBanner{
        background: $primary;
      }
      .msg{
        display:flex;
        color: $light;
        span{
          display: block;
        }
      }
    }
    .buttonHolder{
      width: 100%;
      height: auto;
      text-align: center;
      margin-top: 25px;
    }
    .reviewBtn{
      @include button();
      display: inline-flex;
      border: 1px solid $alt;
      font-family: $primaryFont;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      font-size: 1.5em;
      gap: 7px;
      &:after{
        display: none;
      }
    }
    .imageSide{
      .sectionSlideshow{
        display: block;
      }

      .mainImageWrap{
        border-color: $secondary;
      }
      .imageCircle{
        background: $light;
      }
    }
  }
}
  


@media (max-width: $mid){
  #main-nav{
    .button{
      .messageBanner{
        min-width: 280px !important;
        .msg{
          font-size: 1em;
        }
      }
      .section-title{
        font-size: 3em;
      }

      .imageSide{
        .mainImageWrap{
          height: 70vh;
          width: 70vh;
        }
      }

      .buttonHolder{
        height: 100px;
        width: 100px;
        .locked{
          font-size: 3.2em;
        }
        .startBtn{
          font-size: 2.3em;
        }
      }
    }
  }
  .button .sectionArrow.bottom{
    height: 15vh;
    inset: auto 10% 2vh auto;
  }
  .button .sectionArrow.top{
    height: 18vh;
  }
  .button:nth-child(even) .sectionArrow.top{
     inset: auto 50% calc(84vh + 5px) auto;
  }

  #main-nav .button.active .sectionNum, #main-nav .button.completed .sectionNum{
    height: 105px;
    width: 105px;
    font-size: 3.5em;
  }
}

@media (max-width: $md){
  #main-nav{
    .button{
      .messageBanner{
        min-width: 270px !important;
        .msg{
          font-size: 1em;
        }
      }
      .section-title{
        font-size: 2.8em;
      }

      .imageSide{
        .mainImageWrap{
          height: 65vh;
          width: 65vh;
        }
      }

      .buttonHolder{
        height: 90px;
        width: 90px;
        .locked{
          font-size: 3em;
        }
        .startBtn{
          font-size: 2.1em;
        }
      }
    }
  }

  #main-nav .button.active .sectionNum, #main-nav .button.completed .sectionNum{
    height: 95px;
    width: 95px;
    font-size: 3em;
  }

  #main-nav .button .imageSide .sectionSlideshow{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
  .button .sectionArrow.top{
    height: 20vh;
  }

  .button:nth-child(even) .sectionArrow.top{
    inset: auto 50% calc(82vh + 5px) auto;
 }


}

@media (max-width: $tween){
  #main-nav{
    .button{
      .imageSide{
        .mainImageWrap{
          height: 350px;
          width: 350px;
          border-width: 12px;
        }
        .sectionSlideshow{
          border-width: 7px;
        }
      }
      .sectionNum{
        height: 70px;
        width: 70px;
        font-size: 2.5em;
      }
      &.active, &.completed{
        .sectionNum{
          height: 70px;
          width: 70px;
          font-size: 2.5em;
        }
      }
      
    }
  }
  .navCarouselArrows{
    width: $sbS;
    height: calc(100% - #{$rspHeader});
    top: $rspHeader;

  }
}


@media (max-width: $xs), (max-height: $xsH){
  #main-nav{
    width: calc(100vw - 20px);
    left: 10px;

    .button{
      .sectionArrow{
        display: none !important;
      }
      .imageSide{
        width: 250px;
        .mainImageWrap{
          height: 250px;
          width: 250px;
          border-width: 10px;
        }
        .sectionSlideshow{
          border-width: 5px;
        }
      }

      .titleHolder{
        width: calc(100% - 250px);
      }
    }
  }
  .navCarouselArrows{
   background: $primary;
    width: 100%;
   height: 70px;
    flex-direction: row;
    top: auto;
    bottom: 0px;
    right: auto;
    left: 0;
    background: $primary;
    transition: bottom .25s;
    .alertWidgetMode &, .setStage &{
      bottom: -250px;
    }

    .indicatorsWrap{
      flex-direction: row;
      padding: 0px 20px;
      width: auto;
      .ind{
        margin: 0px 5px;
        display: inline-block;
      }
    }
  }

}



@media (max-width: $xs){
  @media (min-height: $smH + 1){
    #main-nav{
      .button, .button:nth-child(even){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 7px;
        .imageSide{
          height: 200px;
          .mainImageWrap{
            height: 200px;
            width: 200px;
            border-width: 8px;
            .sectionNum{
              height: 55px !important;
              width: 55px !important;
              font-size: 2em;
              border-width: 5px;
            }
          }
          .sectionSlideshow{
            border-width: 3px;
          }
        }
        .titleHolder{
          width: 100%;
        }

        .section-title{
          font-size: 2.5em;
        }
      }
      
    }
  }
}



@media (max-height: $xsH){
#main-nav{
      .button{
        padding-top: 70px;
        .imageSide{
          display: none;
        }
        .titleHolder{
          width: 100%;
          flex-direction: row;
          .bannerHolder{
            position: absolute;
            top: 95px;
            left: 25px;
            width: calc(100% - 50px) !important;
            .messageBanner{
              min-width: calc(100% - 50px) !important;
            }
          }
        }
      }
    }

    .navCarouselArrows{
      height: 55px;
      justify-content: flex-end;
      .carouselArrow{
        font-size: 2.5em;
      }
    }

}

@media (max-width: $xs){
  .navCarouselArrows .indicatorsWrap .ind.completed{display: inline-flex;}
}

@media (max-width: $xxs){
  .navCarouselArrows{
    .indicatorsWrap{
      .ind{
        margin: 0px 2px;
        height: 10px;
        width: 10px;
      }
    }
  }
  
  .navCarouselArrows .indicatorsWrap .ind.current{
    height: 20px;
    width: 20px;
  }
}




