.learnMoreWrapper {
  cursor: pointer;
  padding-top: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  @include hover{
    filter: drop-shadow(0px 0px 5px rgba($basic,.3));
    .iconWrap{
      transform: scale(1.15) translateX(-50%);
      top: -2px;
    }
    .toolTip{
      color: $light;
    }
    .inner{
      background: $contrast;
    }
  }


  .iconWrap{
    width: 60px;
    height: 74px;
    @include horzCenter;
    position: absolute;
    top: 0px;
    transition: transform .2s, top .2s;
    transform-origin: bottom center;
   

    .icon{
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      font-size: 1.8em;
      color: $light;
      transition: transform .25s;
      &.checkIcon{
        transform: scale(0);
      }
    }
    svg{
      width: 100%;
      path{
        fill: $contrast;
      }
    }

  }

  .toolTip {
    @include noHighlight;
    pointer-events: none;
   z-index: 2;
   position: relative;
    color: $alt2;
    font-size: 1.1em;
    transition: color .2s;
  }
  .inner{
    border: 2px solid $contrast;
    padding: 12px 25px;
    transition: background-color .2s;
    position: relative;
    background: $light;
  }
  .txt{
    transition: transform .25s;
    font-family: $secondaryFont;
    font-weight: 700;
    font-style: italic;
  }

  input[type=checkbox] {
    display: none;
  }

  &:before {
    
  }

  

  &.selected {
    .inner{
      background: $alt;
      border-color: $alt;
    }
    .toolTip{
      color: $light;
      transform: scale(0);
    }
    .lmNote{
      transform: scale(1);
    }
    .iconWrap{
      svg{
        path{
          fill: $alt;
        }
      }
      .icon{
        &:before{
          content: 'a';
        }
      }
    }
  }
  &.trans{
    .iconWrap{
      .icon{
        transform:scale(0);
      }
    }
    .txt{
      transform:scale(0);
    }
  }

  .iconWrap {
    
  }

  .icon {
    
  }

  .toolTip {
    position: relative;
    z-index: 2;
  }

  p {
    margin-bottom: 0;
    font-weight: 600;
  }

  .lmNote {
    font-weight: 700;
    font-style: italic;
    color: $light;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include noHighlight;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    padding: 10px;
    line-height: 1.2em;
    text-align: center;
    &.show{
      display: flex !important;
    }
   // display: none;
  }
}

.learnMore {
 

  .learnMoreLinksWrap {
  display: flex;
  justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 30px;

    > .personalized:first-child {
      width: 100% !important;
      float: none !important;
    }

    h4 {
      margin-top: 10px;
    }
  }

  .linkGroup {
    font-family: $basicFont;
    padding: 0 0 0;

    &.default {
      margin: 0 auto;
      width: 450px;
      padding: 0 10px 20px;
      @include bgWithContrast($primary);
      border: 3px solid $secondary;
      border-radius: 7px;
      padding: 7px;
      .inner{
        border: 3px solid $secondary;
        border-radius: 3px;
        padding: 15px 15px;
      }
      h3{
        color: $light;
      }
      .learnMoreLinks {
        margin-top: 10px;

        li {
          a {
            font-size: 1.1em;
            text-decoration: none !important;
            color: text-contrast($primary);

            @include hover {
              color: $alt2;
              background: $secondary;
            }
          }
        }
      }
    }

    &.personalized {
      width: calc(100% - 500px);
      background: $light;
      padding: 20px 25px;
      border-radius: 7px;

      li {
        a {
          span {
            display: none;
          }
        }
      }

      .learnMoreLinks {
        margin-top: 0;

        li {
          a {
            padding: 10px 0 10px 45px;

            &:before {
              top: 15px;
            }
          }
        }
      }

      h4 {
        @include bgWithContrast($accent);
        padding: 2px 10px;
        font-family: $primaryFont;
        font-weight: 700;
        font-size: .9em;
        margin-top: 15px;
        text-transform: uppercase;
      }
    }

    h3 {
      text-align: center;
      font-family: $basicFont;
      padding: 5px 5px 0;
      display: block !important;
      color: $primary;

      &:before {
        display: none;
      }
    }

    p {
      text-align: center;
      margin-bottom: 10px;
      line-height: 1.4em;
      padding-top: 5px;
    }
  }


  .learnMoreLinks {
    list-style-type: none;
    margin: 0 auto;
    padding: 0 0;
    border-left: 0 !important;

    li {
      display: block;
      font-weight: 400;
      margin-bottom: 0;
      margin-bottom: 5px;
      padding-left: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: none;
      }

      a {
        display: block;
        padding: 7px 0 7px 45px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $primary;
        font-size: 1.2em;
        position: relative;
        text-decoration: none !important;
        outline: 0;

        @include hover {
          @include bgWithContrast($primaryHover);
          box-shadow: inset 0 0 0 2px $primary;
        }

        &:before {
          @include iconFont;
          content: "d";
          float: left;
          display: block;
          position: absolute;
          left: 20px;
          top: 10px;
          font-size: 1em;
        }

        &[href*='.pdf'] {
          &:before {
            content: "p";
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: $xl){
  .learnMore .linkGroup.default{
    width: 370px;
  }
  .learnMore .linkGroup.personalized{
    width: calc(100% - 390px);
  }
}

@media (max-width: $md) {
  .learnMoreWrapper {
    // margin-top: 20px;
    // padding: 20px 10px 20px 35px;

    // .iconWrap {
    //   top: -12px;
    //   left: -15px;
    //   transform: none;
    //   height: 45px;
    //   width: 45px;
    //   padding-top: 6px;
    // }

    // .toolTip {
    //   font-size: .9em;
    // }
  }
.learnMore{
  .learnMoreLinksWrap{
    flex-direction: column-reverse;
    gap: 7px;
    padding-left: 10px;
  }
}

.learnMore .linkGroup.default{
  width: 100%;
  .learnMoreLinks{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li{
      width: 50%;
    }
  }
}
.learnMore .linkGroup.personalized{
  width: 100%;
}
 
}

@media (max-width: $sm) {
  .learnMore {
    .learnMoreLinksWrap {
      margin-top: 15px;
    }

    .linkGroup {
      max-width: 600px !important;
      width: 100% !important;
      float: none !important;
      margin: 0 auto;

      &.personalized {
        margin-top: 20px;
      }
    }
  }

  .learnMoreWrapper{
    margin-bottom: 20px;
  }

  .slide{
    background: transparent;
  }
}

@media (max-width: $xs){
  .learnMore .linkGroup.default{
    .learnMoreLinks{
      flex-direction: column;
      li{
        width: 100%;
      }
    }
  }
}
