#validationMessage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba($basic, 0.85);

  .message {
    display: none;
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    text-align: center;
  }

  p {
    @include headerFont;
    color: $light;
    font-size: 2em;
    padding: 10px;

    .itemName, .availableDate {
      display: inline-block;
      margin-right: 5px;
      font-size: 1.3em;
      font-style: normal;
      font-weight: 400;
      font-family: $primaryFont;
      @include bgWithContrast($primary);
      padding: 3px 7px;

      h2 {
        font-size: 1.2em;
        color: $primary;
        text-transform: uppercase;
      }
    }

    @media all and (max-width: $xs) {
      font-size: 1.5em;

      .itemName, .availableDate {
        clear: both;
        display: block;
        margin: 5px auto;
      }
    }
  }
}
