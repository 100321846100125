.slide.quizSlide {
  &.noMedia {
    .quizCopy {
      padding-right: 0;
      margin: auto;
      float: none;
    }
  }

  .slideSide {
    width: 58%;
    float: right;
    padding-left: 30px;
  }

  .slideCopy {
    width: 42%;
    float: left;
  }

  legend {
    @include legendFix();
  }
}

.quizCopy {
}

.quizButtons {
  @include zeroMargPad;
  list-style: none;
  margin-top: 25px;
  width: auto;

  li {
    font-family: $primaryFont;
   // padding: 7px;
    cursor: pointer;
    margin-bottom: 3px;
    width: 100%;
    @include transition;
    display: flex;
    // border: $accent 1px solid;
    // padding: 7px;
    min-height: 65px;
    align-items: center;
    justify-content:flex-start;
    position: relative;

    @include hover {
      p{
        font-weight: 700;
      }
      .letterWrap{
        .iconBG{
          &:before{
            content: 'Y';
          }
        }
      }
    }
  }

  p {
    padding: 10px !important;
    margin: 0;
    font-size: 1em;
    line-height: 1.35em;
    height: 100%;
    box-sizing: border-box;
    text-align: left;
    padding-left: 25px !important;
    color: $basic;
   // border: 1px solid $accent;
  }

  .letterWrap{
    min-width: 48px;
    position: relative;
    height: 56px;
    .iconBG{
      font-size: 3.4em;
      color: $accent;
      top: 0px;
      left: 0px;
      position: absolute;
    }
  }
  .letter {
    font-weight: 300;
    font-size: 1.8em;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
    padding-bottom: 3px;
    position: absolute;
    z-index: 3;
    @include fillSpace;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
  
  }

  .picked {
    @include bgWithContrast($wrong);
    border-color: darken($wrong, 5%);
    transition: padding .2s;
    padding: 7px 15px;
    p{
      font-weight: 700;
      color: $light;
    }
    .iconBG{
      color: $light;
      &:before{
        content: 'Y';
      }
    }
    .letter {
      color: $alt2;
      position: relative;

      &:before {
        // @include iconFont;
        // content: 'b';
        // font-size: 0.7em;
        // position: relative;
        // left: -5px;
      }
    }

    @include hover {
      // @include bgWithContrast($wrong);

      // span.letter {
      //   color: text-contrast($wrong);
      //   border-right: 1px solid text-contrast($wrong);
      // }
    }
  }

  li.correct.picked {
    @include bgWithContrast($correct);
    border-color: darken($correct, 5%);

    // @include hover {
    //   @include bgWithContrast($correct);

    //   span.letter {
    //     color: text-contrast($correct);
    //     border-right: 1px solid text-contrast($correct);
    //   }
    // }

    span.letter {
      // &:before {
      //   content: 'a';
      // }
    }
  }
}

.answersWrapper {
  @include clearfix;
  color: $basic;
  font-size: 1.35em;
  padding: 20px 35px;
}

.quizContent {
  padding: 0;

  p {
    width: auto;
    position: relative;
    padding-bottom: 10px;

    &.resp {
      display: none;
      font-size: 1.1em;
      padding: 0;
      font-family: $primaryFont;
      position: relative;
      padding-left: 35px;
      padding: 7px 10px 7px 45px;

      &:before {
        @include iconFont;
        margin-right: 2px;
        font-size: 1.6em;
        position: absolute;
        left: 10px;
      }
    }

    &.question {
      font-size: 1.3em;
      font-family: $secondaryFont;
      font-weight: 700;
      font-style: italic;;
    }

    &.answerWrong {
      color: $basic;
      font-weight: 600;
      border: 1px solid $wrong;

      &:before {
        content: 'b';
        color: $wrong;
      }
    }

    &.answerCorrect {
      color: $basic;
      font-weight: 600;
      border: 1px solid $correct;
      &:before {
        content: 'a';
        color: $correct;
      }
    }
  }
}

@media (max-width: $xs) {
  .quizButtons {
    padding: 0 !important;
  }
}
