.confirmContent {
  position: relative;
  margin: 10px 0 25px;

  &.disabled {
    opacity: .7;

    label {
      cursor: default;
    }
  }

  input {
    @include vertCenter;
    position: absolute;
    left: 10px;
    z-index: 1;
  }

  label {
    position: relative;
    z-index: 2;
    background: $grey;
    border: lighten($dark, 12%) solid 1px;
    border-radius: 5px;
    padding: 5px 16px 7px 42px;
    cursor: pointer;
    color: $basic;
    font-weight: 700;
    font-family: $primaryFont;
    font-size: .9em;
    display: inline-block;


    @include hover() {
      @include bgWithContrast($primary);
    }
  }

  .confirmCheckbox {
    @include pseudoContent;
    height: 16px;
    width: 16px;
    border: lighten($dark, 12%) solid 1px;
    background: $light;
    position: absolute;
    border-radius: 3px;
    left: 12px;
    top: 10px;

    &:before {
      @include iconFont;
      position: absolute;
      font-size: 1.5em;
      content: 'a';
      left: 0;
      top: -3px;
      color: $primary;
      display: inline-block;
      transform: scale(0);
      transition: transform .2s;
    }
  }

  &.selected {
    label {
      background: $light;
      border-color: $primary;
      color: $primary;
      @include transition();
    }

    .confirmCheckbox {
      &:before {
        transform: scale(1);
      }
    }
  }
}
