.backgroundTitle{
    position: fixed;
    top: 30px;
    left: -15px;
    width: calc(100% - #{$sb});
    opacity: .15;
    transition: width .5s, left .75s;
    @media (max-height: $xsH){
        display: none;
    }
    .setStage &{
       // width: 60%;
        left: -100%;
    }
}


.sectionSlideshow{
    width: 100%;
    height: 100%;
    // @include backgroundCover;
     transition: opacity $out;
     position: absolute;
     overflow: hidden;
     top: 0px;
     left: 0px;
    .ssImg{
        position: absolute;
        @include fillSpace;
        display: none;
        top: 0px;
        opacity: 0;
        @include backgroundCover;
        transition: left .5s ease-in-out, top .5s ease-in-out, opacity .5s ease-in-out;
        &.current{
            opacity: 1;
            z-index: 1;
          //  left: 0px;
            &.movingOut{
               // opacity: 0;
               // left: -100%;
            }
           // opacity: 1;
        }
        &.next{
            opacity: 0;
           // left: 100%;
            &.movingIn{
                opacity: 1;
                z-index: 2;
            }
        }
        &.next.movingIn{
            opacity: 1;
        }
        &.current,&.next{
            display: block;
        }
    }
}



.bgImgPos{// HORIZONTAL VERTICAL
    &[data-position="Center"]{background-position: 50% 50%}
    &[data-position="Center Center"]{background-position: 50% 50%}
    &[data-position="Center Left"]{background-position: 10% 50%}
    &[data-position="Center Right"]{background-position: 90% 50%}
    &[data-position="Top Left"]{background-position: 10% 10%}
    &[data-position="Top Middle"]{background-position: 50% 10%}
    &[data-position="Top Right"]{background-position: 90% 10%}
    &[data-position="Bottom Left"]{background-position: 10% 90%}
    &[data-position="Bottom Middle"]{background-position: 50% 90%}
    &[data-position="Bottom Right"]{background-position: 90% 90%}
    .inSection &{
        &[data-inner-position="Center"]{background-position: 50% 50%}
        &[data-inner-position="Center Center"]{background-position: 50% 50%}
        &[data-inner-position="Center Left"]{background-position: 10% 50%}
        &[data-inner-position="Center Right"]{background-position: 90% 50%}
        &[data-inner-position="Top Left"]{background-position: 10% 22%}
        &[data-inner-position="Top Middle"]{background-position: 50% 22%}
        &[data-inner-position="Top Right"]{background-position: 90% 22%}
        &[data-inner-position="Bottom Left"]{background-position: 10% 90%}
        &[data-inner-position="Bottom Middle"]{background-position: 50% 90%}
        &[data-inner-position="Bottom Right"]{background-position: 90% 90%}
    }
}