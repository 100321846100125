.revealSlide {
  /*********FLIP STYLES*****************/
  &.Flip {

    .copyWrap {
      color: text-contrast($secondary);
      padding-right: 10px;

      p {
        line-height: 1.5em;
      }

      ul {
        margin-top: -5px;
        margin-bottom: 10px;

        li {
          margin-bottom: 2px;
          line-height: 1.3em;
        }
      }

      .heading {
        color: text-contrast($secondary);
      }

      a {
        color: text-contrast($secondary);

        @include hover {
          background: $secondaryHover;
          color: text-contrast($secondaryHover);
        }
      }

      .listheading {
        color: text-contrast($secondary);
        border-color: text-contrast($secondary);
      }

      span.largeText {
        color: text-contrast($secondary);

        &:before, &:after {
          display: none;
        }
      }

      ol {

        li:before {
          color: text-contrast($secondary);
        }
      }
    }


    .flipOnly {
      display: block;
    }

    .revealContentHolder {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      margin-top: 25px;

  
        .revealItemHolder {
          // margin: 10px .5% 0 .5%;
          // width: calc(98% * (1/3) - 10px - 1px);
          // flex-grow: 1;
          //  max-width: 350px;
          //  min-width: 250px;

          @media (max-width: $xs) {
            height: 200px;

            &.picked {
              width: 100%;
              margin: 5px 0;
              max-width: 600px;
              height: auto !important;

              .flipper .back {
                position: relative;
                height: auto;
                min-height: 200px;
              }
            }
          }

          @media (max-width: $xxs) {
            height: 150px;

            &.picked {
              width: 100%;
              margin: 5px 0;
              max-width: 600px;
              height: auto;
            }
          }
        }
      

      &.ImageAndTitle {
        
        .revealBtn .titleWrap {
          opacity: 1;
        }
        .titleWrap{
         position: absolute;
         bottom: 0px;
         right: 0px;
         max-width: calc(100% - 30px);
          h2{
            padding: 7px 15px;
            font-size: 1.1em;
            line-height: 1.2em;
            @include bgWithContrast($primary);
            text-align: right;
            padding-right: 37px;
            font-weight: 700;
            &:before{
              @include iconFont;
              content: 'v';
              position: absolute;
              right: 10px;
              top: 7px;
              transition: transform .3s;
              transform-origin: center center;
              font-size: 1.1em
            }
          }
        }
      }

      &.TitleOnly {
        .revealBtnWrap {
          background: $primary;
          color: text-contrast($primary);
        }
        .revealItemHolder {
          @include hover{
            .revealBtn{
              .titleWrap{
                text-shadow:$secondary 4px 4px 0px;
                  &:before{
                    transform: rotate(180deg) translateX(-50%);
                  }
              }
            }
          }
        }

        .revealBtn {
          .titleWrap {
            display: flex;
            @include absolute;
            @include fillSpace;
            justify-content: center;
            align-items: center;
            padding: 20px;
            flex-direction: column;
            // text-shadow:$light 2px 2px 0px;
            transition: text-shadow .2s;
            &:after{
              transition: transform .2s;
              transform-origin: center center;
              @include iconFont;
              content: 'R';
              font-size: 2em;
            }
    
            h2 {
              @include zeroMargPad;
              text-transform: none;
              font-family: $primaryFont;
              font-weight: 700;
              display: block;
              text-transform: uppercase;
              padding: 3px 5px;
              position: relative;
              font-size: 1.7em;
              line-height: 1.2em;
              padding-bottom: 25px;
            }
          }
        }
      }
    }//reveal content holder

    .revealItemHolder {
      perspective: 1000px;
      transition: opacity .5s, transform .3s, margin .25s;
       margin: 10px 5px 0 5px;
      width: 275px;
      max-width: 325px;
      flex-grow: 1;
      @supports  (aspect-ratio: 5 / 6) {
        //aspect-ratio: 5 / 6;
        height: 330px;
      }
      @supports not (aspect-ratio: 5 / 6) {
        width: 275px;
        height: 330px;
      }

      @media (max-width: $sm) {

        &.picked {
          .flipper .back {
            min-height: 330px;
            max-height: 330px;

          }
        }
      }

      @include hover {
        .flipper {
          transform: rotateY(7deg);
        }
        .front{
          border-color: $secondary;
          background: $secondary;
        }
        .titleWrap {
          margin-top: 0;
          opacity: 1;
          &:after{
            color: $primary;
          }
          h2{
            color: $primary;
           
          }
        }
      }

      &.open {
        height: auto !important;

        .flipper .back {
          position: relative;
          height: auto;
        }
      }

      &.picked {
        .flipper {
          transform: rotateY(0);
        }

        .flipper .front {
          transform: rotateY(180deg);
        }

        .flipper .back {
          transform: rotateY(0);
          min-height: 340px;
        }

        @include hover {
          .flipper {
            transform: rotateY(2deg);
          }
        }
      }
    }

    .flipper {
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      @include fillSpace;
    }

    .tile {
      @include fillSpace();
      @include absolute;
      backface-visibility: hidden;
      
    }

    .front {
      z-index: 2;
      transform: rotateY(0);
      cursor: pointer;
      text-align: center;
      border: 3px solid $primary;
    }

    .back {
      transform: rotateY(180deg);
      padding: 0 0 0 33px;
      @include bgWithContrast($secondary);
      @include transition(.2s);
      border: 3px solid $secondary;
    }

    .revealContent {
      display: none;
    }

    .flipBack {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      top: 20px;
      left: 7px;
      font-size: 1.2em;

      @include hover {
        border: 2px solid text-contrast($secondary);
      }
    }

    .topicImageHolder {
      @include absolute;
      @include fillSpace;

      .topicImage {
        @include absolute;
        @include fillSpace;
        @include backgroundCover;
      }

      img {
        display: none;
      }
    }



    .revealContent {
      max-height: 100%;
      overflow: hidden;
      padding: 15px 7px 15px 0;
      font-size: .9em;

      .topicTitle {
        @include zeroMargPad();
        padding: 3px 5px;
        margin-bottom: 7px;
        @include bgWithContrast($primary);
        font-size: 1.4em;
        font-weight: 700;
      }

      .imgHolder {
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }

    @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
      .revealItemHolder {
        &.picked {
          .flipper {
            transform: rotateY(180deg);
          }

          @include hover {
            .flipper {
              transform: rotateY(175deg);
            }
          }

          .flipper .front {
            transform: rotateY(0);
          }

          .flipper .back {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}
