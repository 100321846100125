$alertW: 240px;
$alertI: 70px;

//Alertbar & Widget Templates
//@import '../templates/_alertBar/_rollout.scss'; //Straight rollout
//@import '../templates/_alertBar/_overlay.scss'; //Overlay
//@import '../templates/_alertBar/_round-docked.scss'; //Round Docked
//@import '../templates/_alertBar/_round-undocked.scss'; //Round Undocked
//@import 'templates/_alertBar/_iceman-default.scss'; //No Widget

.alertMode {
  .alertBar {
    z-index: 12;
  }

  .item {
    margin-bottom: 0;
  }
}

.inSection {
  .alertBar {
    display: none;
  }
}

.alertBar, .alertBarWidget{
 
}
.alertBarWidget{
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 85px;
  padding-bottom: 5px;
  display: flex;
  transition: left .5s, bottom .35s;
  padding: 10px 5px;
  z-index: 100;
  &.out{
    left:-550px;
  }
  .helpMode &, .loading &{
    left:-550px;
  }
  .searchMode &, .faqMode &{
    bottom: -75px;
  }
  .alertMode &{
    background: $primary;
    width: calc(100vw - #{$sb});
    bottom: 0px;
    border-top: 1px solid $secondary;
    @media (max-width: $mid){
      width: calc(100vw - #{$sbL});
    }
    @media (max-width: $md){
      width: calc(100vw - #{$sbM});
    }
    @media (max-width: $tween){
      width: 100vw;
    }
  }

  .setStage &{
    left: -350px;
  }
}

.alertBtn{
  width: 80px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  position: relative;
  transition: transform .2s;
  &.open{
    .icon{
       transform: rotate(45deg);
    }
    .totals{
      transform: scale(0);
    }
  }
  @include hover{
    cursor: pointer;
    transform: scale(1.1) translateX(5px);
    .icon{
      // transform: rotate(5deg);
    }
    svg{
      path{fill:$primaryHover; }
    }
    .totals{
      transform: scale(0);
    }
  }
  .icon{
    z-index: 10;
    color: $light;
    width: calc(100% - 15px);
    font-size: 2em;
    text-align: center;
    line-height: 1em;
    display: flex;
    align-items: center;
    transition: transform .5s .2s;
    justify-content: center;
  }
  svg{
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
   
    path{
      fill: $alt;
    }
  }

  .totals{
    position: absolute;
    left: calc(100% - 15px);
    bottom: -3px;
    background: $contrast;
    border-radius: 50%;
    padding: 10px;
    border: 2px solid $light;
    color: $light;
    font-family: $secondaryFont;
    font-weight: 600;
    z-index: 10;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .35s;
  }
}


.alertBar{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0px 10px 0px 15px;
  width: 0%;
  padding: 0px;
  transition: transform .5s;
  
  &.open{
    width: auto;
    transition: padding .5s, transform .5s;
    padding:0px 10px 0px 15px;
    .item{
      width: 50px;
      opacity: 1;
      transition: width .5s, opacity .2s .5s;
    }
  }
  .item{
    height: 50px;
    width: 0px;
    background: $light;
    border: 2px solid $accent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    color: $primary;
    position: relative;
    opacity: 0;
    transition: width .5s .2s, opacity .2s;
 

    @include hover{
      background: $primary;
      color: $light;
      cursor: pointer;
      .alertName{
        display: block;
      }
    }
    .icon{
      font-size: 1.5em;
    }
    .alertName{
      display: none;
      position: absolute;
      bottom: calc(100% + 10px);
      color: $primary;
      font-family: $primaryFont;
      text-transform: uppercase;
      font-weight: 700;
      background: $light;
      border-radius: 5px;
      padding: 1px 3px;
    }
    .notify{
      position: absolute;
      bottom: -13px;
      right: -7px;
      background: $contrast;
      height: 30px;
      width: 30px;
      border: 2px solid $light;
      color: $light;
      font-family: $secondaryFont;
      font-weight: 600;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .85em;
    }
  }
}
//ALERT CONTENT AREAS//
.alertOverlay{
  @include fillSpace;
  @include fixed;
  background: rgba($basic,.85);
  z-index: 9;
  display: none;
  .alertMode &, .faqMode &{
    display: block;
  }
  
}

#alertContentWrap {
  position: fixed;
  bottom: 85px;
  background: $primary;
  padding: 0;
  @include borderBox;
  z-index: 11;
  width:calc(100vw - #{$sb});
  height: calc(100% - 85px);
  left: 0;
  display: none;
  overflow: hidden;
  @include scrollbar($color: $secondary, $width: 25px, $bgColor: $grey);
  overflow-y: auto;
  @media (max-width: $mid){
    width: calc(100vw - #{$sbL});
  }
  @media (max-width: $md){
    width: calc(100vw - #{$sbM});
  }
  @media (max-width: $tween), (max-height: $xsH){
    width: 100vw;
  }

  // .inSection &{
  //  left: $heroBarInner; 
  // }

  .alertContent {
    display: none;
    padding: 0 0 75px 0;
  }

  h1{
    font-size: 3.8em;
    max-width: 100%;
    font-family: $primaryFont;
      font-weight: 900;
    margin-left: 80px;
    margin-top: 15px;
    margin-bottom: 55px;
    color: $light;
    position: relative;
    padding-bottom: 45px;
    &:after{
      @include iconFont;
      content: 'Z';
      color: $secondary;
      font-size: .3em;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
   
   
  }

  .closeBtn {
    @include button($secondary, $primary);
    text-transform: none;
    color:$light;
    width: 58px;
    height: 58px;
    position: fixed;
    top: -160px;
    left: 7px;
    font-size: 1.8em;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0px;
    display: flex !important;
    font-family: $iconFont;
    z-index: 50;
    transition: top .3s, background-position .15s, opacity .3s ;
    opacity: 0;
    &:after{
      display: none;
    }
    @include hover{
      color:$primary;
    }
    

    &.show {
      transition: top .3s .35s, background-position .15s, opacity .3s .35s ;
      top: 15px;
      display: flex !important;
      opacity: 1;
    }

  }
}

.alertInnerContent {
  display: flex;
  padding: 15px 20px 15px 78px;
  justify-content: space-between;
  align-items: flex-start;
}

.alertListCopy {
  width: 65%;
  padding-left: 25px;
}

.alertInstruction {
  width: 35%;
  @include bgWithContrast($primary);
  border: 3px solid $secondary;
  border-radius: 7px;
  padding: 7px;
  font-weight: 400;
  font-family: $secondaryFont;

  .inner{
    border: 3px solid $secondary;
    border-radius: 3px;
    padding: 20px 25px;
  }

  h2 {
    margin: 0 auto !important;
    font-weight: 900 !important;
    font-size: 2.2em !important;
    padding: 0px;
    display: block !important;
    text-transform: uppercase;
    font-family: $primaryFont;
    padding-bottom: 20px;
  }

  p{
    font-style: italic;
    font-weight: 300;
    font-size: 1.5em;
    line-height: 1.4em;
  }
}

#innerChecklist {
  display: block;
  position: relative;
  background: none;
  overflow: auto !important;
  border: none;
  font-size: 14px;
  line-height: 1.42857143;
}

#checklist, #innerChecklist {

  .printerBtn {
    @include button($accent, $primary);
    color: $light;
    display: inline-block;
    margin-right: 5px;
    font-size: .9em;
    position: relative;
    padding: 10px 15px;
    cursor: pointer;
    @include hover{
      color: $primary;
    }

    span {
      margin-right: 10px;
    }

    &:after {
      display: none;
    }
  }

  .list {
    list-style: none;
    padding-left: 0;
    padding-top: 0px;
    margin-top: 0px;
  }

  .clickArea {
    padding-left: 65px;
    margin-left: -65px;
    position: relative;
    z-index: 100;
    padding-right: 10px;
    cursor: pointer;
  }

  .checkItem {
    @include bgWithContrast($light);
    position: relative;
    @include clearfix;
    margin: 0px 0 10px;
    padding: 20px 0 25px 65px;
    max-width: 960px;
    width: 100%;
    border-radius: 7px;

    .checkbox {
      @include pseudoContent;
      height: 18px;
      width: 18px;
      border: 2px solid $basic;
      background: transparent;
      @include absolute;
      top: 25px;
      left: 28px;
      line-height: .5em;
      font-size: 1.4em;
      text-align: center;
      margin-top: 0;

      @include hover {
        cursor: pointer;
        color: $grey;

        &:before {
          color: $primary;
        }
      }

      a {
        text-decoration: underline !important;
      }
    }

    .instructions {
      margin-bottom: 20px;
      font-size: 1.15em;
      line-height: 1.4em;

      p {
        @include zeroMargPad;
      }

      ul {
        @include zeroMargPad;
        margin-left: 15px;
      }
    }

    &.checked {
      .checkbox {
        &:before {
          color: $primary;
          @include iconFont;
          position: absolute;
          content: 'a';
          font-size: 1.2em;
          top: -7px;
          left: 0;
        }
      }

      h3 {
        color: $basic;
      }
    }

    @include hover {
      background: $secondary;
    }
  }

  h3 {
    cursor: pointer;
    position: relative;
    margin-bottom: 4px;
    @include headerFont;
    color: $basic;
    font-size: 1.5em;
    font-weight: 400;
  }

  .due {
    font-weight: 700;
    text-transform: uppercase;
    font-size: .6em;
    font-family: $primaryFont;
    display: block;
    margin: 0;
    clear: both;
    color: $dark;
    padding: 0;
    letter-spacing: 0;
  }

  .noDate {
    display: none;
  }

  .chLink a {
    @include button;
    display: inline-block;
    margin-right: 5px;
    font-size: .9em;
    padding: 10px 15px;
    position: relative;
    padding-left: 28px;
    &:after{display: none;}

    &:before {
      @include iconFont;
      content: 'd';
      margin-right: 5px;
      position: absolute;
      left: 10px;
      top: 12px;
    }

    &[href*='.pdf'] {
      padding-left: 40px;

      &:before {
        content: "\70";
        font-size: 1.7em;
        top: 6px;
      }
    }
  }
}

#announcements {
  .announcement {
    padding: 20px 30px 20px 10px;
    font-size: 1.125em;
    margin-bottom: 5px;
    background: $light;
    &.new {
      border: 0px;
      background: $secondary;
      padding: 20px 30px;

      h2 {
        font-weight: 700;
        color: $primary;
        padding: 3px 20px 3px 40px;

        &:before {
          color: $primary;
          display: inline-block;
        }
      }
      p{
        color: $basic;
        padding: 5px 20px 0 40px;
      }
    }

    h2 {
      @include headerFont;
      font-weight: 700;
      font-size: 1.8em;
      padding: 3px 20px 3px 20px;
      color: $basic;
      position: relative;

      &:before {
        @include iconFont;
        text-transform: none;
        color: $basic;
        content: 'e';
        margin-left: -10px;
        margin-right: 10px;
        @include absolute;
        font-weight: normal;
        font-size: 1.2em;
        left: 0;
        top: 0;
        display: none;
      }
    }

    p {
      @include zeroMargPad;
      padding: 5px 20px 0 20px;
      color: $basic;
      font-family: $basicFont;
      line-height: 1.5em;
      font-size: 1.1em;
      font-weight: 700;

      @media all and (max-width: $xs) {
        padding: 5px;
        line-height: 1.35em;
      }
    }
  }
}

#account {
  .accountContent {
    @include clearfix;
    margin-left: 40px;

    @media all and (max-width: $xs) {
      margin-left: 10px;
    }

    h2 {
      font-family: $secondaryFont;
      font-size: 1.5em;
      font-weight: 400;
      color: lighten($secondary, 25%);
      float: left;
      margin-right: 20px;

      @media all and (max-width: $xs) {
        margin: 0 20px 20px 0;
      }

      span {
        font-weight: 700;
        color: $basic;
      }
    }

    a {
      @include button;
      margin: 0;
      display: block;
      float: left;
    }
  }
}

#resources {
  // .learnMore .linkGroup.default{
  //   back
  // }
  .slide {
    margin-top: 0;
  }
  .slide .content{
    padding: 0px !important;
    padding-left: 65px !important;
    padding-bottom: 80px !important;
  }

  @media all and (max-width: $xs) {
    padding: 0 0 75px;
  }
  .learnMore{
    .learnMoreLinksWrap{
      margin-top: 0px;
    }
  }



  .slideTitle {
    display: none;
  }

  .textCopy {
    padding: 0 60px 0 0;
  }
}

#wrapper.noOverflow {
  overflow: hidden;
}

@media (max-width: $md) {
  .alertInnerContent {
    display: block;
  }

  .alertInstruction {
    width: 100%;
  }

  .alertListCopy {
    width: 100%;
    margin-top: 25px;
    padding: 0;
  }
}

@media (max-width: $sm) {

  .alertBar {
    // bottom: 0;
    // display: flex;
    // justify-content: space-between;

    // .item {
    //   width: auto;
    //   flex-grow: 1;

    //   &.searchBtn {
    //     width: auto;
    //   }

    //   .notify {
    //     right: 15px;
    //   }
    // }
  }

  #alertContentWrap {
    // height: calc(100% - 40px);
    // border-right: 0;
    // z-index: 30;
  }

  .alertInnerContent {
    // padding: 15px 20px;
  }

  .alertInstruction {
    display: block;
    width: 100%;
  }

  .alertListCopy {
    display: block;
    width: 100%;
  }

  #announcements {
    // .announcement {
    //   padding: 10px 25px;

    //   &.new {
    //     padding: 10px 25px;
    //   }
    // }
  }

  #checklist, #innerChecklist {
    a {
      &.checkPrint {
        display: none;
      }
    }
  }


  .alertMode {
    .alertBar {
      bottom: 0;
    }
  }
}

@media (max-width: $xs + 100) {
  .alertBar {
    .item {
      .notify {
        right: 10px;
      }
    }
  }
}

@media (max-width: $xs) {

  #alertContentWrap {
    h1 {
      font-size:2em;
      margin-left: 70px;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    .closeBtn {
      font-size: 1.5em;
      padding: 0;
      height: 45px;
      width: 45px;
    }
  }
  .alertBarWidget{
    bottom: 75px;
    &.open{
      width: 100%;
      bottom: 0px;
      background: $primary;
    }
  }
  .alertInnerContent{
    padding-left: 60px;
  }
  .alertBar{
    &.open{
      width: calc(100% - 50px);
    }
  }
  .alertBtn{
    &.open{
      background: $alt;
      width: 50px;
      padding-left: 0px;

      .btnBG{
        display: none;
      }
    }
  }

  .alertInstruction {
    p{
      font-size: 1.1em;
      line-height: 1.2em;
    }

    h2 {
      font-size: 1.5em !important;
    }
  }


}

@media (max-width: $xxs) {
  .alertBarWidget{
    &.open{
      height: 50px;
      padding: 0px;
    }
  }

  #alertContentWrap{
    height: calc(100% - 50px);
    bottom: 50px;
   
  }
  .alertBtn{
    &.open{
      height: 50px;
    }
  }
  .alertBar {
    &.open{
      padding: 0px;
      .item{
        flex-grow: 1;
        width: auto;
        
      }
    }
    .item {
      margin: 0px;
      border-radius: 0px;
      .notify{
        bottom: auto;
        top: -15px;
      }
    }
  }
}

@media (max-width: $tiny) {
  .alertInnerContent{
    padding-left: 20px;
  }
}
